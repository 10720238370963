import React, { useState, useEffect, useRef } from "react";
import useOutsideClick from "../../../Hooks/useOutsideClick";
import { toastError } from "../../../Utilities/toast";
import { fetchData } from "../../../Utilities/handleRequest";
import * as Styled from "./styled";

const CouponCategorySelect = ({
  placeholder='Select',
  options=[],
  selected='',
  onSelect=()=>{},
  error='',
  updateList=()=>{}
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [otherInput, setOtherInput] = useState('');
  const [otherActive, setOtherActive] = useState(false);
  const [selectedItem, setSelectedItem] = useState(
    selected ? options?.find((item) => item.id === selected) : undefined
  );

  const dropdownRef = useRef(null);

  useEffect(() => {
    setSelectedItem(options?.find((item) => item.id === selected));
  }, [selected, options]);

  const handleChange = (item) => {
    if (item.id === selected) {
      setIsOpen(false);
      return;
    }
    onSelect(item.id, item.name);
    setIsOpen(false);
  };

  useOutsideClick({
    ref: dropdownRef,
    handler: () => setIsOpen(false),
  });

  const onOtherButtonClick = () => {
    setOtherInput('');
    setIsOpen(false);
    setOtherActive(true);
  }

  const addOtherValue = async () => {
    const otherInputTrim = otherInput.trim();
    if (!otherInputTrim) return;

    const exists = options.filter((item) => (item?.name?.toLowerCase() === otherInputTrim.toLowerCase()));
    if (exists.length > 0) {
      onSelect(exists[0].id);
      setOtherActive(false);
    } else {
      const res = await fetchData('post', 'coupon/add-category', { name: otherInputTrim });
      if (res?.data) {
        onSelect(res?.data?.id);
        setOtherActive(false);
  
        updateList();
      } else {
        toastError('Something went wrong!');
      }
    }
  }
  
  const cancelOtherValue = () => {
    setOtherActive(false);
    setOtherInput('');
  }

  return (
    <Styled.MainDiv ref={dropdownRef}>
      { otherActive ?
        <Styled.OtherInputDiv>
          <input
            onChange={(e) => setOtherInput(e.target.value)}
            style={error ? { border: '1px solid red' } : {}}
            value={otherInput}
          />
          <Styled.AddOtherButton onClick={addOtherValue} />
          <Styled.CancelOtherButton onClick={cancelOtherValue} />
        </Styled.OtherInputDiv>
      :
        <Styled.SelectButtonDiv>
          <Styled.DropdownWrapDiv>
            <Styled.SelectButton
              onClick={() => setIsOpen(!isOpen)}
              isopen={isOpen}
              style={error ? { border: '1px solid #DB1616' } : {}}
            >
              <p>{selectedItem?.name || placeholder}</p>
            </Styled.SelectButton>

            { options && options.length ?
              <Styled.DropdownDiv style={{ maxHeight: isOpen ? '242px' : '0px' }}>
                
                <Styled.ListDiv>
                  {options.map((item) => (
                    <Styled.ListButton key={`${item.id}_${item.name}`} onClick={() => handleChange(item)} active={selected === item.id ? 'true' : 'false'}>
                      <p>{item.name}</p>
                    </Styled.ListButton>
                  ))}
                </Styled.ListDiv>
              </Styled.DropdownDiv>
            : ''
            }
          </Styled.DropdownWrapDiv>

          <Styled.OtherButton onClick={onOtherButtonClick} />
        </Styled.SelectButtonDiv>
      }

      { error ?
        <Styled.ErrorDiv>{error}</Styled.ErrorDiv>
      : ''
      }

    </Styled.MainDiv>
  )
}

export default CouponCategorySelect;
