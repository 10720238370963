import styled from "styled-components";

export const MainWrapDiv = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #e2f1f1;
  border-radius: 10px;
  padding: 22px 28px;
  margin: 14px 32px;
`;
export const HeadWrapDiv = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:after {
    content: '';
    position: absolute;
    right: 5px;
    top: ${({active}) => active === 'true' ? '13px' : '5px'};
    transform: ${({active}) => active === 'false' ? 'rotate(-135deg)' : 'rotate(45deg)'};
    height: 12px;
    width: 12px;
    border-bottom: 2px solid #000000;
    border-right: 2px solid #000000;
    transition: all 0.3s;
  }
`;
export const HeadingDiv = styled.div`
  font-size: 20px;
  font-weight: 700;
`;
export const ProgressDiv = styled.div`
  font-size: 16px;
  font-weight: 400;
  margin-left: 20px;
`;
export const CompletedDiv = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 24px;
  background-color: #118D8B;
  margin-left: 12px;
  position: relative;
  flex-shrink: 0;
  &:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 8px;
    height: 10px;
    width: 5px;
    transform: rotate(45deg);
    border-right: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
  }
`;
export const ContentWrapDiv = styled.div`
  max-height: ${({active, maxHeight}) => active === 'true' ? maxHeight : '0px'};
  transition: all 0.3s;
  overflow: hidden;
`;
export const ContentDiv = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  margin-top: 22px;
`;
export const CompletedDateDiv = styled.div`
  margin: 0px 70px 0px auto;
  font-size: 16px;
  font-weight: 600;
  color: #000000;
`;
export const PaymentCollapseDiv = styled.div`
  padding: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & p {
    font-size: 16px;
    font-weight: 400;
    margin: 0px;
  }
`;
export const CollapseBodyDiv = styled.div`
  padding: 0px 28px 28px 28px;
  display: flex;
  flex-direction: column;
`;
export const RowDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const ColumnDiv = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
`;
export const InputLabelDiv = styled.div`
  margin: 24px 0px 12px 0px;
`;
export const TextDiv = styled.div`
  margin: 0px 0px 12px 0px;
`;
export const ListButton = styled.button`
  border-radius: 8px;
  background-color: #e2f1f1;
  color: #118D8B;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 20px;
  text-align: left;
  display: flex;
  align-items: center;
`;
export const DisabledListButton = styled.div`
  border-radius: 8px;
  background-color: #e2f1f1;
  color: #118D8B;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 20px;
  text-align: left;
  display: flex;
  align-items: center;
  opacity: 0.4;
  cursor: pointer;
`;