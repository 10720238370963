import { useEffect, useState } from "react";
import InputGroup from "../../../../Components/InputGroup";
import Input from "../../../../Components/Input";
import SingleSelect from "../../../../Components/SingleSelect";
import RadioButton from "../../../../Components/RadioButton";
import { MilestoneLayout } from "../../layout";
import * as Styled from "./styled";

const MeasurementModal = ({
  dataMeasurement,
  show,
  hide=()=>{},
  onSubmit=()=>{},
  isDisabled=false,
}) => {
  const [activeList, setActiveList] = useState(1);
  const [measurementData, setMeasurementData] = useState(null);
  const [layout, setLayout] = useState(MilestoneLayout(measurementData)?.[1]?.layout?.[4]?.[0]?.layoutList);

  useEffect(() => {
    const data = {...dataMeasurement};
    if (!data?.measurement_checklist_1?.no_of_walls) {
      if (!data?.measurement_checklist_1) {
        data.measurement_checklist_1 = {};
      }
      data.measurement_checklist_1.no_of_walls = 4;
    }
    setMeasurementData(data);
  }, [dataMeasurement]);

  useEffect(() => {
    const tempLayout = [...MilestoneLayout(measurementData)?.[1]?.layout?.[4]?.[0]?.layoutList];
    const no_of_walls = measurementData?.measurement_checklist_1?.no_of_walls || 4;
    const wallNumbers = Number(no_of_walls);
    tempLayout[0] = tempLayout[0].slice(0, (wallNumbers * 2) + 4);
    setLayout(tempLayout);
  }, [measurementData]);
  
  const onPreviousChecklist = () => {
    if (activeList > 1) {
      setActiveList((prev) => prev - 1);
    }
  }
  
  const onInputChange = (e) => {
    const { id, value } = e.target;
    const list = measurementData[`measurement_checklist_${activeList}`];
    setMeasurementData({...measurementData, [`measurement_checklist_${activeList}`]: {...list, [id]: value} });
  }

  const onRadioButtonChange = (id, value) => {
    let val = value;
    if (val === 'true') {
      val = true;
    } else if (val === 'false') {
      val = false;
    }

    const list = measurementData[`measurement_checklist_${activeList}`];
    setMeasurementData({...measurementData, [`measurement_checklist_${activeList}`]: {...list, [id]: val} });
  }

  const onSingleSelect = (id, value) => {
    const data = {...measurementData};
    const activeChecklist = `measurement_checklist_${activeList}`;

    if (!data[activeChecklist]) {
      data[activeChecklist] = {};
    }

    if (id === 'no_of_walls') {
      const wallNumbers = Number(value);

      data[activeChecklist] = {};
      data[activeChecklist].progress = measurementData?.[activeChecklist]?.progress || 0;
      data[activeChecklist].washroom_measurement_length = measurementData?.[activeChecklist]?.washroom_measurement_length || '';
      data[activeChecklist].washroom_measurement_breadth = measurementData?.[activeChecklist]?.washroom_measurement_breadth || '';

      for (let x = 1; x <= wallNumbers; x++) {
        data[activeChecklist][`wall_${x}_height`] = measurementData?.[activeChecklist]?.[`wall_${x}_height`] || '';
        data[activeChecklist][`wall_${x}_width`] = measurementData?.[activeChecklist]?.[`wall_${x}_width`] || '';
      }
    }

    data[activeChecklist][id] = value;
    setMeasurementData({...data});
  }

  const isChecklistComplete = () => {
    let data = {};
    data = measurementData?.[`measurement_checklist_${activeList}`] || {};
    const activeLayout = layout[activeList - 1] || [];
    const fieldsToInclude = ['inputGroup', 'dropdown', 'radio', 'textarea', 'input', 'conditionalTextarea'];

    for (let x = 0; x < activeLayout.length; x++) {
      if (!activeLayout[x]) {
        continue;
      }
      for (let y = 0; y < activeLayout[x].length; y++) {
        const activeField = activeLayout[x][y];
        if (fieldsToInclude.includes(activeField?.element)) {
          if (activeField?.condition) {

            if (data?.[activeField?.condition] === activeField?.trueCondition && !activeField?.value) {
              return false;
            }
          } else if (typeof activeField?.value === 'undefined' || typeof activeField?.value === 'null' || activeField?.value === '') {
            return false;
          }
        }
      }
    }
    return true;
  }

  const getNextSubmitButtons = (mode) => {
    if (!mode) return;
    const checkComplete = isChecklistComplete();

    if (mode === 'next') {
      return <Styled.NextButton
        onClick={onSaveOrSubmit}
        disabled={!checkComplete}
        style={{ opacity: checkComplete ? '1' : '0.5' }}
      >
        Next
      </Styled.NextButton>
    } else {
      return <Styled.NextButton
        onClick={onSaveOrSubmit}
        disabled={!checkComplete}
        style={{ opacity: checkComplete ? '1' : '0.5' }}
      >
        Submit
      </Styled.NextButton>
    }
  }

  const onSaveOrSubmit = () => {
    if (isDisabled) return
    const data = {...measurementData};
    data[`measurement_checklist_${activeList}`].progress = 100;
    if (data.progress) {
      data.progress += 10;
    } else {
      data.progress = 10;
    }

    if (activeList < 10) {
      if (data[`measurement_checklist_${activeList}`] !== dataMeasurement[`measurement_checklist_${activeList}`]) {
        onSubmit(data, () => setActiveList((prev) => prev + 1));
      } else {
        setActiveList((prev) => prev + 1)
      }
    } else {
      if (data[`measurement_checklist_${activeList}`] !== dataMeasurement[`measurement_checklist_${activeList}`]) {
        onSubmit(data, onModalHide);
      } else {
        onModalHide();
      }
    }
  }

  const onModalHide = () => {
    hide();
    setMeasurementData({...dataMeasurement});
  }

  return (
    <Styled.MainDiv style={{ display: show ? 'flex' : 'none' }}>
      <Styled.ModalDiv id="modal_div">
        <Styled.ModalHeadingWrapDiv>
          <Styled.ModalHeadingDiv>{`Measurement Checklist (${activeList}/10)`}</Styled.ModalHeadingDiv>
          <Styled.CloseModalButton onClick={onModalHide} />
        </Styled.ModalHeadingWrapDiv>

        <Styled.ModalContentDiv>
          { layout?.map((modalRow, modalRowIndex) => (
            modalRowIndex === activeList - 1 ?
              modalRow.map((details, detailsIndex) => (
                <Styled.RowDiv key={`measurement_${modalRowIndex}_${detailsIndex}`}>
                  { details.map((detail, detailIndex) => (
                    <>
                      { detail.element === 'label' ?
                        <Styled.InputLabelDiv style={detail.style}>{detail.text}</Styled.InputLabelDiv>
                      : ''
                      }
                      { detail.element === 'text' ?
                        <div style={detail.style}>{detail.text}</div>
                      : ''
                      }
                      { detail.element === 'textarea' ?
                        <textarea
                          style={detail.style}
                          id={detail.name}
                          placeholder={detail.placeholder}
                          value={detail.value}
                          onChange={(e) => onInputChange(e)}
                          disabled={isDisabled}
                        />
                      : ''
                      }
                      { detail.element === 'conditionalTextarea' ?
                        <Styled.ColumnDiv
                          style={{ opacity: detail.condition && measurementData?.[`measurement_checklist_${activeList}`]?.[detail.condition] !== detail.trueCondition ? '0.4' : '1', width: '100%' }}
                        >
                          { detail.label ?
                            <Styled.InputLabelDiv>{detail.label}</Styled.InputLabelDiv>
                          : ''
                          }
                          <textarea
                            id={detail.name}
                            placeholder={detail.placeholder}
                            disabled={isDisabled || (detail.condition && measurementData?.[`measurement_checklist_${activeList}`]?.[detail.condition] !== detail.trueCondition)}
                            value={detail.value}
                            onChange={(e) => onInputChange(e)}
                          />
                        </Styled.ColumnDiv>
                      : ''
                      }
                      { detail.element === 'input' ?
                        <Styled.ColumnDiv style={{ opacity: detail.condition && measurementData?.[`measurement_checklist_${activeList}`]?.[detail.condition] !== detail.trueCondition ? '0.4' : '1' }}>
                          { detail.label ?
                            <Styled.InputLabelDiv>{detail.label}</Styled.InputLabelDiv>
                          : ''
                          }
                          <Input
                            id={detail.name}
                            value={detail.value}
                            onChange={(e) => onInputChange(e)}
                            placeholder={detail.placeholder}
                            type={detail.type}
                            disabled={isDisabled || (detail.condition && measurementData?.[`measurement_checklist_${activeList}`]?.[detail.condition] !== detail.trueCondition)}
                          />
                        </Styled.ColumnDiv>
                      : ''
                      }
                      { detail.element === 'radio' ?
                        <RadioButton
                          name={detail.name}
                          style={{ width: '48%', margin: '10px 0px' }}
                          options={detail.values}
                          value={detail.value?.toString()}
                          onChange={(e) => onRadioButtonChange(detail.name, e.target.value)}
                          disabled={isDisabled}
                        />
                      : ''
                      }
                      { detail.element === 'inputGroup' ?
                        <InputGroup
                          id={detail.name}
                          value={detail.value}
                          onChange={(e) => onInputChange(e)}
                          placeholder={detail.placeholder}
                          type="number"
                          style={{width: '48%'}}
                          groupValue={detail.group}
                          disabled={isDisabled}
                        />
                      : ''
                      }
                      { detail.element === 'dropdown' ?
                        <SingleSelect
                          placeholder={detail.placeholder}
                          onSelect={(value) => onSingleSelect(detail.name, value)}
                          data={detail.data}
                          style={{ fontSize: '16px', width: '100%' }}
                          height="42px"
                          selectedId={detail.value}
                          fontSize="16px"
                          disabled={isDisabled}
                        />
                      : ''
                      }
                    </>
                  ))}
                </Styled.RowDiv>
              ))
            : ''
          ))}
        </Styled.ModalContentDiv>

        <Styled.ModalFooterDiv>
          { activeList !== 1 ?
            <Styled.PreviousButton onClick={onPreviousChecklist}>Previous</Styled.PreviousButton>
          : ''
          }
          { activeList < layout?.length ? getNextSubmitButtons('next') : '' }
          { activeList === layout?.length ? getNextSubmitButtons('submit') : '' }
        </Styled.ModalFooterDiv>
        
      </Styled.ModalDiv>
    </Styled.MainDiv>
  )
}

export default MeasurementModal;
