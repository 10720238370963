import React from "react";
import styled from "styled-components";
import Layout from "../../Components/Layout";

const MainWrap = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 100px;
  & > div:first-child {
    display: flex;
    align-items: center;
    color: #118D8B;
    font-size: 80px;
    font-weight: 900;
    margin-bottom: 12px;
  }
  & > div:last-child {
    font-size: 20px;
  }
`;
const NoAccessImageDiv = styled.div`
  height: 80px;
  width: 80px;
  border-radius: 80px;
  margin-right: 12px;
  border: 6px solid red;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  & img {
    height: 70px;
    width: 70px;
  }
  &:after {
    content: '';
    height: 6px;
    width: 90px;
    position: absolute;
    rotate: 45deg;
    background-color: red;
  }
`;

const NoAccessPage = () => {

  return (
    <Layout style={{ display: 'flex', flexDirection: 'column' }}>

      <MainWrap>
        <div>
          <NoAccessImageDiv>
            <img src="/user-icon.svg" />
          </NoAccessImageDiv>
          Access Denied
        </div>

        <div>You do not have permission to access this page</div>
      </MainWrap>

    </Layout>

  )
}

export default NoAccessPage;
