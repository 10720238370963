import React from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import * as Styled from "./styled";

const Layout = ({
  activePage,
  style,
  children,
  progressPercentage,
  remainingFiles,
  uploadedFiles,
  errorFiles,
  isVisible,
  uploadingMode,
}) => {
  return (
    <Styled.MainWrapDiv>
      <Header />
      <Styled.ContentDiv>
        <Sidebar
          activePage={activePage}
          progressPercentage={progressPercentage}  
          remainingFiles={remainingFiles}         
          uploadedFiles={uploadedFiles}           
          errorFiles={errorFiles}
          isVisible={isVisible}   
          uploadingMode={uploadingMode}             
        />

        <Styled.ChildrenDiv style={style}>{children}</Styled.ChildrenDiv>
      </Styled.ContentDiv>
    </Styled.MainWrapDiv>
  );
};

export default Layout;
