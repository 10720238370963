import * as Styled from "./styled";

const RadioButton = ({ style, onChange=()=>{}, onClick=()=>{}, options=[], name='', value='', disabled=false }) => {
  return (
    <Styled.WrapDiv>
      { options?.length ? options.map((item, itemIndex) => (
        <Styled.MainLabel style={style} onClick={onClick} key={`${itemIndex}_${item.id}`}>
          <input
            type="radio"
            name={name}
            value={item.id}
            onChange={(e) => onChange(e)}
            checked={value == item.id}
            disabled={disabled}
          />
          <span />
          <p>{item.name}</p>
        </Styled.MainLabel>
      )) : ''}
    </Styled.WrapDiv>
  )
}

export default RadioButton;
