import styled from "styled-components";

export const MainLabel = styled.label`
  position: relative;
  display: inline-block;
  width: ${({ width }) => width ? `${width}px` : '60px'};
  height: ${({ height }) => height ? `${height}px` : '34px'};
  & input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked ~ span {
      background-color: #118D8B;
      &:before {
        transform: translateX(${({ width, height }) => width && height ? `${width-height}px` : '26px'});
      }
    }
    &:focus ~ span {
      box-shadow: 0 0 1px #118D8B;
    }
  }
  & span {
    position: absolute;
    cursor: pointer;
    border-radius: ${({ height }) => height ? `${height}px` : '34px'};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #DB1616;
    transition: .2s;
    &:before {
      position: absolute;
      content: "";
      border-radius: 50%;
      height: ${({ height }) => height ? `${height-6}px` : '26px'};
      width: ${({ height }) => height ? `${height-6}px` : '26px'};
      left: 3px;
      bottom: 3px;
      background-color: white;
      transition: .2s;
    }
  }
`;
