import styled from "styled-components";

export const SectionHeadingDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  color: #118D8B;
  padding: 32px 32px 0px 32px;
  border-top: 1px solid #bfbfbf;
`;
