import * as Styled from "./styled";

const Tabs = ({ tabs=[], activeTab=0, setActiveTab=()=>{}, children, rightElement }) => {
  
  return (
    <Styled.MainWrapDiv>
      <Styled.HeadWrapDiv>
        <Styled.HeadDiv>
          { tabs.map((item, itemIndex) => (
            <Styled.TabDiv key={`tab_${item.title}_${itemIndex}`} active={activeTab === itemIndex ? 'true' : 'false'} onClick={() => setActiveTab(itemIndex)}>
              <img src={activeTab === itemIndex ? item.activeIcon : item.icon} />
              {item.title}
            </Styled.TabDiv>
          ))}
        </Styled.HeadDiv>

        { rightElement ?
          <Styled.HeadDiv>{rightElement}</Styled.HeadDiv>
        : ''
        }
      </Styled.HeadWrapDiv>

      {children}

    </Styled.MainWrapDiv>
  )
}

export default Tabs;
