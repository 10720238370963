import * as Styled from "./styled";

const Collapse = ({ heading='', active=false, setActive=()=>{}, children, maxHeight="1000", style={} }) => {

  return (
    <Styled.MainWrapDiv style={style}>
      <Styled.HeadWrapDiv active={active ? 'true' : 'false'} onClick={setActive}>
        <Styled.HeadingDiv>{heading}</Styled.HeadingDiv>
      </Styled.HeadWrapDiv>

      <Styled.ContentWrapDiv maxHeight={maxHeight} active={active ? 'true' : 'false'}>
        <Styled.ContentDiv>{children}</Styled.ContentDiv>
      </Styled.ContentWrapDiv>
    </Styled.MainWrapDiv>
  )
}

export default Collapse;
