import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import Heading from "../../Components/Heading";
import PrimaryButton from "../../Components/PrimaryButton";
import SearchInput from "../../Components/SearchInput";
import SingleSelect from "../../Components/SingleSelect";
import Table from "../../Components/Table";
import Modal from "../../Components/Modal";
import Input from "../../Components/Input";
import { withRouter } from "../../Utilities/withRouter";
import * as CommonStyled from "../../Common/commonStyled";
import * as Styled from "./styled";
import { fetchData } from "../../Utilities/handleRequest";
import { toastError } from "../../Utilities/toast";
import Loader from "../../Components/Loader";

const Settings = () => {
  const [selectedZone, setSelectedZone] = useState('');
  const [searchText, setSearchText] = useState('');

  const [zone, setZone] = useState('');
  const [area, setArea] = useState('');
  const [pincode, setPincode] = useState('');
  const [addError, setAddError] = useState({});

  const [allLocations, setLocations] = useState([]);
  const [actionLocation, setActionLocation] = useState(null);

  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalLocations, setTotalLocations] = useState();

  const [loading, setLoading] = useState(false);
  const [fetchingLocations, setFetchingLocations] = useState(false);

  const zonesList = [
    { id: 'All', name: 'All Zones' },
    { id: 'Zone1', name: 'Zone 1' },
    { id: 'Zone2', name: 'Zone 2' },
    { id: 'Zone3', name: 'Zone 3' },
  ];

  useEffect(() => {
    setPageNo(1);
    getLocations(1);
  }, [selectedZone, searchText, pageSize])

  const getLocations = async (activePage) => {
    setFetchingLocations(true);
    let searchParams = '';
    searchText && (searchParams += `&pincode=${searchText}`);
    selectedZone && selectedZone !== 'All' && (searchParams += `&zone=${selectedZone}`);
    let res = await fetchData('get', `location/get-location?pageNo=${activePage}&limit=${pageSize}${searchParams}`);
    setLocations(res?.data?.locations)
    setTotalLocations(res?.data?.count);
    setFetchingLocations(false);
  }

  const deleteLocation = async (locationId) => {
    setLoading(true);
    const res = await fetchData('get', `location/delete-location/${locationId}`);
    if (res?.message === 'Location deleted') {
      toastError('The location has been deleted successfully!');
      getLocations(pageNo);
    } else {
      toastError('Something went wrong');
    }
    setLoading(false);
  }

  const onInputChange = (e) => {
    const {id, value} = e.target;
    if (id === 'area') {
      setArea(value);
    } else if (id === 'pincode' && value.length < 7) {
      setPincode(value);
    }
    const error = {...addError};
    delete error[id];
    setAddError(error);
  }

  const onSelectChange = (id) => {
    setZone(id);
    const error = {...addError};
    delete error.zone;
    setAddError(error);
  }

  const validateAddLocation = () => {
    let isValid = true;
    const addingError = {...addError};

    if (!zone) {
      addingError.zone = 'Select zone';
      isValid = false;
    }
    if (!area || area.trim() === '') {
      addingError.area = 'Enter valid area';
      isValid = false;
    }
    if (!pincode || pincode.trim() === '') {
      addingError.pincode = 'Enter valid pincode';
      isValid = false;
    }

    setAddError(addingError);

    if (isValid) {
      addLocation();
    }
    return false;
  }

  const addLocation = async () => {
    setLoading(true);

    let res = await fetchData('post', `location/add-location`, {
      zone : zone?.trim(), 
      area: area?.trim(), 
      pincode : pincode?.trim()
    });

    if (res?.data) {
      hideModal();
      toastError('The location has been added successfully!');
      getLocations(pageNo);
    }

    setLoading(false);
  }

  const onDelete = (row) => {
    setActionLocation(row);
    setShowDeleteModal(true);
  }

  const onConfirmDelete = () => {
    deleteLocation(actionLocation?.id);
    setShowDeleteModal(false);
  }

  const hideModal = () => {
    setActionLocation(null);
    setShowDeleteModal(false);
    setShowAddModal(false);
    setZone('');
    setArea('');
    setPincode('');
    setAddError({});
  }

  const onPageChange = (value) => {
    getLocations(value);
    setPageNo(value);
  }

  const headings = [
    { name: 'Pincode', key: 'pincode', type: 'text', sortBy: true },
    { name: 'Zone', key: 'zone', type: 'text', sortBy: true },
    { name: 'Area', key: 'area', type: 'text', sortBy: true },
    { name: 'Action', type: 'action', actions: { delete: { onClick: onDelete, toolText: 'Delete location' } } }
  ];

  return (
    <Layout activePage="Settings" style={{ display: 'flex', flexDirection: 'column' }}>
      <Loader loading={loading} />

      <Modal
        show={showDeleteModal}
        hide={hideModal}
        okText="Delete"
        hideFooter={true}
        width="446px"
      >
        <CommonStyled.VerificationWrapDiv>
          <img src="/delete-modal-icon.svg" />
          <CommonStyled.VerifyModalHeading>Delete Location</CommonStyled.VerifyModalHeading>
          <CommonStyled.VerifyModalSubheading>Are you sure you want to delete this location?</CommonStyled.VerifyModalSubheading>
          <div>
            <button onClick={hideModal}>Cancel</button>
            <button onClick={onConfirmDelete}>Delete</button>
          </div>
        </CommonStyled.VerificationWrapDiv>
      </Modal>

      <Modal
        show={showAddModal}
        hide={hideModal}
        hideFooter={true}
        width="446px"
        heading="Add New Location"
      >
        <Styled.ColumnDiv>
          <Styled.InputWrapDiv style={{ marginBottom: addError.zone ? '0px' : '20px' }}>
            <Styled.AddEditTitleDiv notEmpty>Zone</Styled.AddEditTitleDiv>
            <SingleSelect
              data={zonesList?.filter(zone => zone?.id !== 'All')}
              placeholder="Select Zone"
              selectedId={zone}
              onSelect={onSelectChange}
              style={{ width: '100%' }}
              error={addError.zone}
            />
          </Styled.InputWrapDiv>

          <Styled.InputWrapDiv style={{ marginBottom: addError.area ? '0px' : '20px' }}>
            <Styled.AddEditTitleDiv notEmpty>Area</Styled.AddEditTitleDiv>
            <Input
              id="area"
              value={area}
              onChange={(e) => onInputChange(e)}
              placeholder="Enter area name"
              error={addError.area}
            />
          </Styled.InputWrapDiv>

          <Styled.InputWrapDiv style={{ marginBottom: addError.pincode ? '0px' : '20px' }}>
            <Styled.AddEditTitleDiv notEmpty>Pincode</Styled.AddEditTitleDiv>
            <Input
              id="pincode"
              value={pincode}
              onChange={(e) => onInputChange(e)}
              placeholder="Enter pincode"
              error={addError.pincode}
              type="number"
            />
          </Styled.InputWrapDiv>

          <Styled.AddButtonsDiv>
            <button onClick={hideModal}>Cancel</button>
            <button onClick={validateAddLocation} style={{ padding: '0px 28px' }}>Add Location</button>
          </Styled.AddButtonsDiv>
        </Styled.ColumnDiv>
      </Modal>

      <Heading
        heading="Service Location"
        subHeading="This is where you can add location of your service availability."
      >
        <PrimaryButton title="Add New Location" onClick={() => setShowAddModal(true)} />
      </Heading>

      <CommonStyled.FilterDiv>
        <SingleSelect
          data={zonesList}
          placeholder="Select Zone"
          selectedId={selectedZone}
          onSelect={(id) => {
            setFetchingLocations(true);
            setSelectedZone(id)
          }}
        />

        <SearchInput onInputChange={(e) => {
          setFetchingLocations(true);
          setSearchText(e.target.value)
        }
        }
          placeholder={'Search from pincode'} />
      </CommonStyled.FilterDiv>

      {!allLocations?.length && selectedZone === 'All' && !searchText ?
        <>
          {!fetchingLocations ?
            <CommonStyled.NoDataFoundDiv>
              <img src="/settings-color-icon.svg" alt="" />
              <div>No location added so far</div>
              <PrimaryButton title="Add New Location" onClick={() => setShowAddModal(true)} />
            </CommonStyled.NoDataFoundDiv>
            : ''
          }
        </>
        :
        <Table
          rows={allLocations}
          headings={headings}
          page={pageNo}
          limit={pageSize}
          count={totalLocations}
          onPageChange={onPageChange}
          onLimitChange={(value) => setPageSize(value)}
          noDataImage="/no-pincode-found-icon.svg"
          noDataText="No pincode found"
          loading={fetchingLocations}
        />
      }

    </Layout>
  )
}

export default withRouter(Settings);
