import { useState, useEffect } from "react";
import { fetchData } from "../../../Utilities/handleRequest";
import Table from "../../../Components/ThemeTable";
import Modal from "../../../Components/Modal";
import SingleSelect from "../../../Components/SingleSelect";
import SearchInput from "../../../Components/SearchInput";
import PrimaryButton from "../../../Components/PrimaryButton";
import { toastError } from "../../../Utilities/toast";
import * as Styled from "./styled";

const ProjectElements = ({
  params,
  projectElements=[],
  showElementsModal=false,
  hideElementsModal,
  setLoading,
  getProject
}) => {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [searchText, setSearchText] = useState('');
  const [selectedElements, setSelectedElements] = useState([]);

  const [allCategories, setAllCategories] = useState([]);
  const [allElements, setAllElements] = useState([]);

  const [elementsLoading, setElementsLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (projectElements?.length) {
      setSelectedElements([...projectElements]);
    }
  }, [projectElements]);

  useEffect(() => {
    if (showElementsModal) {
      setPage(1);
      getElements(1);
      getElementCategories();
    }
  }, [showElementsModal]);

  useEffect(() => {
    setPage(1);
    getElements(1);
  }, [searchText, limit, selectedCategory]);

  const getElementCategories = async () => {
    let res = await fetchData('get', 'element/get-all-category');
    setAllCategories([{ id: '', name: 'All Categories' }, ...res?.data?.category || []]);
  }

  const getElements = async (pageNo) => {
    if (allElements?.length === 0) {
      setElementsLoading(true);
    }
    let url = `element/get-all-elements?pageNo=${pageNo || page}&limit=${limit}`;
    if (selectedCategory) {
      url += `&category_id=${selectedCategory}`;
    }
    if (searchText) {
      url += `&title=${searchText}`;
    }

    let res = await fetchData('get', url);
    setAllElements(res?.data?.elements || []);
    setCount(res?.data?.count || 0);
    setElementsLoading(false);
  }

  const onPageChange = (value) => {
    getElements(value);
    setPage(value);
  }

  const onAddRow = (row) => {
    const elements = [...selectedElements];
    const index = elements.map(item => item.id).indexOf(row.id);
    if (index === -1) {
      elements.push(row);
    }
    setSelectedElements(elements);
    setAllElements([...allElements]);
  }

  const onRemoveRow = (row) => {
    const elements = [...selectedElements];
    const index = elements.map(item => item.id).indexOf(row.id);
    if (index > -1) {
      elements.splice(index, 1);
    }
    setSelectedElements(elements);
    setAllElements([...allElements]);
  }

  const saveElements = async () => {
    if (!selectedElements?.length) {
      toastError('No element selected!');
      return;
    }
    setLoading(true);
    let res = await fetchData('post', `projects/update-project/${params.id}`, {
      elements_metadata: { elements: selectedElements }
    });

    if (res?.data) {
      toastError('Elements updated successfully');
      await getProject(true);
    } else {
      toastError('Something went wrong!');
    }
  }

  const headings = [
    { name: 'Title', key: 'title', type: 'text', sortBy: true },
    { name: 'SKU', key: 'sku', type: 'text', sortBy: true },
    { name: 'Category', key: 'category_name', type: 'text', sortBy: true },
    { name: 'Finish', key: 'finishing', type: 'text', sortBy: true },
    { name: 'Size', key: 'size', type: 'size', sortBy: true, style: { whiteSpace: 'nowrap' }},
    { name: 'MRP', key: 'mrp', type: 'rupees', sortBy: true },
    { name: 'BX Price', key: 'bx_price', type: 'rupees', sortBy: true },
    { name: 'Procurement Cost', key: 'procurement_cost', type: 'rupees', sortBy: true },
    { name: 'Brand', key: 'brands', type: 'text', sortBy: true },
    { name: 'Action', type: 'action', actions: { addRow: {onClick: onAddRow, toolText: 'Add element'}, removeRow: {onClick: onRemoveRow, toolText: 'Delete element'} } }
  ];

  return (
    <Styled.ProjectTableWrapDiv>
      <Modal
        modalStyle={{ height: '80vh' }}
        width="90vw"
        show={showElementsModal}
        hide={hideElementsModal}
        hideFooter={true}
        closeButton={false}
        backdropClick={false}
        contentStyle={{ padding: '0px', height: '100%', maxHeight: '80vh' }}
        outerCloseButton={true}
      >
        <Styled.FilterDiv>
          <SingleSelect
            data={allCategories}
            selectedId={selectedCategory}
            onSelect={(id) => setSelectedCategory(id)}
            placeholder="Select category"
          />

          <SearchInput
            onInputChange={(e) => setSearchText(e.target.value)}
            placeholder="Search with element name"
          />
        </Styled.FilterDiv>

        <Table
          rows={allElements}
          headings={headings}
          page={page}
          limit={limit}
          count={count}
          onPageChange={onPageChange}
          onLimitChange={(value) => setLimit(value)}
          noDataImage="/no-element-found-icon.svg"
          noDataText="No element found"
          loading={elementsLoading}
          hideColumnToggle={true}
          addedRows={selectedElements}
          style={{ height: 'calc(100% - 102px)' }}
        />
      </Modal>

      <Table
        rows={selectedElements}
        headings={headings}
        count={1}
        noDataImage="/no-element-added-icon.svg"
        noDataText="No element added so far"
        hideColumnToggle={true}
        addedRows={selectedElements}
      />

      <Styled.CardFooterDiv>
        <PrimaryButton title="Update Element" style={{ borderRadius: '8px', marginLeft: '20px' }} onClick={saveElements} />
      </Styled.CardFooterDiv>
    </Styled.ProjectTableWrapDiv>
  )
}

export default ProjectElements;
