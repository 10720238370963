import { useEffect, useState } from "react";
import Heading from "../../Components/Heading";
import Layout from "../../Components/Layout";
import InputGroup from "../../Components/InputGroup";
import PrimaryButton from "../../Components/PrimaryButton";
import SecondaryButton from "../../Components/SecondaryButton";
import Loader from "../../Components/Loader";
import { fetchData } from "../../Utilities/handleRequest";
import Collapse from "./Collapse";
import * as Styled from "./styled";
import { toastError } from "../../Utilities/toast";

const Pricing = () => {
  const [activeCollapses, setActiveCollapses] = useState([]);
  const [pricingData, setPricingData] = useState(null);
  const [update, setUpdate] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPricing();
  }, []);

  const getPricing = async () => {
    setLoading(true);
    let res = await fetchData('get', 'price-data/get-all-price');
    if (res?.data) {
      let data = [];
      let price = res?.data?.price || [];
      let parentName = '';

      for (let x = 0; x < price.length; x++) {
        if (parentName !== price[x].parent_name) {
          data.push([]);
          parentName = price[x].parent_name;
        }

        data[data.length - 1].push(price[x]);
      }

      setPricingData(data || []);
    }
    setLoading(false);
  }

  const onCollapseChange = (name) => {
    const items = [...activeCollapses];
    if (items.includes(name)) {
      const index = items.indexOf(name);
      if (index > -1) {
        items.splice(index, 1);
      }
    } else {
      items.push(name);
    }
    setActiveCollapses(items);
  }

  const onInputChange = (e, collapseIndex, rowIndex, itemIndex, mode) => {
    const data = [ ...pricingData ];
    const { value } = e.target;

    if (data?.[collapseIndex]?.[rowIndex]?.price_field_data?.[itemIndex]) {
      const fieldData = data[collapseIndex][rowIndex].price_field_data[itemIndex];
      fieldData[mode] = Number(value);

      const updateData = [...update];
      const index = updateData.findIndex((item) => item.id === fieldData.id);
      if (index > -1) {
        updateData[index][mode] = Number(value);
      } else {
        if (mode === 'amount') {
          updateData.push({
            id: fieldData.id,
            amount: Number(value),
            additional: fieldData.additional
          });
        } else {
          updateData.push({
            id: fieldData.id,
            amount: fieldData.amount,
            additional: Number(value)
          });
        }
      }

      setUpdate(updateData);
      setPricingData(data);
    }
  }

  const onUpdatePrice = async () => {
    if (!update?.length) {
      toastError('Please change before save');
      return;
    }
    setLoading(true);

    let res = await fetchData('post', 'price-data/update-price', { price: update });
    if (res?.message === 'Successfully Updated') {
      getPricing();
      setUpdate([]);
      toastError('Pricing updated successfully')
    } else {
      toastError('Something went wrong!')
    }

    setLoading(false);
  }

  const onReset = () => {
    getPricing();
    setUpdate([]);
  }

  const labelArray = ['Ground to 3rd Floor', '4th Floor to 8th Floor', '9th Floor to 15th Floor', 'Above 15th Floor'];

  return (
    <Layout activePage="Pricing" style={{ display: 'flex', flexDirection: 'column' }}>
      <Loader loading={loading} />

      <Heading
        heading="Pricing"
        subHeading="This is where you can set the pricing."
      />

      {pricingData?.length ?
        <Styled.ContentWrapDiv>
          {pricingData.map((collapse, collapseIndex) => {
            return (
              <div key={`${collapseIndex}_collapse`}>
                <Collapse
                  heading={collapse?.[0]?.parent_name}
                  active={activeCollapses.includes(collapse?.[0]?.parent_name)}
                  setActive={() => onCollapseChange(collapse?.[0]?.parent_name)}
                  maxHeight="800px"
                >
                  <Styled.CollapseBodyDiv>
                    {collapse?.map((row, rowIndex) => (
                      <div key={`key_collapse_body_${rowIndex}`}>
                        {row.child_name ? <Styled.HeadingDiv>{row.child_name}</Styled.HeadingDiv> : ''}

                        {row.parent_name !== 'Margin' && row.parent_name !== 'Material Handling Charges' ?
                          <Styled.RowDiv>
                            {row?.price_field_data?.map((item, itemIndex) => (
                              <Styled.ColumnDiv key={`${item.name}_${itemIndex}_key`}>
                                <Styled.LabelDiv>{item.max === null ? `>${item.min} Sft` : `${item.min}-${item.max} Sft`}</Styled.LabelDiv>
                                <InputGroup
                                  style={{ marginBottom: '12px' }}
                                  value={item.amount}
                                  type="number"
                                  onChange={(e) => onInputChange(e, collapseIndex, rowIndex, itemIndex, 'amount')}
                                  groupAtLast={item.amount_unit === 'PERC'}
                                  groupValue={item.amount_unit === 'PERC' ? '%' : '₹'}
                                />
                              </Styled.ColumnDiv>
                            ))}
                          </Styled.RowDiv>
                          : ''
                        }

                        {row.parent_name === 'Material Handling Charges' ?
                          <Styled.ColumnDiv style={{ width: '100%' }}>
                            <Styled.RowDiv key={`key_collapse_body_${rowIndex}`}>
                              {row?.price_field_data?.map((item, itemIndex) => (
                                <Styled.ColumnDiv key={`${item.name}_${itemIndex}_key`}>
                                  <Styled.LabelDiv>{labelArray[itemIndex]}</Styled.LabelDiv>

                                  {itemIndex === 3 ?
                                    <Styled.AddInputWrapDiv>
                                      <Styled.AddInputDiv>
                                        <InputGroup
                                          value={item.amount}
                                          type="number"
                                          onChange={(e) => onInputChange(e, collapseIndex, rowIndex, itemIndex, 'amount')}
                                          groupAtLast={item.amount_unit === 'PERC'}
                                          groupValue={item.amount_unit === 'PERC' ? '%' : '₹'}
                                        />
                                        <Styled.AddIconDiv />
                                        <InputGroup
                                          value={item.additional}
                                          type="number"
                                          onChange={(e) => onInputChange(e, collapseIndex, rowIndex, itemIndex, 'additional')}
                                          groupAtLast={item.amount_unit === 'PERC'}
                                          groupValue={item.amount_unit === 'PERC' ? '%' : '₹'}
                                        />
                                      </Styled.AddInputDiv>
                                      <Styled.AdditionDiv>{'Additional per floor (max 20%)'}</Styled.AdditionDiv>
                                    </Styled.AddInputWrapDiv>
                                    :
                                    <InputGroup
                                      style={{ marginBottom: '12px' }}
                                      value={item.amount}
                                      type="number"
                                      onChange={(e) => onInputChange(e, collapseIndex, rowIndex, itemIndex, 'amount')}
                                      groupAtLast={item.amount_unit === 'PERC'}
                                      groupValue={item.amount_unit === 'PERC' ? '%' : '₹'}
                                    />
                                  }
                                </Styled.ColumnDiv>
                              ))}
                            </Styled.RowDiv>

                            <Styled.DescriptionDiv style={{ margin: '0px' }}>Material handling charges will be applied on the total execution cost</Styled.DescriptionDiv>
                          </Styled.ColumnDiv>
                          : ''
                        }

                        {row.parent_name === 'Margin' ?
                          <Styled.RowDiv>
                            <Styled.ColumnDiv style={{ width: '50%', paddingRight: '10px', boxSizing: 'border-box' }}>
                              <Styled.LabelDiv>{'BathXpertz Margin (in %)'}</Styled.LabelDiv>
                              <InputGroup
                                style={{ marginBottom: '12px' }}
                                value={row?.price_field_data?.[0]?.amount}
                                type="number"
                                onChange={(e) => onInputChange(e, collapseIndex, rowIndex, 0, 'amount')}
                                groupAtLast={row?.price_field_data?.[0]?.amount_unit === 'PERC'}
                                groupValue={row?.price_field_data?.[0]?.amount_unit === 'PERC' ? '%' : '₹'}
                              />
                              <Styled.DescriptionDiv>Margin will be applied on the total execution cost</Styled.DescriptionDiv>
                            </Styled.ColumnDiv>
                          </Styled.RowDiv>
                          : ''
                        }

                      </div>
                    ))}
                  </Styled.CollapseBodyDiv>
                </Collapse>
              </div>
            )
          })}

          <Styled.FooterDiv>
            <SecondaryButton title="Reset" onClick={onReset} />
            <PrimaryButton style={{ marginLeft: '20px', borderRadius: '8px' }} title="Save Changes" onClick={onUpdatePrice} />
          </Styled.FooterDiv>
        </Styled.ContentWrapDiv>
        : ''
      }
    </Layout>
  )
}

export default Pricing;
