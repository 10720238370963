import styled from "styled-components";

export const MainWrapDiv = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #e2f1f1;
  border-radius: 10px;
  padding: 22px 28px;
  margin: 16px 32px;
`;
export const HeadWrapDiv = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:after {
    content: '';
    position: absolute;
    right: 5px;
    top: ${({active}) => active === 'true' ? '13px' : '5px'};
    transform: ${({active}) => active === 'true' ? 'rotate(-135deg)' : 'rotate(45deg)'};
    height: 12px;
    width: 12px;
    border-bottom: 2px solid #000000;
    border-right: 2px solid #000000;
    transition: all 0.3s;
  }
`;
export const HeadingDiv = styled.div`
  font-size: 20px;
  font-weight: 700;
`;
export const ContentWrapDiv = styled.div`
  max-height: ${({active, maxHeight}) => active === 'true' ? maxHeight : '0px'};
  transition: all 0.3s;
  overflow: hidden;
`;
export const ContentDiv = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  margin-top: 22px;
`;
