import React, { useEffect, useState } from "react";
import moment from "moment";
import Pagination from "../Pagination";
import SingleSelect from "../SingleSelect";
import ToggleSwitch from "../ToggleSwitch";
import TableDropDown from "../TableDropDown";
import ToolTip from "../ToolTip";
import { numberWithCommas } from "../../Utilities/utilsFunctions";
import * as Styled from "./styled";

const ThemeTable = ({
  rows = [],
  headings = [],
  count = 0,
  limit = 10,
  page = 1,
  onPageChange = () => { },
  onLimitChange = () => { },
  noDataText = "",
  noDataImage = "",
  loading = false,
  hideColumnToggle = false,
  addedRows = [],
  style={}
}) => {
  const [sortBy, setSortBy] = useState(null);
  const [sortType, setSortType] = useState('asc');
  const [tableRows, setTableRows] = useState([]);
  const [hiddenColumns, setHiddenColumns] = useState([]);

  const entries = [
    { id: 10, name: '10' },
    { id: 15, name: '15' },
    { id: 20, name: '20' },
    { id: 25, name: '25' },
    { id: 50, name: '50' }
  ];

  useEffect(() => {
    if (!rows.length || !headings) {
      setTableRows([])
      return;
    }
    const rowArr = [];

    for (let y = 0; y < rows.length; y++) {
      const row = rows[y];
      const itemArr = [];

      for (let x = 0; x < headings.length; x++) {
        const item = headings[x];

        if (!item) {
          itemArr.push('');
          break;
        }

        if (item.type === 'action') {
          if (!item.actions) {
            itemArr.push('');
            break;
          }
          itemArr.push(
            <Styled.ActionsWrapDiv>
              {item.actions.edit ?
                <ToolTip tooltip={item.actions.edit.toolText || ''}>
                  <Styled.ActionButton onClick={() => item.actions.edit.onClick(row)}>
                    <img src="/edit-icon.svg" />
                  </Styled.ActionButton>
                </ToolTip>
                : ''
              }
              {item.actions.delete ?
                <ToolTip tooltip={item.actions.delete.toolText || ''}>
                  <Styled.ActionButton onClick={() => item.actions.delete.onClick(row)}>
                    <img src="/delete-icon.svg" />
                  </Styled.ActionButton>
                </ToolTip>
                : ''
              }
              {item.actions.view && (!item.actions.view.hide || !item.actions.view.hide(row)) ?
                <ToolTip tooltip={item.actions.view.toolText || ''}>
                  <Styled.ActionButton onClick={() => item.actions.view.onClick(row)}>
                    <img src="/view-icon.svg" />
                  </Styled.ActionButton>
                </ToolTip>
                : ''
              }
              {item.actions.settings ?
                <ToolTip tooltip={item.actions.settings.toolText || ''}>
                  <Styled.ActionButton onClick={() => item.actions.settings.onClick(row)}>
                    <img src="/settings-icon.svg" />
                  </Styled.ActionButton>
                </ToolTip>
                : ''
              }
              {item.actions.addUser ?
                <ToolTip tooltip={item.actions.addUser.toolText || ''}>
                  <Styled.ActionButton onClick={() => item.actions.addUser.onClick(row)}>
                    <img src="/add-user-icon.svg" />
                  </Styled.ActionButton>
                </ToolTip>
                : ''
              }
              {item.actions.addRow && item.actions.removeRow ?
                addedRows.length > 0 && addedRows.find(o => o.id === row.id) ?
                  <ToolTip tooltip={item.actions.removeRow.toolText || ''}>
                    <Styled.ActionButton onClick={() => item.actions.removeRow.onClick(row)}>
                      <img src="/remove-element-icon.svg" />
                    </Styled.ActionButton>
                  </ToolTip>
                  :
                  <ToolTip tooltip={item.actions.addRow.toolText || ''}>
                    <Styled.ActionButton onClick={() => item.actions.addRow.onClick(row)}>
                      <img src="/add-element-icon.svg" />
                    </Styled.ActionButton>
                  </ToolTip>
                : ''
              }
              {item.actions.toggle ?
                <ToolTip tooltip={item.actions.toggle.toolText(row[item.actions.toggle.key])}>
                  <ToggleSwitch
                    height={20}
                    width={33}
                    style={{ marginLeft: '4px' }}
                    onChange={(value) => item.actions.toggle.onChange(row, value)}
                    onClick={() => item.actions.toggle.onClick(row)}
                    value={row[item.actions.toggle.key]}
                  />
                </ToolTip>
                : ''
              }
            </Styled.ActionsWrapDiv>
          )
        } else if (item.type === 'date') {
          if (row[item.key]) {
            itemArr.push(moment(row[item.key]).format("DD MMM YYYY, hh:mm A"));
          } else {
            itemArr.push('-')
          }
        } else if (item.type === 'size') {
          itemArr.push(row[item.key]?.['length'] && row[item.key]?.['width'] ? `${row[item.key]['length']} X ${row[item.key]['width']}` : '');
        } else if (item.type === 'array') {
          let itemName = item.key && row?.[item.key] ? row?.[item.key].join(', ') : '';
          itemArr.push(itemName);
        } else if (item.type === 'status') {
          let itemValue = row[item.key];
          let itemName = itemValue ? itemValue.replace('_', ' ') : '';
          itemName = itemName.toLowerCase();
          itemName = itemName.charAt(0).toUpperCase() + itemName.slice(1);
          itemArr.push(itemName);
        } else if (item.type === 'stage') {
          let itemValue = '';
          if (!row.received_amount) {
            itemValue = 'draft';
          } else {
            if (item.key?.length === 2) {
              itemValue = row[item.key[0]]?.[item.key[1]] || 'Visit the site';
            } else {
              itemValue = ''
            }
          }
          let itemName = itemValue ? itemValue.replace('_', ' ') : '';
          itemName = itemName.toLowerCase();
          itemName = itemName.charAt(0).toUpperCase() + itemName.slice(1);
          itemArr.push(itemName);
        } else {
          if (!row[item.key]) {
            itemArr.push('-');
          } else {
            itemArr.push(row[item.key]);
          }
        }
      }
      rowArr.push(itemArr);
    }

    let sortedRows = rowArr;

    if (sortBy !== null) {
      if (sortType === 'asc') {
        sortedRows = rowArr.sort((a, b) => (a[sortBy] > b[sortBy]) ? 1 : ((b[sortBy] > a[sortBy]) ? -1 : 0));
      } else if (sortType === 'desc') {
        sortedRows = rowArr.sort((a, b) => (a[sortBy] < b[sortBy]) ? 1 : ((b[sortBy] < a[sortBy]) ? -1 : 0));
      }
    }

    setTableRows([...sortedRows]);

  }, [rows, sortBy, sortType]);

  const onSorting = (itemIndex) => {
    if (!rows || !rows.length) return;
    if (sortBy === itemIndex) {
      setSortType(() => (!sortType || sortType === 'desc' ? 'asc' : 'desc'));
    } else {
      setSortBy(itemIndex);
    }
  }

  const getTd = (type, cell) => {
    if (typeof cell === 'undefined' || cell === null) return;

    if (type === 'address') {
      return (
        <ToolTip tooltip={cell} wrap={true}>
          <Styled.SingleLineP>{cell}</Styled.SingleLineP>
        </ToolTip>
      )
    } else if (type === 'rupees') {
      return `₹${numberWithCommas(cell ? Number(cell) : 0)}`;
    } else if (type === 'transactionStatus') {
      return <Styled.StatusDiv status={cell}>{cell === 'not_attempted' ? 'Not Attempted' : 'captured' ? 'Successful' : 'Failed'}</Styled.StatusDiv>
    } else {
      return cell;
    }
  }

  return (
    <Styled.MainDiv style={style}>

      <Styled.TableWrap>
        <Styled.MainTable>
          {headings && headings.length ?
            <thead>
              <tr>
                {headings.map((item, itemIndex) => (
                  hiddenColumns.includes(item.name) ? '' :
                    item.sortBy ?
                      <th key={`${itemIndex}_${item.name}`}>
                        <Styled.SortByButton
                          onClick={() => onSorting(itemIndex)}
                          sortBy={sortBy === itemIndex}
                          sortType={sortType}
                        >
                          {item.name}
                        </Styled.SortByButton>
                      </th>
                      :
                      <th key={`${itemIndex}_${item.name}`}>
                        {item.name === 'Action' ?
                          <Styled.TableDropdownDiv>
                            <div style={{ marginRight: '30px' }}>{item.name}</div>
                            {!hideColumnToggle ?
                              <TableDropDown
                                data={headings}
                                hiddenColumns={hiddenColumns}
                                setHiddenColumns={(columns) => setHiddenColumns(columns)}
                              />
                              : ''
                            }
                          </Styled.TableDropdownDiv>
                          : item.name
                        }
                      </th>
                ))}
              </tr>
            </thead>
            : ''
          }

          {!loading && tableRows.length ?
            <tbody>
              {tableRows.map((row, rowIndex) => (
                <tr key={`tbody_tr_${rowIndex}`}>
                  {row.map((cell, cellIndex) => (
                    hiddenColumns.includes(headings[cellIndex]['name']) ? '' :
                      <td style={headings[cellIndex]['style']} key={`tr_td_${cellIndex}`}>
                        {getTd(headings[cellIndex]['type'], cell)}
                      </td>
                  ))}
                </tr>
              ))}
            </tbody>
            : ''
          }

        </Styled.MainTable>

        {loading ?
          <Styled.LoadingDiv>
            <Styled.LoaderDiv />
            <div>Loading...</div>
          </Styled.LoadingDiv>
          : ''
        }

        {!loading && !tableRows.length ?
          <Styled.NoDataDiv>
            <img src={noDataImage} />
            <div>{noDataText}</div>
          </Styled.NoDataDiv>
          : ''
        }
      </Styled.TableWrap>

      {tableRows && tableRows.length && count > 10 ?
        <Styled.PaginationWrapDiv>
          <Styled.EntriesDiv>
            Show
            <SingleSelect
              placeholder="Show"
              onSelect={onLimitChange}
              data={entries}
              style={{ width: '71px', marginLeft: '12px' }}
              height="40px"
              selectedId={limit}
              direction="up"
            />
          </Styled.EntriesDiv>

          <Pagination
            activePage={page}
            totalPages={Math.ceil((count || 0) / (limit || 0))}
            onPageChange={onPageChange}
          />
        </Styled.PaginationWrapDiv>
        : ''
      }

    </Styled.MainDiv>
  )
}

export default ThemeTable;
