import React, { useState } from "react";
import Modal from "../../Modal";
import * as Styled from "../styled";
import SecondaryButton from "../../SecondaryButton";
import { fetchData } from "../../../Utilities/handleRequest";


const ResetModal = ({
  show,
  errorMessage,
  setErrorMessage,
  successMessage,
  setSuccessMessage,
  hide,
  setModalState,
  token
}) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;


  const handleResetPassword = async () =>{
    let res = await fetchData('post', 'admin-auth/reset-password', {
      password : password?.trim(),
      token
    })

    if(res?.message === "Password updated"){
      setSuccessMessage(true);
    } else {
      // setErrorMessage(res?.error?.response?.data?.message || 'error while changing password');
    }
  }

  const checkError = () => {
    if(errorMessage || password?.trim()?.length === 0 || confirmPassword?.trim()?.length === 0
  || !password?.match(passwordRegex) || !confirmPassword?.match(passwordRegex)){
      setErrorMessage(errorMessage || 'password must have minimum 8 characters, 1 number and 1 special character or symbol');
      return true;
    }

    if(password?.trim() !== confirmPassword?.trim()){
      setErrorMessage("password does not match");
      return true;
    }
  }
  
  return (
    <Styled.MainDiv>
      <Modal show={show} width="500px" hide={hide} hideFooter={true} backdropColor={'none'} closeButton={false} backdropClick={false}>
        <Styled.ModalContent>
          <Styled.HeadingDiv>
            <img
              src="/bathx_logo.svg"
              height={"40px"}
              width={"240px"}
              alt="bathX"
            />
            {successMessage ? (
              <>
                <img
                  src="/Home/success_icon.svg"
                  alt="<>"
                  style={{marginTop : '30px'}}
                />
                <p style={{margin: '28px 0px' }}>Password Changed!</p>
              </>
            ) : (
              <p>Reset Password</p>
            )}
          </Styled.HeadingDiv>
          {successMessage ? (
            <Styled.DetailsDiv>
              <p style={{margin: '0px', textAlign: 'center'}}>Your password has been changed successfully. Please login using the new password.</p>
            </Styled.DetailsDiv>
          ) : (
            <Styled.DetailsDiv>
              <Styled.InputDiv>
                <Styled.InputLabel>New Password</Styled.InputLabel>
                <Styled.InputElement
                  type="password"
                  placeholder="Enter new password"
                  value={password}
                  error={errorMessage}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    if (!e.target.value?.match(passwordRegex)){}
                      // setErrorMessage("invalid password");
                    else setErrorMessage(null);
                  }}
                />
              </Styled.InputDiv>

              <Styled.InputDiv>
                <Styled.InputLabel>Confirm Password</Styled.InputLabel>
                <Styled.InputElement
                  type="password"
                  placeholder="Enter confirm password"
                  value={confirmPassword}
                  error={errorMessage}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    if (!e.target.value?.match(passwordRegex)){}
                      // setErrorMessage("invalid confirm password");
                    else setErrorMessage(null);
                  }}
                />
                {errorMessage && (
                  <Styled.ErrorDiv error={errorMessage}>{errorMessage}</Styled.ErrorDiv>
                )}
              </Styled.InputDiv>

              <SecondaryButton
                title={"Reset Password"}
                style={{
                  backgroundColor: "#118D8B",
                  borderRadius: "22px",
                  width: "100%",
                  textAlign: "center",
                  color: "#FFF",
                  fontFamily: "Nunito, sans-serif",
                  fontSize: "20px",
                  fontWeight: 700,
                  border: "none",
                  marginTop: "10px",
                }}
                onClick={()=> !checkError() && handleResetPassword()}
              />
            </Styled.DetailsDiv>
          )}
          <Styled.LinkDiv
            onClick={() => {
              setModalState("login");
              setErrorMessage(null);
              setSuccessMessage(false);
            }}
          >
            Back to <span style={{ color: "#118D8B" }}>Login</span>
          </Styled.LinkDiv>
        </Styled.ModalContent>
      </Modal>
    </Styled.MainDiv>
  );
};

export default ResetModal;
