import * as Styled from "./styled";

const Textarea = ({ error='', id="", value='', onChange=()=>{}, placeholder="", style }) => {
  
  return (
    <Styled.TextareaWrapDiv>
      <textarea
        id={id}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        style={{ ...style, border: error ? '1px solid #DB1616' : '1px solid #bfbfbf' }}
      />

      { error ?
        <Styled.ErrorDiv>{error}</Styled.ErrorDiv>
      : ''
      }
    </Styled.TextareaWrapDiv>
  )
}

export default Textarea;
