import styled from "styled-components";

export const MainLabel = styled.label`
  position: relative;
  display: flex;
  cursor: pointer;
  
  margin: 14px;
  
  & input {
    opacity: 0;
    display: none;
    width: 0;
    height: 0;
    &:checked ~ span {
      background-color: #118D8B;
      border: 1px solid #118D8B;
      &:before {
        display: block;
      }
    }
    &:focus ~ span {
      box-shadow: 0 0 1px #118D8B;
    }
  }

  & p {
    margin: 2px 0px 0px 0px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }

  & span {
    height: 24px;
    width: 24px;
    display: block;
    border: 1px solid #000000;
    border-radius: 4px;
    background-color: #ffffff;
    transition: .2s;
    position: relative;
    margin-right: 20px;
    flex-shrink: 0;
    &:before {
      position: absolute;
      content: "";
      height: 13px;
      width: 7px;
      border-bottom: 3px solid #ffffff;
      border-right: 3px solid #ffffff;
      transform: rotate(45deg);
      transition: .2s;
      top: 2px;
      left: 7px;
    }
  }
`;
