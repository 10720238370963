import { toast } from "react-toastify";

export const toastSuccess = (msg, otherInfo = {}) => {
  toast.clearWaitingQueue();
  return toast.success(msg, {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "colored",
    ...otherInfo,
  });
};

export const toastError = (msg, otherInfo = {}) => {
  toast.clearWaitingQueue();
  return toast.error(msg, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    pauseOnFocusLoss : false,
    theme: "colored",
    ...otherInfo,
  });
};

export const toastInfo = (msg, otherInfo = {}) => {
  toast.clearWaitingQueue();
  return toast.info(msg, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "colored",
    ...otherInfo,
  });
};