import React, { useState, useRef } from "react";
import useOutsideClick from "../../Hooks/useOutsideClick";
import * as Styled from "./styled";

const Multiselect = ({
  placeholder='Select',
  options=[],
  selected=[],
  onSelect=()=>{},
  onRemove=()=>{},
  showOther=true,
  error=''
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [otherActive, setOtherActive] = useState(false);
  const [otherInput, setOtherInput] = useState('');

  const onOptionSelect = (id) => {
    const selectedArr = [...selected];
    if (selectedArr.filter(e => e === id).length === 0) {
      onSelect(id);
    }
    setIsOpen(false);
  }

  const onSelectedOptionRemove = (id) => {
    onRemove(id);
  }

  const dropdownRef = useRef(null);
  useOutsideClick({
    ref: dropdownRef,
    handler: () => setIsOpen(false),
  });

  const onOtherButtonClick = () => {
    setOtherInput('');
    setIsOpen(false);
    setOtherActive(true);
  }

  const addOtherValue = () => {
    const otherInputTrim = otherInput.trim();
    if (!otherInputTrim) return;
    if (selected && selected.filter(e => e.toLowerCase() === otherInputTrim.toLowerCase()).length !== 0) {
      setOtherActive(false);
      return;
    }
    
    setOtherActive(false);
    const filteredObj = options.filter(e => e.name.toLowerCase() === otherInputTrim.toLowerCase())[0];

    if (filteredObj) {
      onSelect(filteredObj.name);
    } else {
      onSelect(otherInputTrim);
    }
  }
  
  const cancelOtherValue = () => {
    setOtherActive(false);
  }

  return (
    <Styled.MultiselectDiv ref={dropdownRef}>
      { otherActive && showOther ?
        <Styled.OtherInputDiv>
          <input
            onChange={(e) => setOtherInput(e.target.value)}
            style={error ? { border: '1px solid red' } : {}}
            value={otherInput}
          />
          <Styled.AddOtherButton onClick={addOtherValue} />
          <Styled.CancelOtherButton onClick={cancelOtherValue} />
        </Styled.OtherInputDiv>
      :
        <Styled.SelectWrapDiv>
          <Styled.DropdownWrapDiv>
            <Styled.SelectButton
              onClick={() => setIsOpen(!isOpen)}
              isopen={isOpen}
              style={error ? { border: '1px solid red' } : {}}
            >
              {placeholder}
            </Styled.SelectButton>

            { options && options.length ?
              <Styled.DropdownDiv style={{ maxHeight: isOpen ? '242px' : '0px' }}>
                <Styled.ListDiv>
                  { options.map((item, itemIndex) => (
                    <Styled.ListButton key={`${itemIndex}_${item.id}`} onClick={() => onOptionSelect(item.name)}>
                      <p>{item.name}</p>
                    </Styled.ListButton>
                  ))}
                </Styled.ListDiv>
              </Styled.DropdownDiv>
            : ''
            }
          </Styled.DropdownWrapDiv>

          <Styled.OtherButton onClick={onOtherButtonClick} />
        </Styled.SelectWrapDiv>
      }

      { error ?
        <Styled.ErrorDiv>{error}</Styled.ErrorDiv>
      : ''
      }

      { selected && selected.length > 0 ?
        <Styled.SelectedItemsDiv>
          { selected.map((sel, selIndex) => {
            return (
              <Styled.SelectedDiv key={`${selIndex}_multi_selected`}>
                {sel ? sel.toString() : ''}
                <button onClick={() => onSelectedOptionRemove(sel)} />
              </Styled.SelectedDiv>
            )
          })}
        </Styled.SelectedItemsDiv>
      : ''
      }
    </Styled.MultiselectDiv>
  );
}

export default Multiselect;