export const userTypes = [
  { id: 'All', name: 'All Users' },
  { id: 'ADMIN', name: 'Admin' },
  { id: 'OPERATIONS_HEAD', name: 'Operations Head' },
  { id: 'ZONAL_OPERATIONS_HEAD', name: 'Zonal Operations Head' },
  { id: 'PROJECT_MANAGER', name: 'Project Manager' },
  { id: 'SUPERVISOR', name: 'Supervisor' },
  { id: 'DESIGNER', name: 'Designer' },
  { id: 'DESIGNER_LEAD', name: 'Design Lead' },
  { id: 'PROCUREMENT_MANAGER', name: 'Procurement Manager' },
  { id: 'PROCUREMENT_HEAD', name: 'Procurement Head' },
  { id: 'SALES_AGENT', name: 'Sales Agent' },
  { id: 'SALES_LEAD', name: 'Sales Lead' },
  { id: 'CUSTOMER_SERVICE_EXECUTIVE', name: 'Customer Service Executive' },
  { id: 'CUSTOMER_SERVICE_MANAGER', name: 'Customer Service Manager' },
  { id: 'CONTRACTORS', name: 'Contractors' },
  { id: 'VENDORS', name: 'Vendors' },
];

export const userPermissions = {
  "ADMIN": ['projects', 'themes', 'elements', 'users', 'feedback', 'support', 'pricing', 'transactions', 'discount-coupon', 'appointments', 'settings', 'customers'],
  "OPERATIONS_HEAD": ['projects', 'feedback', 'support'],
  "ZONAL_OPERATIONS_HEAD": ['projects', 'feedback', 'support'],
  "PROJECT_MANAGER": ['projects', 'feedback', 'support'],
  "SUPERVISOR": ['projects', 'feedback', 'support'],
  "DESIGNER_LEAD": ['projects', 'themes', 'elements', 'feedback'],
  "DESIGNER": ['projects', 'themes', 'elements', 'feedback'],
  "PROCUREMENT_HEAD": ['projects', 'elements', 'feedback'],
  "PROCUREMENT_MANAGER": ['projects', 'elements', 'feedback'],
  "SALES_AGENT": ['projects', 'pricing'],
  "SALES_LEAD": ['projects', 'pricing'],
  "CUSTOMER_SERVICE_EXECUTIVE": ['projects', 'support'],
  "CUSTOMER_SERVICE_MANAGER": ['projects', 'support'],
  "CONTRACTORS": ['projects'],
  "VENDORS": ['projects'],
};

export const finishColors = [
  { id: 'White', name: 'White' },
  { id: 'Rose Gold', name: 'Rose Gold' },
  { id: 'Chrome Rose Gold', name: 'Chrome Rose Gold' },
  { id: 'Chrome', name: 'Chrome' },
  { id: 'General', name: 'General' },
  { id: 'Grey', name: 'Grey' },
  { id: 'Gold', name: 'Gold' },
  { id: 'Matt Black', name: 'Matt Black' },
  { id: 'Glossy Black', name: 'Glossy Black' },
  { id: 'Black', name: 'Black' },
  { id: 'Gun Metal', name: 'Gun Metal' },
];

export const elementUnits = [
  { id: 'Box', name: 'Box' },
  { id: 'Pcs', name: 'Pcs' }
];

export const miscellaneousProperties = [
  { id: 'GVT', name: 'GVT' },
  { id: 'Wall hung', name: 'Wall hung' },
  { id: 'Table top', name: 'Table top' },
  { id: '2 way', name: '2 way' },
  { id: 'Rain shower', name: 'Rain shower' }
];

export const serviceCostTypes = [
  { id: 'Fixed cost', name: 'Fixed cost' },
  { id: 'Per sq. feet cost', name: 'Per sq. feet cost' }
];

export const shapes = [
  { id: 'L Shape', name: 'L Shape' },
  { id: 'Rectangle', name: 'Rectangle' },
  { id: 'Square', name: 'Square' }
];

export const projectStatus = [
  { id: '', name: 'All' },
  { id: 'DRAFT', name: 'Draft' },
  { id: 'IN_PROGRESS', name: 'In Progress' },
  { id: 'COMPLETED', name: 'Completed' }
];

export const couponTypes = [
  { id: 'percentage', name: 'Percentage' },
  { id: 'fixed', name: 'Fixed' }
];

export const transactionStatus = [
  { id: '', name: 'All' },
  { id: 'captured', name: 'Successful' },
  { id: 'failed', name: 'Failed' },
  { id: 'not_attempted', name: 'Not Attempted' }
];
