import styled from "styled-components";

export const MainDiv = styled.div`
  height: 67px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 24px;
  border-bottom: 1px solid #F1F1F1;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 2;
  flex-shrink: 0;
`;
export const LogoImg = styled.img`
  height: 27px;
`;
export const MenuDiv = styled.div`
  display: flex;
  align-items: center;
  & > img {
    height: 20px;
    width: 20px;
    margin: 9px;
  }
  & > img:last-child {
    height: 40px;
    width: 40px;
    border-radius: 40px;
    margin: 0px 0px 0px 9px;
  }
`;