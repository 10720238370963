import React, { useState, useEffect, useRef } from "react";
import useOutsideClick from "../../Hooks/useOutsideClick";
import * as Styled from "./styled";

const SingleSelect = ({
  selectedId,
  data = [],
  onSelect,
  style,
  placeholder='Select',
  direction='down',
  height="44px",
  error='',
  search=false,
  fontSize='',
  disabled=false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [allOptions, setAllOptions] = useState([]);
  const [selectedItem, setSelectedItem] = useState(
    selectedId ? data?.find((item) => item.id === selectedId) : undefined
  );
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (data) {
      setAllOptions(data);
    }

    if (selectedId && data) {
      const newSelectedItem = data.find((item) => item.id === selectedId);
      newSelectedItem && setSelectedItem(newSelectedItem);
    } else {
      setSelectedItem(undefined);
    }
  }, [selectedId, data]);

  const handleChange = (item) => {
    if (disabled) return;
    if (item.id === selectedId) {
      setIsOpen(false);
      return;
    }
    setSelectedItem(item);
    onSelect && onSelect(item.id);
    setIsOpen(false);
    setSearchText('');
    setAllOptions(data);
  };

  const dropdownRef = useRef(null);
  useOutsideClick({
    ref: dropdownRef,
    handler: () => setIsOpen(false),
  });

  const onSearchInputChange = (e) => {
    const { value } = e.target;
    setSearchText(value);
    if (!data || !data.length) return;

    if (value) {
      const searchData = data.filter((item) => item?.name?.toLowerCase().includes(value.toLowerCase()));
      setAllOptions(searchData);
    } else {
      setAllOptions(data)
    }
  }

  return (
    <Styled.MainDiv ref={dropdownRef} style={style} fontSize={fontSize}>
      <Styled.SelectButton
        onClick={() => setIsOpen(!isOpen)}
        isopen={isOpen}
        customHeight={height}
        style={error ? { border: '1px solid #DB1616' } : {}}
      >
        <p>{selectedItem?.name || placeholder}</p>
      </Styled.SelectButton>

      { error ?
        <Styled.ErrorDiv>{error}</Styled.ErrorDiv>
      : ''
      }

      { data && data.length ?
        <Styled.DropdownDiv style={{ maxHeight: isOpen ? '242px' : '0px' }} direction={direction} customHeight={height}>
          { search && data.length > 6 ?
            <Styled.SearchInputDiv>
              <input placeholder={placeholder} onChange={(e) => onSearchInputChange(e)} value={searchText} />
              <img src="/search-icon.svg" />
            </Styled.SearchInputDiv>
          : ''
          }
          <Styled.ListDiv style={search ? {maxHeight: '194px'} : {}}>
            {allOptions.map((item, itemIndex) => (
              <Styled.ListButton key={`${itemIndex}_${item.id}_${item.name}`} onClick={() => handleChange(item)} active={selectedId === item.id ? 'true' : 'false'}>
                <p>{item.name}</p>
              </Styled.ListButton>
            ))}
          </Styled.ListDiv>
        </Styled.DropdownDiv>
      : ''
      }

    </Styled.MainDiv>
  )
}

export default SingleSelect;
