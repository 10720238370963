import { useState, useEffect } from "react";
import Layout from "../../Components/Layout";
import { withRouter } from "../../Utilities/withRouter";
import Loader from "../../Components/Loader";
import Heading from "../../Components/Heading";
import SecondaryButton from "../../Components/SecondaryButton";
import PrimaryButton from "../../Components/PrimaryButton";
import Input from "../../Components/Input";
import Textarea from "../../Components/Textarea";
import UploadImage from "../../Components/UploadImage";
import SingleSelect from "../../Components/SingleSelect";
import Table from "../../Components/ThemeTable";
import SearchInput from "../../Components/SearchInput";
import { numberWithCommas } from "../../Utilities/utilsFunctions";
import { toastError } from "../../Utilities/toast";
import { fetchData, uploadMedia } from "../../Utilities/handleRequest";
import * as Styled from "./styled";
import * as CommonStyled from "../../Common/commonStyled";

const EditElementsBundle = ({ params, navigate, location }) => {
  const pathname = location.pathname.split('/')[1];
  const [loading, setLoading] = useState(false);
  const [elementLoading, setElementLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('all');

  const [allElements, setAllElements] = useState([]);
  const [elements, setElements] = useState([]);
  const [filteredElements, setFilteredElements] = useState([]);
  const [bundleData, setBundleData] = useState({
    title: '',
    description: '',
    image_url: [],
    mrp: '',
    bx_price: '',
    procurement_cost: ''
  });

  const [allCategories, setAllCategories] = useState([]);
  const [allSubCategories, setAllSubCategories] = useState([]);
  const [elementSubCategories, setElementSubCategories] = useState([]);
  const [allBrands, setAllBrands] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [selectedBrand, setSelectedBrand] = useState('');
  const [searchText, setSearchText] = useState('');

  const [elementPage, setElementPage] = useState(1);
  const [elementLimit, setElementLimit] = useState(10);
  const [elementCount, setElementCount] = useState(0);
  const [selectedElementCategory, setSelectedElementCategory] = useState('');
  const [selectedElementSubCategory, setSelectedElementSubCategory] = useState('');
  const [selectedElementBrand, setSelectedElementBrand] = useState('');
  const [elementSearchText, setElementSearchText] = useState('');

  const [bundleError, setBundleError] = useState({});

  useEffect(() => {
    if (params?.id) {
      getElementsBundle(params.id);
    }
    getBrands();
    getCategories();
    getAllElements();
  }, [params.id]);

  useEffect(() => {
    setAllElements([...allElements]);
    const data = {...bundleData};
    data.mrp = getTotalOfBundleElements('mrp');
    data.bx_price = getTotalOfBundleElements('bx_price');
    data.procurement_cost = getTotalOfBundleElements('procurement_cost');
    setBundleData(data);

    const elementsFilter = elements?.filter((e) => (
      (!selectedCategory || e.category_id === selectedCategory) &&
      (!selectedSubCategory || e.subcategory_id === selectedSubCategory) &&
      (!selectedBrand || e.brands === selectedBrand) &&
      (!searchText || e.title.toLowerCase().includes(searchText.toLowerCase()))
    ));

    setFilteredElements(elementsFilter);
  }, [elements]);

  useEffect(() => {
    const elementsFilter = elements?.filter((e) => (
      (!selectedCategory || e.category_id === selectedCategory) &&
      (!selectedSubCategory || e.subcategory_id === selectedSubCategory) &&
      (!selectedBrand || e.brands === selectedBrand) &&
      (!searchText || e.title.toLowerCase().includes(searchText.toLowerCase()))
    ));

    setFilteredElements(elementsFilter);
  }, [selectedCategory, selectedSubCategory, selectedBrand, searchText]);

  useEffect(() => {
    setElementPage(1);
    getAllElements(1);
  }, [selectedElementCategory, selectedElementSubCategory, selectedElementBrand, elementSearchText, elementLimit]);

  useEffect(() => {
    if (selectedElementCategory) {
      getSubCategories('element', selectedElementCategory);
    }
  }, [selectedElementCategory]);

  useEffect(() => {
    if (selectedCategory) {
      getSubCategories('bundle', selectedCategory);
    }
  }, [selectedCategory]);

  const getCategories = async () => {
    let res = await fetchData('get', 'element/get-all-category');
    setAllCategories([{ id: '', name: 'All Categories' }, ...res?.data?.category || []]);
  }

  const getSubCategories = async (mode, categoryId) => {
    if (!categoryId) return;
    let res = await fetchData('get', `element/get-all-subcategory?category_id=${categoryId}`);
    if (res?.data?.subcategory && !res?.data?.subcategory?.length) {
      toastError('No subcategory found!');
    } else {
      if (mode === 'bundle') {
        setAllSubCategories([{ id: '', name: 'All SubCategories' }, ...res?.data?.subcategory || []]);
      } else {
        setElementSubCategories([{ id: '', name: 'All SubCategories' }, ...res?.data?.subcategory || []]);
      }
    }
  }

  const getBrands = async () => {
    let res = await fetchData('get', 'element/get-elements-utils');
    if (res?.data?.brands) {
      const brandsArray = res.data.brands.map((b) => ({ id: b.name, name: b.name }));
      setAllBrands([{ id: '', name: 'All Brands' }, ...brandsArray]);
    }
  }

  const getAllElements = async (pageNo) => {
    setElementLoading(true);
    let url = `element/get-all-elements?pageNo=${pageNo || elementPage}&limit=${elementLimit}`;
    if (selectedElementCategory) {
      url += `&category_id=${selectedElementCategory}`;
    }
    if (selectedElementSubCategory) {
      url += `&subcategory_id=${selectedElementSubCategory}`;
    }
    if (selectedElementBrand) {
      url += `&brand=${selectedElementBrand}`;
    }
    if (elementSearchText) {
      url += `&title=${elementSearchText}`;
    }

    let res = await fetchData('get', url);
    setAllElements(res?.data?.elements || []);
    setElementCount(res?.data?.count || 0);
    setElementLoading(false);
  }

  const getElementsBundle = async (id) => {
    setLoading(true);
    let res = await fetchData('get', `elements-bundle/get-element-bundle/${id}`);
    const data = {};
    if (res?.data) {
      data.title = res.data?.title || '';
      data.description = res.data?.description || '';
      data.image_url = res.data?.image_url || [];
      data.mrp = res.data?.mrp || 0;
      data.bx_price = res.data?.bx_price || 0;
      data.procurement_cost = res.data?.procurement_cost || 0;
      data.element_ids = res.data?.element_ids || [];
    }

    setBundleData(data);
    setElements([...res.data?.elements]);
    setLoading(false);
  }

  const onInputChange = (e) => {
    const { id, value } = e.target;
    setBundleData({ ...bundleData, [id]: value });
    removeFromError(id);
  }

  const onImageSelect = (value, id) => {
    setBundleData({ ...bundleData, [id]: value });
    removeFromError(id);
  }

  const removeFromError = (id) => {
    if (!bundleError[id]) return;
    const errorData = {...bundleError};
    delete errorData[id];
    setBundleError(errorData);
  }

  const onElementsPageChange = (value) => {
    getAllElements(value);
    setElementPage(value);
  }

  const onRowAdd = (row) => {
    const list = [...elements];
    const index = list.findIndex(e => e.id === row.id);
    if (index === -1) {
      list.push(row);
    }
    setElements(list);
  }

  const onRowRemove = (row) => {
    const list = [...elements];
    const index = list.findIndex(e => e.id === row.id);
    if (index > -1) {
      list.splice(index, 1);
    }
    setElements(list);
  }

  const getTotalOfBundleElements = (name) => {
    if (!elements || !elements.length) return 0;
    let value = 0;
    for (let x = 0; x < elements.length; x+= 1) {
      value += Number(elements[x][name]) || 0;
    }
    return value;
  }

  const isDataValid = () => {
    const errorData = {};

    if (!bundleData?.title) {
      errorData.title = 'Enter a title';
    }
    if (!bundleData?.description) {
      errorData.description = 'Enter description';
    }
    if (!bundleData?.image_url?.length) {
      errorData.image_url = 'Select minimum 1 bundle image';
    }
    /* if (!bundleData?.mrp) {
      errorData.mrp = 'Enter mrp';
    }
    if (!bundleData?.bx_price) {
      errorData.bx_price = 'Enter BX price';
    }
    if (!bundleData?.procurement_cost) {
      errorData.procurement_cost = 'Enter procurement cost';
    } */
    
    setBundleError(errorData);

    if (Object.keys(errorData).length === 0) {
      return true;
    }
    return false;
  }

  const addUpdateBundle = () => {
    if (!isDataValid()) return;

    if (!elements?.length) {
      toastError('Select minimum 1 element');
      return;
    }
    setLoading(true);
    uploadBundleImages();
  }

  const uploadBundleImages = async () => {
    let data = {...bundleData};
    let imageUrlArr = [];

    for (let x = 0; x < data.image_url.length; x++) {
      if (typeof data.image_url[x] !== 'string') {
        const mediaUrl = await uploadMedia(data.image_url[x]);
        imageUrlArr.push(mediaUrl);
      } else {
        imageUrlArr.push(data.image_url[x]);
      }
    }
    data = { ...data, image_url: imageUrlArr };
    adjustElements(data);
  }

  const adjustElements = (dataParams) => {
    if (!dataParams) {
      setLoading(false);
      return;
    }

    const data = {...dataParams};

    const element_ids = [...dataParams?.element_ids || []];
    const add_elements = [];

    for (let x = 0; x < elements?.length; x++) {
      if (!element_ids?.includes(elements[x].id)) {
        add_elements.push(elements[x].id);
      } else {
        const index = element_ids.indexOf(elements[x].id);
        element_ids.splice(index, 1);
      }
    }

    if (element_ids?.length) {
      data.delete_elements = [...element_ids];
    }

    if (add_elements?.length) {
      data.add_elements = add_elements;
    }

    delete data.element_ids;

    if (pathname === 'add-elements-bundle') {
      createBundle(data);
    } else if (pathname === 'edit-elements-bundle') {
      updateBundle(data);
    }
  }

  const createBundle = async (data) => {
    let res = await fetchData('post', 'elements-bundle/add-element-bundle', data);
    if (res?.data) {
      toastError('The bundle has been added successfully!');
      navigate('/elements-bundles');
    } else {
      toastError(res?.message || 'Something went wrong');
    }
    setLoading(false);
  }

  const updateBundle = async (data) => {
    let res = await fetchData('post', `elements-bundle/update-element-bundle/${params.id}`, data);
    if (res?.data) {
      toastError('The bundle has been updated successfully!');
      navigate('/elements-bundles');
    } else {
      toastError(res?.message || 'Something went wrong');
    }
    setLoading(false);
  }

  const onElementCategoryChange = (value) => {
    setSelectedElementCategory(value);
    setSelectedElementSubCategory('');
    setElementSubCategories([]);
  }

  const onCategoryChange = (value) => {
    setSelectedCategory(value);
    setSelectedSubCategory('');
    setAllSubCategories([]);
  }

  const headings = [
    { name: 'Element Title', key: 'title', type: 'text', sortBy: true },
    { name: 'Brand', key: 'brands', type: 'text', sortBy: true },
    { name: 'Finish', key: 'finishing', type: 'text', sortBy: true },
    { name: 'MRP', key: 'mrp', type: 'rupees', sortBy: true },
    { name: 'BX Price', key: 'bx_price', type: 'rupees', sortBy: true },
    { name: 'Procurement Cost', key: 'procurement_cost', type: 'rupees', sortBy: true },
    { name: 'Action', type: 'action', actions: { addRow: {onClick: onRowAdd, toolText: 'Add element'}, removeRow: {onClick: onRowRemove, toolText: 'Remove element'}}}
  ];

  return (
    <Layout activePage="Elements">
      <Loader loading={loading} />

      <Heading
        heading={pathname === 'add-elements-bundle' ? 'Add New Bundle' : 'Update Bundle'}
        subHeading={pathname === 'add-elements-bundle' ? 'This is where you can add new bathroom elements bundle.' : 'This is where you can update bathroom elements bundle.'}
        backTo={{ text: 'Back to all bundles', url: '/elements-bundles' }}
      />

      <Styled.CardDiv>
        <Styled.CardContentDiv>
          <Styled.ColumnDiv style={{ width: '48%' }}>
            <Styled.TitleDiv notEmpty>Bundle Title</Styled.TitleDiv>
            <Input
              id="title"
              value={bundleData?.title || ''}
              onChange={(e) => onInputChange(e)}
              placeholder={'Enter bundle title'}
              error={bundleError?.title}
            />
          </Styled.ColumnDiv>

          <Styled.ColumnDiv>
            <Styled.TitleDiv notEmpty>Description</Styled.TitleDiv>
            <Textarea
              id="description"
              value={bundleData?.description || ''}
              onChange={(e) => onInputChange(e)}
              style={{ height: '117px', padding: '12px 20px' }}
              placeholder="Enter description here"
              error={bundleError?.description}
            />
          </Styled.ColumnDiv>

          <Styled.ColumnDiv style={{ width: '48%' }}>
            <Styled.TitleDiv notEmpty>Bundle Image</Styled.TitleDiv>
            <UploadImage
              selected={bundleData?.image_url || []}
              onSelect={(value) => onImageSelect(value, 'image_url')}
              error={bundleError.image_url}
            />
          </Styled.ColumnDiv>

          <Styled.ColumnDiv>
            <Styled.TitleDiv>Bundle Details:-</Styled.TitleDiv>
            <Styled.BundleDetailsDiv>
              <p>No. of elements:  <span>{elements?.length || 0}</span></p>
              <div>
                <p>MRP:  <span>{`₹${numberWithCommas(bundleData.mrp || 0)}`}</span></p>
                <p>BX Price:  <span>{`₹${numberWithCommas(bundleData.bx_price || 0)}`}</span></p>
                <p>Procurement Cost:  <span>{`₹${numberWithCommas(bundleData.procurement_cost || 0)}`}</span></p>
              </div>
            </Styled.BundleDetailsDiv>
          </Styled.ColumnDiv>

          <Styled.TabWrapDiv>
            <Styled.TabButton active={activeTab === 'all' ? 'true' : 'false'} onClick={() => setActiveTab('all')}>All Elements</Styled.TabButton>
            <Styled.TabButton active={activeTab === 'bundle' ? 'true' : 'false'} onClick={() => setActiveTab('bundle')}>{`Elements in Bundle (${elements?.length || 0})`}</Styled.TabButton>
          </Styled.TabWrapDiv>

          <Styled.TabContentDiv style={{ display: activeTab === 'all' ? 'flex' : 'none' }}>
            <CommonStyled.FilterDiv>
              <SingleSelect
                data={allCategories}
                selectedId={selectedElementCategory}
                onSelect={(id) => onElementCategoryChange(id)}
                style={{ width: '18%' }}
                optionKey="all"
                placeholder="Select category"
              />
              <SingleSelect
                data={elementSubCategories}
                selectedId={selectedElementSubCategory}
                onSelect={(id) => setSelectedElementSubCategory(id)}
                style={{ width: '18%' }}
                optionKey="all"
                placeholder="Select subcategory"
              />
              <SingleSelect
                data={allBrands}
                selectedId={selectedElementBrand}
                onSelect={(id) => setSelectedElementBrand(id)}
                style={{ width: '18%' }}
                optionKey="all"
                placeholder="Select brand"
              />
              <SearchInput
                onInputChange={(e) => setElementSearchText(e.target.value)}
                placeholder="Search with element name"
                style={{ width: '38%' }}
              />
            </CommonStyled.FilterDiv>

            { !allElements.length && !selectedElementCategory && !selectedElementSubCategory && !selectedElementBrand && !elementSearchText ?
              <>
                { !elementLoading ?
                  <CommonStyled.NoDataFoundDiv>
                    <img src="/no-element-added-icon.svg" />
                    <div>No element added so far</div>
                    <PrimaryButton title="Upload New Element" to={'/add-elements'} />
                  </CommonStyled.NoDataFoundDiv>
                : ''
                }
              </>
            :
              <Table
                rows={allElements}
                headings={headings}
                page={elementPage}
                limit={elementLimit}
                count={elementCount}
                onPageChange={(value) => onElementsPageChange(value)}
                onLimitChange={(value) => setElementLimit(value)}
                noDataImage="/no-element-found-icon.svg"
                noDataText="No element found"
                loading={elementLoading}
                hideColumnToggle={true}
                addedRows={elements || []}
              />
            }
          </Styled.TabContentDiv>

          <Styled.TabContentDiv style={{ display: activeTab === 'bundle' ? 'flex' : 'none' }}>
            <CommonStyled.FilterDiv>
              <SingleSelect
                data={allCategories}
                selectedId={selectedCategory}
                onSelect={(id) => onCategoryChange(id)}
                style={{ width: '18%' }}
                optionKey="bundle"
                placeholder="Select category"
              />
              <SingleSelect
                data={allSubCategories}
                selectedId={selectedSubCategory}
                onSelect={(id) => setSelectedSubCategory(id)}
                style={{ width: '18%' }}
                optionKey="bundle"
                placeholder="Select subcategory"
              />
              <SingleSelect
                data={allBrands}
                selectedId={selectedBrand}
                onSelect={(id) => setSelectedBrand(id)}
                style={{ width: '18%' }}
                optionKey="bundle"
                placeholder="Select brand"
              />
              <SearchInput
                onInputChange={(e) => setSearchText(e.target.value)}
                placeholder="Search with element name"
                style={{ width: '38%' }}
              />
            </CommonStyled.FilterDiv>

            { !filteredElements.length && !selectedCategory && !selectedSubCategory && !selectedBrand && !searchText ?
              <>
                { !elementLoading ?
                  <CommonStyled.NoDataFoundDiv>
                    <img src="/no-element-added-icon.svg" />
                    <div>No element added so far</div>
                  </CommonStyled.NoDataFoundDiv>
                : ''
                }
              </>
            :
              <Table
                rows={filteredElements || []}
                headings={headings}
                page={1}
                limit={1000}
                count={0}
                onPageChange={() => {}}
                onLimitChange={() => {}}
                noDataImage="/no-element-found-icon.svg"
                noDataText="No element found"
                loading={false}
                hideColumnToggle={true}
                addedRows={filteredElements}
              />
            }
          </Styled.TabContentDiv>

        </Styled.CardContentDiv>

        <Styled.CardFooterDiv>
          <SecondaryButton title="Cancel" onClick={() => navigate('/elements-bundles')} />
          <PrimaryButton title={pathname === 'add-elements-bundle' ? 'Add Bundle' : 'Update Bundle'} style={{ borderRadius: '8px', marginLeft: '20px' }} onClick={addUpdateBundle} />
        </Styled.CardFooterDiv>
      </Styled.CardDiv>

    </Layout>
  );
}

export default withRouter(EditElementsBundle);
