import styled from "styled-components";

export const MainWrapDiv = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #bfbfbf;
  border-radius: 8px;
`;
export const HeadWrapDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const HeadDiv = styled.div`
  padding: 24px;
  display: flex;
  align-items: center;
`;
export const TabDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 21px;
  margin: 0px 8px;
  border-radius: 6px;
  font-weight: 700;
  font-size: 20px;
  background-color: ${({active}) => active === 'true' ? '#118D8B' : '#ffffff'};
  color: ${({active}) => active === 'true' ? '#ffffff' : '#000000'};
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background-color: ${({active}) => active === 'true' ? '#118D8B' : '#ebebeb'};
  }
  & img {
    margin-right: 12px;
  }
`;