import React, { useState, useEffect, useRef } from "react";
import Modal from "../Modal";
import { toastError } from "../../Utilities/toast";
import * as Styled from "./styled";

const UploadImage = ({ selected = [], onSelect = () => { }, error = '', layout="vertical", labelStyle={} }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [preview, setPreview] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState(null);

  const imageInput = useRef(null);

  useEffect(() => {
    if (!selected || !selected.length) {
      setPreview([]);
      return;
    } else {
      const changedPreview = [];
      
      for (let x = 0; x < selected.length; x++) {
        if (!selected[x]) continue;
        if (typeof selected[x] === 'string') {
          changedPreview.push(<Styled.PreviewButton style={{ backgroundImage: `url("${selected[x]}")` }} onClick={() => onImageClick(selected[x])} />);
        } else {
          const objectUrl = URL.createObjectURL(selected[x]);
          changedPreview.push(<Styled.PreviewButton style={{ backgroundImage: `url("${objectUrl}")` }} onClick={() => onImageClick(objectUrl)} />);
        }
      }
      
      setPreview(changedPreview);
    }

    setSelectedFiles(selected);

    if (imageInput && imageInput.current) {
      imageInput.current.value = '';
    }

    return () => {
      for (let x = 0; x < selected.length; x++) {
        if (typeof selected[x] !== 'string') {
          URL.revokeObjectURL(selected[x]);
        }
      }
    }
  }, [selected]);

  const onSelectFile = (e) => {
    if (!e?.target?.files?.length) return;
    const file = e.target.files[0];
    const fileArr = file.name.split('.');
    const fileExt = fileArr[fileArr.length - 1];

    if (fileExt !== 'jpg' && fileExt !== 'jpeg' && fileExt !== 'png') {
      toastError('File type not supported');
      return;
    }
    
    if (!selectedFiles || !selectedFiles.length) {
      const selectedArr = [];
      selectedArr.push(file);
      setSelectedFiles(selectedArr);
      onSelect(selectedArr);
    } else {
      if (selectedFiles.filter(e => e === file).length === 0) {
        setSelectedFiles([...selectedFiles, file]);
        onSelect([...selectedFiles, file]);
      }
    }
  }

  const onDropFile = (e) => {
    e.preventDefault();
    if (!e?.dataTransfer?.files?.length) return;
    const droppedFiles = Array.from(e.dataTransfer.files);

    const file = droppedFiles[0];
    const fileArr = file.name.split('.');
    const fileExt = fileArr[fileArr.length - 1];

    if (fileExt !== 'jpg' && fileExt !== 'jpeg' && fileExt !== 'png') {
      toastError('File type not supported');
      return;
    }
    
    if (!selectedFiles || !selectedFiles.length) {
      const selectedArr = [];
      selectedArr.push(file);
      setSelectedFiles(selectedArr);
      onSelect(selectedArr);
    } else {
      if (selectedFiles.filter(e => e === file).length === 0) {
        setSelectedFiles([...selectedFiles, file]);
        onSelect([...selectedFiles, file]);
      }
    }
  }

  const onSelectedOptionRemove = (index) => {
    let selectedArr = [...selectedFiles];
    selectedArr.splice(index, 1);

    if (selectedFiles && selectedFiles.length) {
      setSelectedFiles(selectedArr);
      onSelect(selectedArr);
    } else {
      setSelectedFiles([]);
      onSelect([]);
    }
  }

  const onImageClick = (src) => {
    setModalImage(src);
    setShowModal(true);
  }

  return (
    <Styled.UploadWrapDiv>
      <Modal
        show={showModal}
        hide={() => setShowModal(false)}
        hideFooter={true}
        closeButton={false}
        backdropClick={false}
        outerCloseButton={true}
        contentStyle={{ padding: '0px', borderRadius: '6px' }}
        modalStyle={{ background: 'none', border: 'none' }}
      >
        <Styled.ModalImg src={modalImage} />
      </Modal>

      <Styled.InputWrapLabel
        style={{ ...labelStyle, border: `1px dashed ${error ? 'red' : '#bfbfbf'}`, flexDirection: layout === 'vertical' ? 'column' : 'row-reverse' }}
        onDrop={onDropFile}
        onDragOver={(e) => e.preventDefault()}
      >
        <Styled.UploadImgDiv style={{ margin: layout === 'vertical' ? '0px 0px 12px 0px' : '0px 0px 0px 12px' }}>
          <img src="/upload-image-icon.svg" />
        </Styled.UploadImgDiv>

        <Styled.TextWrapDiv style={{ alignItems: layout === 'vertical' ? 'center' : 'flex-start' }}>
          <Styled.Text1Div>Choose file to upload</Styled.Text1Div>
          <Styled.Text2Div>or drag and drop them here</Styled.Text2Div>
          <Styled.Text3Div>File format: .jpg, .jpeg, .png  Recommended image ratio: 1:1</Styled.Text3Div>
        </Styled.TextWrapDiv>
        <input type="file" onChange={(e) => onSelectFile(e)} ref={imageInput} />
      </Styled.InputWrapLabel>

      { error ?
        <Styled.ErrorDiv>{error}</Styled.ErrorDiv>
      : ''
      }

      {preview && preview.length > 0 ?
        <Styled.SelectedItemsDiv>
          {preview.map((prev, prevIndex) => (
            <Styled.SelectedDiv key={`upload_image_preview_${prevIndex}`}>
              <Styled.ClosePreviewButton onClick={() => onSelectedOptionRemove(prevIndex)}>
                <img src="/close-preview-icon.svg" />
              </Styled.ClosePreviewButton>

              {prev}
            </Styled.SelectedDiv>
          ))
          }
        </Styled.SelectedItemsDiv>
        : ''
      }
    </Styled.UploadWrapDiv>
  );
}

export default UploadImage;