import styled from "styled-components";

export const CardDiv = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #bfbfbf;
`;
export const CardContentDiv = styled.div`
  padding: 0px 32px 32px 32px;
  display: flex;
  flex-direction: column;
`;
export const RowDiv = styled.div`
  width: 100%;
  display: flex;
`;
export const ColumnDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const TitleDiv = styled.div`
  font-size: 16px;
  padding: 30px 0px 10px 0px;
  &::after {
    content: '*';
    color: #DB1616;
    display: ${({ notEmpty }) => notEmpty ? 'inline' : 'none'};
  }
`;
export const SizeDiv = styled.div`
  width: 30%;
  height: 42px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #bfbfbf;
  & input {
    width: 100%;
    border: none !important;
    flex-shrink: 1;
  }
  & div {
    width: 48px;
    height: 34px;
    flex-shrink: 0;
    border-right: 1px solid #bfbfbf;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const CardFooterDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #bfbfbf;
  padding: 32px;
`;
export const ErrorDiv = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #DB1616;
  text-align: right;
`;
export const BundleDetailsDiv = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #e2f1f1;
  padding: 24px;
  border-radius: 8px;
  & p {
    margin: 0px;
    font-size: 16px;
    font-weight: 700;
  }
  & span {
    color: #118D8B;
    font-size: 16px;
    font-weight: 700;
    margin-left: 6px;
  }
  & > div {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    & > p {
      width: 30%;
    }
  }
`;
export const TabWrapDiv = styled.div`
  display: flex;
  margin-top: 20px;
`;
export const TabButton = styled.button`
  padding: 16px 28px;
  border-bottom: 2px solid ${({ active }) => active === 'true' ? '#118D8B' : 'transparent'};
  font-size: 16px;
  font-weight: 700;
  background: none;
  color: ${({ active }) => active === 'true' ? '#118D8B' : '#000000'};
  transition: all 0.2s;
`;
export const TabContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0px 0px 0px;
  min-height: 700px;
`;