import styled from "styled-components";

export const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
export const SelectButton = styled.button`
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ isopen }) => isopen ? '#118D8B' : '#000000'};
  border-radius: 4px;
  position: relative;

  &:before {
    content: '';
    height: 16px;
    width: 3px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 2px;
  }
  &:after {
    content: '';
    height: 3px;
    width: 16px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 2px;
  }
`;
export const DropdownDiv = styled.div`
  width: 140px;
  overflow: hidden;
  transition: all 0.3s;
  position: absolute;
  top: 32px;
  right: 0px;
  z-index: 2;
`;
export const ListDiv = styled.div`
  max-height: 232px;
  display: flex;
  flex-direction: column;
  border: 1px solid #ADB8AE;
  border-radius: 8px;
  overflow: auto;
  transition: all 0.3s;
  padding: 4px 0px;
  background-color: #ffffff;
`;
export const ListButton = styled.button`
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  color: #000000;
  padding: 0px 15px;
  font-size: 14px;
  font-weight: 700;
  z-index: 2;
  flex-shrink: 0;
  &:hover {
    background-color: #F1F1F1;
  }
  & p {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const CheckboxLabel = styled.label`
  margin: 0px;
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;
  background-color: #ffffff;
  padding: 8px 12px;
  
  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  & span {
    display: flex;
    height: 19px;
    width: 19px;
    background-color: ${({ ischecked }) => ischecked ? '#118D8B' : '#ffffff'};
    border: 1px solid #ADB8AE;
    border-radius: 4px;
    margin: 0px;
    position: relative;
    flex-shrink: 0;
    &:after {
      content: "";
      position: absolute;
      top: 3px;
      left: 7px;
      display: ${({ ischecked }) => ischecked ? 'block' : 'none'};
      height: 8px;
      width: 4px;
      transform: rotate(45deg);
      border-bottom: 2px solid #ffffff;
      border-right: 2px solid #ffffff;
    }
  }
  &:hover {
    background-color: #F0F0F0;
  }
  & p {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0px 0px 0px 12px;
  }
`;
