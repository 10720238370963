import styled from "styled-components";

export const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
export const InputWrapDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;
export const AddButtonsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & > button {
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 0px 28px;
    font-size: 16px;
    font-weight: 600;
  }
  & > button:first-child {
    color: #000000;
    border: 1px solid #000000;
    margin-right: 20px;
    background-color: #ffffff;
  }
  & > button:last-child {
    color: #ffffff;
    background-color: #118D8B;
    border: 1px solid #118D8B;
    padding: 0px 48px;
  }
`;
export const AddEditTitleDiv = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
  &::after {
    content: '*';
    color: #DB1616;
    display: ${({ notEmpty }) => notEmpty ? 'inline' : 'none'};
  }
`;
