import React, { useEffect, useState } from "react";
import { useSearchParams } from 'react-router-dom';
import { LoginModal, ForgotModal, ResetModal } from "../../Components/HomeScreenModals/index";
import * as Styled from "./styled";

const Home = () => {
  const [showModal, setShowModal] = useState(true);
  const [modalState, setModalState] = useState("login");
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [searchParams] = useSearchParams();

  const token = searchParams.get('token');

  useEffect(() => {
    if (token)
      setModalState('reset');
  }, [token])

  return (
    <Styled.MainDiv>
      {modalState === "login" && (
        <LoginModal
          show={showModal}
          hide={() => setShowModal(false)}
          setModalState={setModalState}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}

      {modalState === "forgot" && (
        <ForgotModal
          show={showModal}
          hide={() => setShowModal(false)}
          setModalState={setModalState}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
        />
      )}

      {modalState === "reset" && (
        <ResetModal
          show={showModal}
          hide={() => setShowModal(false)}
          setModalState={setModalState}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
          token={token}
        />
      )}
    </Styled.MainDiv>
  );
};

export default Home;
