import React, { useState, useEffect, useRef } from "react";
import Modal from "../../../../Components/Modal";
import { toastError } from "../../../../Utilities/toast";
import * as Styled from "./styled";

const UploadFile = ({ selected = [], changeFiles = () => { }, error = '', labelStyle={}, disabled=false }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState(null);
    
  const imageInput = useRef(null);

  useEffect(() => {
    if (imageInput && imageInput.current) {
      imageInput.current.value = '';
    }

    return () => {
      for (let x = 0; x < selected.length; x++) {
        if (typeof selected[x] !== 'string') {
          URL.revokeObjectURL(selected[x]);
        }
      }
    }
  }, [selected]);

  const getUploadedFileIcon = (fileExtension, file) => {
    if (fileExtension === 'xls' || fileExtension === 'xlsx') {
      return <Styled.PreviewButton style={{ backgroundImage: 'url("/xls-file-icon.svg")' }} onClick={() => downloadFile(file)} />;
    } else if (fileExtension === 'doc' || fileExtension === 'docx') {
      return <Styled.PreviewButton style={{ backgroundImage: 'url("/doc-file-icon.svg")' }} onClick={() => downloadFile(file)} />;
    } else if (fileExtension === 'pdf') {
      return <Styled.PreviewButton style={{ backgroundImage: 'url("/pdf-file-icon.svg")' }} onClick={() => downloadFile(file)} />;
    } else {
      return <Styled.PreviewButton style={{ backgroundImage: `url("${file}")` }} onClick={() => onImageClick(file)} />;
    }
  }

  const getUploadingFileIcon = (fileExtension) => {
    if (fileExtension === 'xls' || fileExtension === 'xlsx') {
      return <Styled.PreviewButton style={{ backgroundImage: 'url("/xls-file-icon.svg")' }} />;
    } else if (fileExtension === 'doc' || fileExtension === 'docx') {
      return <Styled.PreviewButton style={{ backgroundImage: 'url("/doc-file-icon.svg")' }} />;
    } else if (fileExtension === 'pdf') {
      return <Styled.PreviewButton style={{ backgroundImage: 'url("/pdf-file-icon.svg")' }} />;
    }
    return false;
  }

  const onSelectFile = (e) => {
    if (!e?.target?.files?.length) return;
    const file = e.target.files[0];
    const fileArr = file.name.split('.');
    const fileExt = fileArr[fileArr.length - 1];
    const validExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'xls', 'xlsx'];

    if (!fileExt || !validExtensions.includes(fileExt)) {
      toastError('File type not supported');
      return;
    }
    
    if (!selected || !selected.length) {
      const selectedArr = [];
      selectedArr.push(file);
      changeFiles(selectedArr);
    } else {
      if (selected.filter(e => e === file).length === 0) {
        changeFiles([...selected, file]);
      }
    }
  }

  const onDropFile = (e) => {
    e.preventDefault();
    if (disabled) return;
    if (!e?.dataTransfer?.files?.length) return;
    const droppedFiles = Array.from(e.dataTransfer.files);
    const validExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'xls', 'xlsx'];

    const file = droppedFiles[0];
    const fileArr = file.name.split('.');
    const fileExt = fileArr[fileArr.length - 1];

    if (!fileExt || !validExtensions.includes(fileExt)) {
      toastError('File type not supported');
      return;
    }
    
    if (!selected || !selected.length) {
      const selectedArr = [];
      selectedArr.push(file);
      changeFiles(selectedArr);
    } else {
      if (selected.filter(e => e === file).length === 0) {
        changeFiles([...selected, file]);
      }
    }
  }

  const onSelectedOptionRemove = (index) => {
    if (disabled) return;
    let selectedArr = [...selected];
    selectedArr.splice(index, 1);

    if (selected && selected.length) {
      changeFiles(selectedArr);
    } else {
      changeFiles([]);
    }
  }

  const downloadFile = (url) => {
    window.open(url);
    return false;
  }

  const onImageClick = (src) => {
    setModalImage(src);
    setShowModal(true);
  }

  const showPreview = (file) => {
    if (!file) return;
    let fileExtension = '';
    if (typeof file === 'string') {
      const fileArr = file?.split('.') || [];
      fileExtension = fileArr[fileArr.length - 1];
      return getUploadedFileIcon(fileExtension, file);
    } else {
      const fileName = file.name;
      const fileArr = fileName?.split('.') || [];
      fileExtension = fileArr[fileArr.length - 1];

      if (getUploadingFileIcon(fileExtension)) {
        return getUploadingFileIcon(fileExtension);
      } else {
        if (typeof file?.name === 'string') {
          const objectUrl = URL.createObjectURL(file);
          return <Styled.PreviewButton style={{ backgroundImage: `url("${objectUrl}")` }} onClick={() => onImageClick(objectUrl)} />;
          /* const filePreview = [];
          var reader = new FileReader();
          reader.onload = function(event) {
            console.log('reader event ==========>', event.target.result);
            filePreview.push(<Styled.PreviewButton style={{ backgroundImage: `url("${event.target.result}")` }} onClick={() => onImageClick(event.target.result)} />);
          }
          reader.readAsDataURL(file);
          return filePreview; */
        }
      }
    }
  }

  return (
    <Styled.UploadWrapDiv>
      <Modal
        show={showModal}
        hide={() => setShowModal(false)}
        hideFooter={true}
        closeButton={false}
        backdropClick={false}
        outerCloseButton={true}
        contentStyle={{ padding: '0px', borderRadius: '6px' }}
        modalStyle={{ background: 'none', border: 'none' }}
      >
        <Styled.ModalImg src={modalImage} />
      </Modal>

      <Styled.InputWrapLabel
        style={{ ...labelStyle, border: `1px dashed ${error ? 'red' : '#bfbfbf'}`, backgroundColor: disabled ? '#f8f8f8' : '#ffffff' }}
        onDrop={onDropFile}
        onDragOver={(e) => e.preventDefault()}
      >
        <Styled.TextWrapDiv>
          <Styled.Text1Div>Choose file to upload</Styled.Text1Div>
          <Styled.Text2Div>or drag and drop them here</Styled.Text2Div>
          <Styled.Text3Div>File format: .jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx .pdf</Styled.Text3Div>
        </Styled.TextWrapDiv>

        <Styled.UploadImgDiv>
          <img src="/upload-image-icon.svg" />
        </Styled.UploadImgDiv>

        <input type="file" onChange={(e) => onSelectFile(e)} ref={imageInput} disabled={disabled} />
      </Styled.InputWrapLabel>

      { error ?
        <Styled.ErrorDiv>{error}</Styled.ErrorDiv>
      : ''
      }

      {selected && selected.length > 0 ?
        <Styled.SelectedItemsDiv>
          {selected?.map((item, itemIndex) => {
            return (
              <Styled.SelectedDiv key={`uploadFile_${itemIndex}`}>
                <Styled.ClosePreviewButton onClick={() => onSelectedOptionRemove(itemIndex)}>
                  <img src="/close-preview-icon.svg" />
                </Styled.ClosePreviewButton>
                {showPreview(item)}
              </Styled.SelectedDiv>
            )
          })}
        </Styled.SelectedItemsDiv>
        : ''
      }
    </Styled.UploadWrapDiv>
  );
}

export default UploadFile;