import styled from "styled-components";

export const MainWrapDiv = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #F1F1F1;
`;
export const ContentDiv = styled.div`
  height: 100vh;
  display: flex;
  flex-grow: 1;
  padding-top: 67px;
  box-sizing: border-box;
`;
export const ChildrenDiv = styled.div`
  padding: 28px;
  flex-grow: 1;
  position: relative;
  overflow: auto;
`;
