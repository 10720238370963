import { useEffect, useRef, useState } from "react";
import moment from "moment";
import * as Styled from "./styled";

const InputDate = ({ error='', id="", value='', onChange=()=>{}, placeholder="", style={}, type="date", min, max, disabled=false }) => {
  const [dateValue, setDateValue] = useState('');
  const dateInput = useRef(null);

  useEffect(() => {
    setDateValue(value);
  }, [value]);

  const onInputChange = (e) => {
    setDateValue(e.target.value);
    onChange(e);
  }

  return (
    <Styled.InputWrapDiv style={style}>
      <Styled.InputLabel
        style={{ border: error ? '1px solid #DB1616' : '1px solid #bfbfbf', backgroundColor: disabled ? '#f8f8f8' : '#ffffff' }}
      >
        { type === 'date' ?
          dateValue ? moment(dateValue).format("DD MMM YYYY") : (placeholder || 'Select date')
        : ''
        }
        { type === 'datetime-local' ?
          dateValue ? moment(dateValue).format("DD MMM YYYY, hh:mm A") : (placeholder || 'Select date and time')
        : ''
        }
        <img src="/date-input-icon.svg" />
        <input
          id={id}
          ref={dateInput}
          value={value}
          type={type}
          min={min}
          max={max}
          onChange={onInputChange}
          disabled={disabled}
        />
      </Styled.InputLabel>

      { error ?
        <Styled.ErrorDiv>{error}</Styled.ErrorDiv>
      : ''
      }
    </Styled.InputWrapDiv>
  )
}

export default InputDate;
