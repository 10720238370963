import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import Heading from "../../Components/Heading";
import PrimaryButton from "../../Components/PrimaryButton";
import SearchInput from "../../Components/SearchInput";
import SingleSelect from "../../Components/SingleSelect";
import Table from "../../Components/ThemeTable";
import Modal from "../../Components/Modal";
import { fetchData } from "../../Utilities/handleRequest";
import { withRouter } from "../../Utilities/withRouter";
import { toastError } from "../../Utilities/toast";
import * as CommonStyled from "../../Common/commonStyled";

const DiscountCoupon = ({ navigate }) => {
  const [selectedType, setSelectedType] = useState('All');
  const [searchText, setSearchText] = useState('');

  const [allCoupons, setAllCoupons] = useState([]);

  const [loading, setLoading] = useState(false);
  const [actionCoupon, setActionCoupon] = useState(null);
  const [showActiveModal, setShowActiveModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  useEffect(() => {
    setPage(1);
    getCoupons(1);
  }, [searchText, limit, selectedType]);

  const getCoupons = async (pageNo) => {
    setLoading(true);
    let url = `coupon/get-all-coupons?pageNo=${pageNo || page}&limit=${limit}`;
    if (selectedType && selectedType !== 'All') {
      url += `&type=${selectedType}`;
    }
    if (searchText) {
      url += `&code=${searchText}`;
    }

    let res = await fetchData('get', url);
    setAllCoupons(res?.data?.coupon || []);
    setCount(res?.data?.count || 0);
    setLoading(false);
  }

  const onDelete = (row) => {
    setActionCoupon(row);
    setShowDeleteModal(true);
  }

  const onConfirmDelete = async () => {
    setLoading(true);
    let res = await fetchData('post', `coupon/delete-coupon/${actionCoupon.id}`);
    if (res?.message === 'Successfully Deleted') {
      hideModal();
      toastError('The coupon has been deleted successfully!');
      getCoupons();
    } else {
      toastError('Something went wrong');
      setLoading(false);
    }
  }

  const onEdit = (row) => {
    navigate(`/edit-discount-coupon/${row.id}`);
  }

  const onPageChange = (value) => {
    getCoupons(value);
    setPage(value);
  }

  const onStatusClick = async (row) => {
    setActionCoupon(row);
    setShowActiveModal(true);
  }

  const onStatusConfirm = async() => {
    setLoading(true);
    let res = await fetchData('post', `coupon/update-coupon/${actionCoupon.id}`, { status: !actionCoupon.status });
    if (res?.data) {
      hideModal();
      toastError(`The coupon has been ${actionCoupon.status ? 'inactivated' : 'activated'} successfully!`);
      getCoupons();
    } else {
      toastError('Something went wrong');
      setLoading(false);
    }
  }

  const hideModal = () => {
    setActionCoupon(null);
    setShowActiveModal(false);
    setShowDeleteModal(false);
  }

  const types = [
    { id: 'All', name: 'Select type' },
    { id: 'percentage', name: 'Percentage' },
    { id: 'fixed', name: 'Fixed' }
  ]

  const headings = [
    { name: 'Title', key: 'title', type: 'text', sortBy: true },
    { name: 'Coupon code', key: 'code', type: 'text', sortBy: true },
    { name: 'Category', key: 'category_name', type: 'text', sortBy: true },
    { name: 'Type', key: 'type', type: 'text', sortBy: true },
    { name: 'Limit', key: 'limit', type: 'text', sortBy: true },
    { name: 'Expiry Date', key: 'expiry_date', type: 'date', sortBy: true },
    { name: 'Status', type: 'action', actions: { toggle: { key: 'status', onClick: onStatusClick, onChange: ()=>{}, toolText: (value) => value ? 'Active' : 'Inactive' }}, sortBy: true },
    { name: 'Action', type: 'action', actions: { edit: {onClick: onEdit, toolText: 'Edit coupon'}, delete: {onClick: onDelete, toolText: 'Delete coupon'} } }
  ];

  return (
    <Layout activePage="Discount Coupon" style={{ display: 'flex', flexDirection: 'column' }}>

      <Modal
        show={showActiveModal}
        hide={hideModal}
        hideFooter={true}
        width="446px"
      >
        <CommonStyled.VerificationWrapDiv>
          <img src="/active-inactive-icon.svg" />
          <CommonStyled.VerifyModalHeading style={{ color: '#118D8B' }}>{actionCoupon?.status ? 'Inactive Coupon' : 'Active Coupon'}</CommonStyled.VerifyModalHeading>
          <CommonStyled.VerifyModalSubheading>
            { actionCoupon?.status ?
              'Are you sure you want to inactive this coupon?'
            :
              'Are you sure you want to active this coupon?'
            }
          </CommonStyled.VerifyModalSubheading>
          <div>
            <button onClick={hideModal}>Cancel</button>
            <button onClick={onStatusConfirm}>Confirm</button>
          </div>
        </CommonStyled.VerificationWrapDiv>
      </Modal>

      <Modal
        show={showDeleteModal}
        hide={hideModal}
        okText="Delete"
        hideFooter={true}
        width="446px"
      >
        <CommonStyled.VerificationWrapDiv>
          <img src="/delete-modal-icon-green.svg" />
          <CommonStyled.VerifyModalHeading style={{ color: '#118D8B' }}>Delete Coupon</CommonStyled.VerifyModalHeading>
          <CommonStyled.VerifyModalSubheading>Are you sure you want to delete this coupon?</CommonStyled.VerifyModalSubheading>
          <div>
            <button onClick={hideModal}>Cancel</button>
            <button onClick={onConfirmDelete}>Delete</button>
          </div>
        </CommonStyled.VerificationWrapDiv>
      </Modal>

      <Heading
        heading="Discount Coupon"
        subHeading="This is where you can view the listing of all the discount coupon and can add new coupon."
      >
        <PrimaryButton title="Add New Coupon" style={{ marginLeft: '20px' }} to={'/add-discount-coupon'} />
      </Heading>

      <CommonStyled.FilterDiv>
        <SingleSelect
          data={types}
          selectedId={selectedType}
          onSelect={(id) => setSelectedType(id)}
        />

        <SearchInput
          onInputChange={(e) => setSearchText(e.target.value)}
          placeholder="Search with coupon code"
        />
      </CommonStyled.FilterDiv>

      { !allCoupons.length && selectedType === 'All' && !searchText ?
        <>
          { !loading ?
            <CommonStyled.NoDataFoundDiv>
              <img src="/no-coupon-added-icon.svg" />
              <div>No discount coupon added so far</div>
              <PrimaryButton title="Add New Coupon" to={'/add-discount-coupon'} />
            </CommonStyled.NoDataFoundDiv>
          : ''
          }
        </>
      :
        <Table
          rows={allCoupons}
          headings={headings}
          page={page}
          limit={limit}
          count={count}
          onPageChange={onPageChange}
          onLimitChange={(value) => setLimit(value)}
          noDataImage="/no-coupon-found-icon.svg"
          noDataText="No discount coupon found"
          loading={loading}
          hideColumnToggle={true}
        />
      }

    </Layout>
  )
}

export default withRouter(DiscountCoupon);
