import styled from "styled-components";
import { Link } from "react-router-dom";

export const MainDiv = styled.div`
  width: 206px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  background-color: #ffffff;
`;
export const LinkWrapDiv = styled.div`
  width: 206px;
  display: flex;
  flex-direction: column;
  padding-top: 22px;
  overflow-x: auto;
`;
export const ItemLink = styled(Link)`
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 24px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  color: ${({ active }) => active === 'true' ? '#ffffff' : '#000000'};
  background-color: ${({ active }) => active === 'true' ? '#118D8B' : '#ffffff'};
  transition: all 0.3s;
  flex-shrink: 0;
  & > img {
    height: 24px;
    width: 24px;
    margin-right: 12px;
  }
  &:hover {
    background-color: ${({ active }) => active === 'true' ? '#118D8B' : '#F1F1F1'};
  }
`;
export const ImportWrapDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 18px 0px 18px;
  border-bottom: 1px solid #bfbfbf;
  overflow: hidden;
  flex-shrink: 0;
  transition: all 0.3s;
  background-color: #ffffff;
`;
export const ImportHeading = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #118d8b;
  position: relative;
  cursor: pointer;
  padding: 12px 0px;
  &:after {
    content: '';
    height: 8px;
    width: 8px;
    border-bottom: 3.5px solid #118d8b;
    border-right: 3.5px solid #118d8b;
    position: absolute;
    transition: all 0.3s;
    transform: rotate(${({active}) => active ? '-135' : '45'}deg);
    right: -2px;
    top: ${({active}) => active ? '20px' : '16px'};
  }
`;
export const ImportDetail = styled.div`
  font-size: 12px;
  margin-bottom: 4px;
`;
export const ProgressWrapDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
`;
export const ProgressDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 8px;
  border-radius: 8px;
  background-color: #c3e3e2;
  overflow: hidden;
  flex-grow: 1;
  & div {
    height: 8px;
    border-radius: 8px;
    background-color: #118D8B;
  }
`;
export const RemainingDiv = styled.div`
  font-size: 12px;
  padding: 4px 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
  & div {
    height: 12px;
    width: 12px;
    background-color: #ffffff;
    border-radius: 16px;
    border: 2px solid #b5b5b5;
    margin-right: 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &:before {
      content: '';
      height: 6px;
      width: 6px;
      border-radius: 6px;
      background-color: #b5b5b5;
    }
  }
`;
export const CompletedDiv = styled.div`
  font-size: 12px;
  color: #118d8b;
  padding: 4px 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
  & div {
    height: 16px;
    width: 16px;
    background-color: #118d8b;
    border-radius: 16px;
    margin-right: 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &:before {
      content: '';
      height: 7px;
      width: 3px;
      border-bottom: 2px solid #ffffff;
      border-right: 2px solid #ffffff;
      position: absolute;
      transform: rotate(45deg);
      margin-bottom: 2px;
    }
  }
`;
export const ErrorDiv = styled.div`
  font-size: 12px;
  color: red;
  padding: 4px 0px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
  & div {
    height: 16px;
    width: 16px;
    background-color: red;
    border-radius: 16px;
    margin-right: 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &:before {
      content: '';
      height: 10px;
      width: 2px;
      background-color: #ffffff;
      position: absolute;
      transform: rotate(45deg);
    }
    &:after {
      content: '';
      height: 10px;
      width: 2px;
      background-color: #ffffff;
      position: absolute;
      transform: rotate(-45deg);
    }
  }
`;
