import React, { useState, useEffect, useRef } from "react";
import useOutsideClick from "../../Hooks/useOutsideClick";
import { toastError } from "../../Utilities/toast";
import { fetchData, uploadMedia } from "../../Utilities/handleRequest";
import * as Styled from "./styled";

const CategorySelect = ({
  placeholder='Select',
  options=[],
  selectedId='',
  selectedName='',
  onSelect=()=>{},
  error='',
  updateList=()=>{},
  noIcon=false,
  mode,
  selectedCategory
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [otherInput, setOtherInput] = useState('');
  const [categoryIcon, setCategoryIcon] = useState(null);
  const [otherActive, setOtherActive] = useState(false);

  const imageInput = useRef(null);
  const dropdownRef = useRef(null);

  const addNewCategory = async (data) => {
    const res = await fetchData('post', 'element/add-category', data);
    if (res?.data) {
      return res.data;
    }
    return false;
  }

  const addNewSubCategory = async (data) => {
    const res = await fetchData('post', 'element/add-subcategory', data);
    if (res?.data) {
      return res.data;
    }
    return false;
  }

  const handleChange = (item) => {
    if (item.id === selectedId) {
      setIsOpen(false);
      return;
    }
    onSelect(item.id, item.name);
    setIsOpen(false);
  };

  useOutsideClick({
    ref: dropdownRef,
    handler: () => setIsOpen(false),
  });

  const onCategoryIconSelect = (e) => {
    if (!e || !e.target || !e.target.files || e.target.files.length === 0) return;
    const file = e.target.files[0];
    const fileArr = file.name.split('.');
    const fileExt = fileArr[fileArr.length - 1];

    if (fileExt !== 'jpg' && fileExt !== 'jpeg' && fileExt !== 'png' && fileExt !== 'svg') {
      toastError('File type not supported');
      return;
    }
    setCategoryIcon(file);
  }

  const onOtherButtonClick = () => {
    setOtherInput('');
    setIsOpen(false);
    setOtherActive(true);
  }

  const addOtherValue = async () => {
    const otherInputTrim = otherInput.trim();
    if (!otherInputTrim) return;
    if (selectedName && selectedName.toLowerCase() === otherInputTrim.toLowerCase()) {
      toastError('Category already exist!');
      return;
    }
    if (!noIcon && !categoryIcon) {
      toastError('Select category icon!');
      return;
    }

    let newCategory = null;

    if (mode === 'category') {
      const iconUrl = await uploadMedia(categoryIcon);
      newCategory = await addNewCategory({ name: otherInput, image_url: iconUrl });
    } else if (mode === 'subcategory') {
      newCategory = await addNewSubCategory({ name: otherInput, category_id: selectedCategory });
    }

    if (newCategory) {
      onSelect(newCategory.id, newCategory.name);
      setOtherActive(false);
      if (mode === 'category') {
        updateList();
      } else if (mode === 'subcategory') {
        updateList(selectedCategory);
      }
    } else {
      toastError('Something went wrong!');
    }
  }
  
  const cancelOtherValue = () => {
    setOtherActive(false);
    setOtherInput('');
    setCategoryIcon(null);
  }

  return (
    <Styled.MainDiv ref={dropdownRef}>
      { otherActive ?
        <Styled.OtherInputDiv>
          <Styled.InputWrapDiv>
            <input
              onChange={(e) => setOtherInput(e.target.value)}
              style={error ? { border: '1px solid red' } : {}}
              value={otherInput}
            />
            { !noIcon ?
              <label>
                Upload Icon
                <input type="file" style={{ display: 'none' }} ref={imageInput} onChange={(e) => onCategoryIconSelect(e)} />
              </label>
            : ''
            }
          </Styled.InputWrapDiv>
          <Styled.AddOtherButton onClick={addOtherValue} />
          <Styled.CancelOtherButton onClick={cancelOtherValue} />
        </Styled.OtherInputDiv>
      :
        <Styled.SelectButtonDiv>
          <Styled.DropdownWrapDiv>
            <Styled.SelectButton
              onClick={() => setIsOpen(!isOpen)}
              isopen={isOpen}
              style={error ? { border: '1px solid #DB1616' } : {}}
            >
              <p>{selectedName || placeholder}</p>
            </Styled.SelectButton>

            { options && options.length ?
              <Styled.DropdownDiv style={{ maxHeight: isOpen ? '242px' : '0px' }}>
                
                <Styled.ListDiv>
                  {options.map((item) => (
                    <Styled.ListButton key={`${item.id}_${item.name}`} onClick={() => handleChange(item)} active={selectedId === item.id ? 'true' : 'false'}>
                      <p>{item.name}</p>
                    </Styled.ListButton>
                  ))}
                </Styled.ListDiv>
              </Styled.DropdownDiv>
            : ''
            }
          </Styled.DropdownWrapDiv>

          <Styled.OtherButton onClick={onOtherButtonClick} />
        </Styled.SelectButtonDiv>
      }

      { error ?
        <Styled.ErrorDiv>{error}</Styled.ErrorDiv>
      : ''
      }

      

    </Styled.MainDiv>
  )
}

export default CategorySelect;
