import React, { useState } from "react";
import Modal from "../../Modal";
import * as Styled from "../styled";
import SecondaryButton from "../../SecondaryButton";
import { fetchData } from "../../../Utilities/handleRequest";

const ForgotModal = ({ show, errorMessage, setErrorMessage, hide, successMessage, setSuccessMessage, setModalState }) => {
  const [email, setEmail] = useState('');
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const handleSendLink = async () =>{
    let res = await fetchData('post','admin-auth/forgot-password', {
      email : email?.trim()
    })
    
    if(res?.data || res.message === 'Token sent to email'){
      setSuccessMessage(true);
    } else {
      setErrorMessage(res?.error?.data?.message || 'invalid email');
    }
  }

  const checkError = () => {
    if(errorMessage || email?.trim()?.length === 0){
      setErrorMessage("invalid email");
      return true;
    }
  }

  return (
    <Styled.MainDiv>
      <Modal show={show} width="500px" hide={hide} hideFooter={true} backdropColor={'none'} closeButton={false} backdropClick={false}>
        <Styled.ModalContent>
          <Styled.HeadingDiv>
            <img
              src="/bathx_logo.svg"
              height={"40px"}
              width={"240px"}
              alt="bathX"
            />
            {successMessage ? (
              <>
                <img
                  src="/Home/success_icon.svg"
                  alt="<>"
                  style={{marginTop : '30px'}}
                />
                <p style={{margin: '28px 0px' }}>Reset Password Link Sent!</p>
              </>
            ) : (
              <p>Forgot Password</p>
            )}
          </Styled.HeadingDiv>
          {successMessage ? (
            <Styled.DetailsDiv>
              <p style={{margin: '0px', textAlign: 'center'}}>Reset password link has been sent to your registered email address.</p>
            </Styled.DetailsDiv>
          ) : (
          <Styled.DetailsDiv>
            <Styled.InputDiv>
              <Styled.InputLabel>Email Address</Styled.InputLabel>
              <Styled.InputElement 
                value={email}
                type="text"
                placeholder="Enter email address" 
                error={errorMessage} 
                onChange={(e) => {
                  setEmail(e.target.value);
                  if(!e.target.value?.match(emailRegex))
                    setErrorMessage("invalid email")
                  else
                    setErrorMessage(null)
                }}
              />
              {errorMessage && (
                <Styled.ErrorDiv>{errorMessage}</Styled.ErrorDiv>
              )}
            </Styled.InputDiv>
            <SecondaryButton
              title={"Reset Password"}
              style={{
                backgroundColor: "#118D8B",
                borderRadius: "22px",
                width: "100%",
                textAlign: "center",
                color: "#FFF",
                fontFamily: "Nunito, sans-serif",
                fontSize: "20px",
                fontWeight: 700,
                border: "none",
                marginTop: "10px",
              }}
              onClick={()=> !checkError() && handleSendLink()}
            />
          </Styled.DetailsDiv>
          )}
          <Styled.LinkDiv onClick={() => {
            setModalState("login")
            setErrorMessage(null);
            setSuccessMessage(false);
          }}>
            Back to <span style={{ color: "#118D8B" }}>Login</span>
          </Styled.LinkDiv>
        </Styled.ModalContent>
      </Modal>
    </Styled.MainDiv>
  );
};

export default ForgotModal;
