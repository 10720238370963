import styled from "styled-components";

export const UploadWrapDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const InputWrapLabel = styled.label`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  position: relative;
  border: 1px dashed #bfbfbf;
  border-radius: 8px;
  padding: 13px 20px;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  & > input {
    display: none;
  }
`;
export const UploadImgDiv = styled.div`
  width: 40px;
  height: 30px;
  background-color: #118D8B;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  & img {
    width: 16px;
    height: 15px;
  }
`;
export const TextWrapDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & div {
    text-align: left;
  }
`;
export const Text1Div = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #118D8B;
  margin-bottom: 5px;
`;
export const Text2Div = styled.div`
  font-size: 12px;
  margin-bottom: 5px;
`;
export const Text3Div = styled.div`
  font-size: 12px;
  color: #808080;
`;
export const SelectedItemsDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
`;
export const SelectedDiv = styled.div`
  width: 90px;
  margin: 6px;
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ClosePreviewButton = styled.button`
  height: 20px;
  width: 20px;
  background: none;
  border: none;
  margin: 0px;
  padding: 0px;
  box-shadow: none;
  flex-shrink: 0;
  position: absolute;
  right: -10px;
  top: -10px;
  & > img {
    height: 20px;
    width: 20px;
  }
`;
export const PreviewButton = styled.button`
  width: 90px;
  height: 90px;
  border-radius: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
export const ErrorDiv = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #DB1616;
  text-align: right;
`;
export const ModalImg = styled.img`
  max-height: 70vh;
  max-width: 70vw;
`;
