import React, { useState } from "react";
import Modal from "../../Modal";
import PrimaryButton from "../../PrimaryButton";
import { fetchData } from "../../../Utilities/handleRequest";
import { userPermissions } from "../../../StaticData/databases";
import * as Styled from "../styled";

const LoginModal = ({ show, errorMessage, setErrorMessage, hide, setModalState }) => {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  // const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  const passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const handleLogin = async () => {
    let res = await fetchData('post', 'admin-auth/login', {
      email : email?.trim(),
      password : password?.trim()
    })

    if (res?.data) {
      const user = res?.data?.user;
      localStorage.setItem('jwtToken', res?.data?.token);
      localStorage.setItem('user', JSON.stringify(user || {}));
      if (user.role && userPermissions[user.role] && userPermissions[user.role][0]) {
        window.location.href = `/${userPermissions[user.role][0]}`;
      } else {
        window.location.href = '/no-permission';
      }
    } else {
      setErrorMessage(res.error?.data?.message || 'invalid email or password');
    }
  }

  const checkError = () => {
    if (errorMessage || email?.trim()?.length === 0 || password?.trim()?.length === 0) {
      setErrorMessage("invalid email or password");
      return true;
    }
    if(!password?.match(passwordRegex)){
      setErrorMessage('password must have minimum 8 characters, 1 number and 1 special character or symbol');
      return true;
    }

    if(!email?.match(emailRegex)){
      setErrorMessage('invalid email');
      return true;
    }
  }

  const onFormSubmit = (e) => {
    e.preventDefault();
    !checkError() && handleLogin()
  }

  return (
    <Styled.MainDiv>
      <Modal show={show} width="500px" hide={hide} hideFooter={true} backdropColor={'none'} closeButton={false} backdropClick={false}>
        <Styled.ModalContent>
          <Styled.HeadingDiv>
            <img
              src="/bathx_logo.svg"
              height={"40px"}
              width={"240px"}
              alt="bathX"
            />
            <p>Login Now</p>
          </Styled.HeadingDiv>

          <Styled.LoginForm onSubmit={(e) => onFormSubmit(e)}>
            <Styled.InputDiv>
              <Styled.InputLabel>Email Address</Styled.InputLabel>
              <Styled.InputElement
                value={email}
                type="text"
                placeholder="Enter email address"
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (!e.target.value?.match(emailRegex)){}
                    // setErrorMessage("invalid email or password")
                  else
                    setErrorMessage(null)
                }}
                error={errorMessage}
              />
            </Styled.InputDiv>
            <Styled.InputDiv>
              <Styled.InputLabel>Password</Styled.InputLabel>
              <Styled.InputElement
                type="password"
                value={password}
                placeholder="Enter password"
                error={errorMessage}
                onChange={(e) => {
                  setPassword(e.target.value);
                  if (!e.target.value?.match(passwordRegex)){}
                    // setErrorMessage("invalid email or password")
                  else
                    setErrorMessage(null)
                }}
              />
              {errorMessage && (
                <Styled.ErrorDiv>{errorMessage}</Styled.ErrorDiv>
              )}
            </Styled.InputDiv>
            <PrimaryButton
              title={"Login"}
              style={{
                width: "100%",
                fontSize: "20px",
                fontWeight: 700,
                marginTop: "20px",
                justifyContent: "center"
              }}
              type="submit"
            />
          </Styled.LoginForm>
          <Styled.LinkDiv onClick={() => {
            setModalState("forgot")
            setErrorMessage(null);
          }}>
            Forgot Password
          </Styled.LinkDiv>
        </Styled.ModalContent>
      </Modal>
    </Styled.MainDiv>
  );
};

export default LoginModal;
