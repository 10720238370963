import * as Styled from "./styled";

const ChecklistModal = ({
  show,
  hide=()=>{},
  heading='',
  children,
  onSave=()=>{},
  disabled=false,
}) => {

  return (
    <Styled.MainDiv
      style={{ display: show ? 'flex' : 'none' }}
    >
      <Styled.ModalDiv id="modal_div">
        <Styled.ModalHeadingWrapDiv>
          <Styled.ModalHeadingDiv>{heading}</Styled.ModalHeadingDiv>
          <Styled.CloseModalButton onClick={hide} />
        </Styled.ModalHeadingWrapDiv>

        <Styled.ModalContentDiv>
          {children}
        </Styled.ModalContentDiv>

        <Styled.ModalFooterDiv>
          <Styled.SaveButton onClick={onSave} style={{ backgroundColor: disabled ? '#bfbfbf' : '#118D8B' }} disabled={disabled}>Save</Styled.SaveButton>
        </Styled.ModalFooterDiv>
        
      </Styled.ModalDiv>
    </Styled.MainDiv>
  )
}

export default ChecklistModal;
