import styled from "styled-components";

export const MainDiv = styled.div`
  height: 100vh;
  width: 100vw;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  position: fixed;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ backdropColor }) => backdropColor || 'rgba(0, 0, 0, 0.7)'};
`;
export const ModalDiv = styled.div`
  max-height: 90vh;
  background-color: #ffffff;
  border: 1px solid #ADB8AE;
  border-radius: 10px;
  position: relative;
`;
export const ModalHeadingDiv = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: #118D8B;
  display: flex;
  justify-content: space-between;
  padding: 18px 28px;
  border-bottom: 1px solid #bfbfbf;
`;
export const CloseModalButton = styled.button`
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0px;
  background: none;
  &:before {
    content: '';
    height: 2px;
    width: 20px;
    position: absolute;
    transform: rotate(45deg);
    background-color: #000000;
    border-radius: 2px;
  }
  &:after {
    content: '';
    height: 2px;
    width: 20px;
    position: absolute;
    transform: rotate(-45deg);
    background-color: #000000;
    border-radius: 2px;
  }
`;
export const OuterCloseModalButton = styled.button`
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  top: -36px;
  padding: 0px;
  background: none;
  &:before {
    content: '';
    height: 2px;
    width: 20px;
    position: absolute;
    transform: rotate(45deg);
    background-color: #ffffff;
    border-radius: 2px;
  }
  &:after {
    content: '';
    height: 2px;
    width: 20px;
    position: absolute;
    transform: rotate(-45deg);
    background-color: #ffffff;
    border-radius: 2px;
  }
`;
export const ModalContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 28px;
  max-height: 70vh;
  overflow: auto;
`;
export const ModalFooterDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 28px;
`;