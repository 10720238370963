import * as Styled from "./styled";

const ToolTip = ({ children, tooltip='', wrap=false }) => {

  return (
    <Styled.WrapDiv>
      <Styled.ToolTipBaseDiv>
        <Styled.ToolTipDiv>
          <div>
            <p style={wrap ? {whiteSpace: 'wrap'} : {}}>{tooltip}</p>
          </div>
        </Styled.ToolTipDiv>
      </Styled.ToolTipBaseDiv>

      {children}
    </Styled.WrapDiv>
  )
}

export default ToolTip;
