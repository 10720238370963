import { useEffect, useState } from "react";
import Loader from "../../Components/Loader";
import { fetchClientData } from "../../Utilities/clientRequest";
import { withRouter } from "../../Utilities/withRouter";
import * as Styled from "./styled";

const Payment = ({ params }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [paymentResult, setPaymentResult] = useState('');

  useEffect(() => {
    checkUser();
  }, [params]);

  const checkUser = async () => {
    setLoading(true);
    let res = await fetchClientData(params.token, 'get', 'client-auth/me');
    if (res?.data) {
      initiatePayment(res.data);
    } else if (res?.error === 'unauthorize') {
      setLoading(false);
      setMessage('Invalid user!');
    } else {
      setLoading(false);
      setMessage('Something went wrong!');
    }
  }

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const initiatePayment = async (data) => {
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
    setLoading(false);
    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    try {
      const options = {
        key: "rzp_test_u8MCqcDM73Ga63", // Enter the Key ID generated from the Dashboard
        amount: "50000", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "BathX", //your business name
        description: "Test Transaction",
        image: "https://example.com/your_logo",
        order_id: params.order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: async function (response) {
          if (response.razorpay_payment_id && response.razorpay_order_id && response.razorpay_signature) {
            const data = {
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature
            };
  
            await fetchClientData(params.token, 'post', 'payments/payment-callback', data);
            setPaymentResult('successful');
          } else {
            setPaymentResult('fail');
          }
        },
        // callback_url: "https://eneqd3r9zrjok.x.pipedream.net/",
        prefill: { //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
          name: data.name, //your customer's name
          email: data.email,
          contact: data.phone_number //Provide the customer's phone number for better conversion rates 
        },
        notes: {
          address: "Razorpay Corporate Office"
        },
        theme: {
          color: "#3399cc"
        },
        modal: {
          ondismiss: async function() {
            const data = {
              status: 'not_attempted',
              project_id: params.project_id,
              razorpay_order_id: params.order_id,
            };
  
            await fetchClientData(params.token, 'post', 'payments/payment-callback', data);
            setPaymentResult('fail');
          }
        }
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch {
      console.log('payment failed');
      setPaymentResult('fail');
    }
  }

  return (
    <Styled.MainWrapDiv>
      <Loader loading={loading} />

      {paymentResult === 'successful' ?
        <Styled.ResultWrapDiv>
          <img src="/payment-success-icon.svg" />
          <Styled.HeadingDiv style={{ color: '#118D8B' }}>Payment Successful!</Styled.HeadingDiv>
          <Styled.SubHeadingDiv>Your transaction was successfully completed!</Styled.SubHeadingDiv>
          <Styled.DescriptionDiv>You will be redirected to the BOQ screen to continue with your project.</Styled.DescriptionDiv>
        </Styled.ResultWrapDiv>
        : ''
      }

      {paymentResult === 'fail' ?
        <Styled.ResultWrapDiv>
          <img src="/payment-fail-icon.svg" />
          <Styled.HeadingDiv style={{ color: '#DB1616' }}>Payment Failed!</Styled.HeadingDiv>
          <Styled.SubHeadingDiv>Your transaction has failed!</Styled.SubHeadingDiv>
          <Styled.DescriptionDiv>You will be redirected to the project page where you can attempt the transaction again.</Styled.DescriptionDiv>
        </Styled.ResultWrapDiv>
        : ''
      }

      { message ?
        <Styled.MessageDiv>{message}</Styled.MessageDiv>
      : ''
      }
      
    </Styled.MainWrapDiv>
  )
}

export default withRouter(Payment);
