import * as Styled from "./styled";

const ProgressBar = ({ layout='vertical', progress=0, doneText='Completed', style={} }) => {
  return (
    <Styled.MainWrapDiv style={style} layout={layout}>
      <Styled.ProgressDiv>
        <div style={{ width: `${progress}%` }} /> 
      </Styled.ProgressDiv>

      <Styled.ProgressInfoDiv>{`${progress}% ${doneText}`}</Styled.ProgressInfoDiv>
    </Styled.MainWrapDiv>
  )
}

export default ProgressBar;
