import styled from "styled-components";
import { Link } from "react-router-dom";

export const MainDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 28px;
`;
export const LeftDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
export const RightDiv = styled.div`
  margin-left: 18px;
`;
export const HeadingDiv = styled.div`
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 8px;
`;
export const BackToDiv = styled(Link)`
  text-decoration: none;
  color: #919191;
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-bottom: 4px;
  & img {
    height: 8px;
    width: 4px;
    margin-right: 5px;
  }
`;
