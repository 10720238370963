import styled from "styled-components";

export const MainDiv = styled.div`
  height: 100vh;
  width: 100vw;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  position: fixed;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ backdropColor }) => backdropColor || 'rgba(0, 0, 0, 0.7)'};
`;
export const ModalDiv = styled.div`
  height: 732px;
  min-height: 735px;
  max-height: 90vh;
  width: 516px;
  background-color: #ffffff;
  border: 1px solid #BFBFBF;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
`;
export const ModalHeadingWrapDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 18px 16px 18px 28px;
  border-bottom: 1px solid #bfbfbf;
  flex-shrink: 0;
`;
export const ModalHeadingDiv = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: #118D8B;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const CloseModalButton = styled.button`
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0px;
  background: none;
  &:before {
    content: '';
    height: 2px;
    width: 20px;
    position: absolute;
    transform: rotate(45deg);
    background-color: #000000;
    border-radius: 2px;
  }
  &:after {
    content: '';
    height: 2px;
    width: 20px;
    position: absolute;
    transform: rotate(-45deg);
    background-color: #000000;
    border-radius: 2px;
  }
`;
export const ModalContentDiv = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px 28px 28px 28px;
  overflow: auto;
  flex-grow: 1;
  box-sizing: border-box;
`;
export const RowDiv = styled.div`
  font-size: 16px;
  display: flex;
  justify-content: space-between;
`;
export const ColumnDiv = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
`;
export const InputLabelDiv = styled.div`
  margin: 16px 0px 8px 0px;
  font-size: 16px;
  &:after {
    content: '*';
    font-size: 16px;
    color: #DB1616;
  }
`;
export const TextDiv = styled.div`
  margin: 0px 0px 12px 0px;
  font-size: 16px;
`;
export const ModalFooterDiv = styled.div`
  height: 92px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  align-self: flex-end;
  position: relative;
  display: flex;
  flex-shrink: 0;
`;
export const PreviousButton = styled.button`
  height: 42px;
  width: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #000000;
  background-color: #ffffff;
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  margin-right: 20px;
`;
export const NextButton = styled.button`
  height: 42px;
  width: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: #118D8B;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
`;
