import React from 'react';

export const Star = ({ filled }) => {
  return (
    <span style={{ cursor: 'default' }}>
      {filled ? <img src={'./star-active.svg'} / > :  <img src={'./star-inactive.svg'} / >}
    </span>
  )
};

const StarRating = ({ totalStars = 5, rating }) => {
    return (
      <div>
        {[...Array(totalStars)].map((_, index) => (
          <Star
            key={index}
            filled={index < rating}
          />
        ))}

      </div>
    );
  };


export default StarRating