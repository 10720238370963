import styled from "styled-components";

export const InputWrapDiv = styled.div`
  display: flex;
  flex-direction: column;
  & input {
    width: 100%;
    box-sizing: border-box;
  }
`;
export const InputDiv = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #bfbfbf;
  & div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 16px;
    border-right: ${({ atLast }) => atLast ? 'none' : '1px solid #000000'};
    border-left: ${({ atLast }) => atLast ? '1px solid #000000' : 'none'};
    height: 80%;
  }
  & input {
    border: none; 
    &:focus {
      outline: none;
    }
  }
  &:focus-within {
    border: 1px solid #118D8B !important;
    outline-color: #118D8B;
    outline-style: solid;
    outline-width: 1.5px;
  }
`;
export const ErrorDiv = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #DB1616;
  text-align: right;
`;