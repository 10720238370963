import styled from "styled-components";

export const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 28px 28px 14px 28px;
  box-sizing: border-box;
`;
export const RowDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
`;
export const InputWrapDiv = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: ${({ iserror }) => iserror ? '0px' : '20px'};
`;
export const AddButtonsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #bfbfbf;
  padding: 28px;
  & button {
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 0px 28px;
    font-size: 16px;
    font-weight: 600;
  }
  & > button {
    color: #ffffff;
    background-color: #118D8B;
    border: 1px solid #118D8B;
    padding: 0px 20px 0px 40px;
    position: relative;
    &:before {
      content: '';
      height: 3px;
      width: 16px;
      position: absolute;
      left: 11px;
      background-color: #ffffff;
    }
    &:after {
      content: '';
      height: 16px;
      width: 3px;
      position: absolute;
      left: 17px;
      background-color: #ffffff;
    }
  }
  & > div {
    display: flex;
    align-items: center;
    & > button:first-child {
      color: #000000;
      border: 1px solid #000000;
      margin-right: 20px;
      background-color: #ffffff;
    }
    & > button:last-child {
      color: #ffffff;
      background-color: #118D8B;
      border: 1px solid #118D8B;
      padding: 0px 48px;
    }
  }
  
`;
export const AddEditTitleDiv = styled.div`
  width: 48%;
  font-size: 16px;
  margin-bottom: 10px;
  &::after {
    content: '*';
    color: #DB1616;
    display: ${({ notEmpty }) => notEmpty ? 'inline' : 'none'};
  }
`;
export const RemoveRoleDiv = styled.div`
  margin-left: 20px;
`;
export const RemoveRoleButton = styled.button`
  height: 44px;
  width: 44px;
  flex-shrink: 0;
  background-color: ${({ mode }) => mode === 'add' ? '#118d8b' : '#ffffff'};
  border: 1px solid #bfbfbf;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;