import PrimaryButton from "../PrimaryButton";
import SecondaryButton from "../SecondaryButton";
import * as Styled from "./styled";

const Modal = ({
  show,
  hide=()=>{},
  heading,
  width,
  okText='',
  onOk=()=>{},
  cancelText,
  hideFooter,
  backdropColor,
  backdropClick,
  closeButton=true,
  outerCloseButton=false,
  children,
  contentStyle={},
  modalStyle={}
}) => {

  const onBackgroundClick = (e) => {
    if (e.target.id !== 'bg_div') return;
    hide();
  }

  return (
    <Styled.MainDiv
      id="bg_div"
      style={{ display: show ? 'flex' : 'none' }}
      backdropColor={backdropColor}
      onClick={(e) => backdropClick && onBackgroundClick(e)}
    >
      <Styled.ModalDiv id="modal_div" style={{ ...modalStyle, width: width ? width : 'auto' }}>
        { heading ?
          <Styled.ModalHeadingDiv>
            {heading}
            {closeButton && <Styled.CloseModalButton onClick={hide} />}
          </Styled.ModalHeadingDiv>
        :
          <>
          {
            closeButton 
          ? 
            <Styled.CloseModalButton onClick={hide} style={{ position: 'absolute', top: '18px', right: '25px' }} />
          :
            ''
          }
          </>
        }

        { outerCloseButton ?
          <Styled.OuterCloseModalButton onClick={hide} />
        : ''
        }

        <Styled.ModalContentDiv style={contentStyle}>
          {children}
        </Styled.ModalContentDiv>

        { !hideFooter ?
          <Styled.ModalFooterDiv>
            <SecondaryButton title={cancelText || "Cancel"} onClick={hide} />
            { okText ?
              <PrimaryButton title={okText} style={{ marginLeft: '20px', borderRadius: '8px' }} onClick={onOk} />
            : ''
            }
          </Styled.ModalFooterDiv>
        : ''
        }
        
      </Styled.ModalDiv>
    </Styled.MainDiv>
  )
}

export default Modal;
