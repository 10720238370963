import { userPermissions } from "../StaticData/databases";

const getFromLocalStorage = (key) => {
  try {
    let value = localStorage.getItem(key);
    if (!value) return;
    return value;
  } catch (err) {
    clearLocalStorage();
    alert("Error Reading Storage. Please try again!!");
  }
}

export const isUserLoggedIn = () => {
  const token = getFromLocalStorage('jwtToken');
  if (!token) return false;
  return true;
}

export const clearLocalStorage = () => {
  if (!localStorage) return;
  localStorage.clear();
}

export const getUserPermissions = () => {
  let user = getFromLocalStorage('user');
  if (!user) return;

  user = JSON.parse(user);
  if (!user.role) return;

  const permissions = userPermissions[user.role] || [];

  if (permissions && permissions.length) {
    return permissions;
  }
  return;
}

export const getUserRole = () => {
  let user = getFromLocalStorage('user');
  if (!user) return;

  user = JSON.parse(user);
  if (!user.role) {
    return;
  }

  return user.role;
}