import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Button = styled.button`
  height: 44px;
  padding: 0px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #19221A;
  background-color: #ffffff;
  color: #19221A;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
`;
const LinkButton = styled(Link)`
  height: 44px;
  padding: 0px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #19221A;
  background-color: #ffffff;
  color: #19221A;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  white-space: nowrap;
`;

const SecondaryButton = ({ title, style, onClick=()=>{}, to }) => {
  return (
    to ?
    <LinkButton style={style} to={to}>{title}</LinkButton>
    :
    <Button style={style} onClick={onClick}>{title}</Button>
  )
}

export default SecondaryButton;
