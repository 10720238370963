import React, { useEffect, useState } from 'react';
import * as Styled from "./styled";

const Pagination = ({ activePage, totalPages, onPageChange }) => {
  const [pageButtons, setPageButtons] = useState([]);

  useEffect(() => {
    if (activePage && totalPages) {
      const pages = [];
      let pageShow = totalPages - 2 > 3 ? 3 : totalPages - 2;
      const getPage = (a, t, x) => {
        let p = 0;
        if (t > 2 && t < 6) { p = x + 2 }
        else if (t > 5) {
          if (a > 2 && a < t - 1) {
            p = a + (x - 1)
          } else if (a < 3) {
            p = x + 2
          } else if (a > t - 2) {
            p = t - (3 - x)
          }
        }
        return p;
      }

      for (let x = 0; x < pageShow; x += 1) {
        const obj = { page: 0, show: true };
        obj.page = getPage(activePage, totalPages, x);
        if (obj.page > 0) pages.push(obj);
      }

      setPageButtons(pages);
    }
  }, [activePage, totalPages]);


  return (
    <Styled.PaginationDiv>
      { totalPages > 1 ?
        <>
          { activePage > 1 ?
            <Styled.NextPrevButton onClick={() => {window.scrollTo(0, 0); onPageChange(activePage - 1)}}><div>Previous</div></Styled.NextPrevButton>
          : ''
          }

          <button
            style={ activePage === 1 ? { color: '#118D8B', backgroundColor: '#EDEDED' } : {} }
            onClick={() => {window.scrollTo(0, 0);onPageChange(1)}}
          >1</button>

          { totalPages > 5 && activePage > 3 ? <div>...</div> : '' }

          { pageButtons && pageButtons.length > 0 ?
            pageButtons.map((p) => (
              p.show ?
                <button
                  key={`${p.page}_pagination`}
                  style={ activePage === p.page ? { color: '#118D8B', backgroundColor: '#EDEDED' } : {} }
                  onClick={() => {onPageChange(p.page); window.scrollTo(0, 0); }}
                >{p.page}</button>
              : ''
            ))
          : ''
          }

          { totalPages > 5 && activePage < totalPages - 2 ? <div>...</div> : '' }

          <button
            style={ activePage === totalPages ? { color: '#118D8B', backgroundColor: '#EDEDED' } : {} }
            onClick={() => {window.scrollTo(0, 0);onPageChange(totalPages)}}
          >{totalPages}</button>

          { activePage < totalPages ?
            <Styled.NextPrevButton val="next" onClick={() => {window.scrollTo(0, 0);onPageChange(activePage + 1)}}><div>Next</div></Styled.NextPrevButton>
          : ''
          }
        </>
      : ''
      }
    </Styled.PaginationDiv>
  );
}

export default Pagination;
