import styled from "styled-components";

export const ToolTipBaseDiv = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const ToolTipDiv = styled.div`
  position: absolute;
  bottom: 0px;
  max-height: 0px;
  overflow: hidden;
  transition: all 0.3s;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  & > div {
    padding: 12px;
    border-radius: 6px;
    background-color: #000000;
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    & > p {
      color: #ffffff;
      font-size: 12px;
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0px;
      padding: 0px;
    }
    &:after {
      content: '';
      height: 12px;
      width: 12px;
      background-color: #000000;
      position: absolute;
      bottom: -4px;
      transform: rotate(45deg);
    }
  }
`;
export const WrapDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  &:hover ${ToolTipDiv} {
    max-height: 100px;
  }
`;