import styled from "styled-components";

export const MainWrapDiv = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const MessageDiv = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 12px;
`;
export const ResultWrapDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 36px;
  & img {
    height: 40px;
    width: 40px;
    margin-bottom: 12px;
  }
`;
export const HeadingDiv = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  margin-bottom: 12px;
`;
export const SubHeadingDiv = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: #000000;
  text-align: center;
  margin-bottom: 12px;
`;
export const DescriptionDiv = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #808080;
  text-align: center;
`;
