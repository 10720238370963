import styled from "styled-components";

export const ProjectTableWrapDiv = styled.div`
  min-height: 500px;
  display: flex;
  flex-direction: column;
`;
export const FilterDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 28px;
`;
export const CardFooterDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #bfbfbf;
  padding: 32px;
`;
