import styled from "styled-components";

export const SelectWrapDiv = styled.div`
  width: 100%;
  display: flex;
  background-color: #ffffff;
  position: relative;
`;
export const SelectButton = styled.button`
  height: 44px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border: ${({ isopen }) => isopen ? '2px solid #118D8B' : '1px solid #bfbfbf'};
  border-radius: 8px;
  padding: 0px 36px 0px 15px;
  font-size: 14px;
  position: relative;
  & p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:after {
    content: '';
    height: 9px;
    width: 9px;
    position: absolute;
    right: 12px;
    top: ${({ isopen }) => isopen ? '16px' : '12px'};
    border-bottom: 2px solid #bfbfbf;
    border-right: 2px solid #bfbfbf;
    transform: ${({ isopen }) => isopen ? 'rotate(-135deg)' : 'rotate(45deg)'};
    transition: all 0.3s;
  }
`;
export const DropdownDiv = styled.div`
  width: 100%;
  overflow: hidden;
  transition: all 0.3s;
  position: absolute;
  top: 44px;
  z-index: 2;
`;
export const ListDiv = styled.div`
  max-height: 240px;
  display: flex;
  flex-direction: column;
  border: 1px solid #bfbfbf;
  border-radius: 8px;
  overflow: auto;
  transition: all 0.3s;
`;
export const ListButton = styled.button`
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  color: #000000;
  padding: 0px 15px;
  z-index: 2;
  flex-shrink: 0;
  &:hover {
    background-color: #F1F1F1;
  }
  & p {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const MultiselectDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;
export const SelectedItemsDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 7px;
`;
export const SelectedDiv = styled.div`
  padding: 7px 10px;
  margin: 6px;
  background-color: #C4DEDE;
  display: flex;
  align-items: center;
  font-size: 14px;
  border-radius: 40px;
  & > button {
    height: 14px;
    width: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: none;
    border: none;
    margin: 0px 0px 0px 8px;
    padding: 0px;
    box-shadow: none;
    flex-shrink: 0;
    &:before {
      content: '';
      height: 2px;
      width: 14px;
      position: absolute;
      transform: rotate(45deg);
      background-color: #000000;
    }
    &:after {
      content: '';
      height: 2px;
      width: 14px;
      position: absolute;
      transform: rotate(-45deg);
      background-color: #000000;
    }
  }
`;
export const OtherButton = styled.button`
  height: 44px;
  width: 44px;
  background-color: #ffffff;
  border: 1px solid #bfbfbf;
  border-radius: 8px;
  margin-left: 16px;
  flex-shrink: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &:before {
    content: '';
    width: 3px;
    height: 24px;
    background-color: #000000;
    border-radius: 3px;
    position: absolute;
  }
  &:after {
    content: '';
    height: 3px;
    width: 24px;
    background-color: #000000;
    border-radius: 3px;
    position: absolute;
  }
`;
export const OtherInputDiv = styled.div`
  display: flex;
  align-items: center;
  & button {
    height: 44px;
    width: 44px;
    border-radius: 8px;
    margin-left: 16px;
    flex-shrink: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
  }
`;
export const AddOtherButton = styled.button`
  background-color: #118D8B;
  &:before {
    content: '';
    width: 10px;
    height: 18px;
    border-bottom: 4px solid #ffffff;
    border-right: 4px solid #ffffff;
    transform: rotate(45deg);
    position: absolute;
    top: 8px;
  }
`;
export const CancelOtherButton = styled.button`
  background-color: red;
  &:before {
    content: '';
    width: 3.5px;
    height: 24px;
    background-color: #ffffff;
    position: absolute;
    transform: rotate(45deg);
  }
  &:after {
    content: '';
    width: 3.5px;
    height: 24px;
    background-color: #ffffff;
    position: absolute;
    transform: rotate(-45deg);
  }
`;
export const ErrorDiv = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #DB1616;
  text-align: right;
`;
export const DropdownWrapDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;