import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import Heading from "../../Components/Heading";
import PrimaryButton from "../../Components/PrimaryButton";
import SearchInput from "../../Components/SearchInput";
import SingleSelect from "../../Components/SingleSelect";
import Table from "../../Components/ThemeTable";
import Modal from "../../Components/Modal";
import { fetchData } from "../../Utilities/handleRequest";
import { withRouter } from "../../Utilities/withRouter";
import { toastError } from "../../Utilities/toast";
import * as CommonStyled from "../../Common/commonStyled";

const ElementsBundles = ({ navigate }) => {
  const [selectedBrand, setSelectedBrand] = useState('All');
  const [searchText, setSearchText] = useState('');

  const [allBrands, setAllBrands] = useState([]);
  const [allBundles, setAllBundles] = useState([]);

  const [loading, setLoading] = useState(false);
  const [actionBundle, setActionBundle] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  useEffect(() => {
    getBrands();
  }, []);

  const getBrands = async () => {
    let res = await fetchData('get', 'element/get-elements-utils');
    if (res?.data?.brands) {
      const brandArr = res?.data?.brands.map(b => ({ id: b.name, name: b.name }));
      setAllBrands([{id: 'All', name: 'Select brand'}, ...brandArr]);
    }
  }

  useEffect(() => {
    setPage(1);
    getElementsBundle(1);
  }, [searchText, limit, selectedBrand]);

  const getElementsBundle = async (pageNo) => {
    setLoading(true);
    let url = `elements-bundle/get-element-bundles?pageNo=${pageNo || page}&limit=${limit}`;
    if (selectedBrand && selectedBrand !== 'All') {
      url += `&brands=${selectedBrand}`;
    }
    if (searchText) {
      url += `&title=${searchText}`;
    }

    let res = await fetchData('get', url);
    setAllBundles(res?.data?.result || []);
    setCount(res?.data?.count || 0);
    setLoading(false);
  }

  const onDelete = (row) => {
    setActionBundle(row);
    setShowDeleteModal(true);
  }

  const onConfirmDelete = async () => {
    setLoading(true);
    let res = await fetchData('post', `elements-bundle/delete-element-bundle/${actionBundle.id}`);
    if (res?.message === 'Successfully Deleted') {
      hideModal();
      toastError('The bundle has been deleted successfully!');
      getElementsBundle();
    } else {
      toastError('Something went wrong');
      setLoading(false);
    }
  }

  const onEdit = (row) => {
    navigate(`/edit-elements-bundle/${row.id}`);
  }

  const onPageChange = (value) => {
    getElementsBundle(value);
    setPage(value);
  }

  const hideModal = () => {
    setActionBundle(null);
    setShowDeleteModal(false);
  }

  const elementsDropDown = [
    { name: 'Elements', id: 'elements' },
    { name: 'Element Bundles', id: 'elements-bundles' }
  ];

  const headings = [
    { name: 'Title', key: 'title', type: 'text', sortBy: true, style: { color: '#118D8B' } },
    { name: 'Brand(s)', key: 'brands', type: 'array', sortBy: true },
    { name: 'No. of Elements', key: 'total_elements', type: 'text', sortBy: true },
    { name: 'MRP', key: 'mrp', type: 'rupees', sortBy: true },
    { name: 'BX Price', key: 'bx_price', type: 'rupees', sortBy: true },
    { name: 'Procurement Cost', key: 'procurement_cost', type: 'rupees', sortBy: true },
    { name: 'Action', type: 'action', actions: { edit: {onClick: onEdit, toolText: 'Edit bundle'}, delete: {onClick: onDelete, toolText: 'Delete bundle'} } },
  ];

  return (
    <Layout activePage="Elements" style={{ display: 'flex', flexDirection: 'column' }}>

      <Modal
        show={showDeleteModal}
        hide={hideModal}
        okText="Delete"
        hideFooter={true}
        width="446px"
      >
        <CommonStyled.VerificationWrapDiv>
          <img src="/delete-modal-icon-green.svg" />
          <CommonStyled.VerifyModalHeading style={{ color: '#118D8B' }}>Delete Bundle</CommonStyled.VerifyModalHeading>
          <CommonStyled.VerifyModalSubheading>Are you sure you want to delete this bundle?</CommonStyled.VerifyModalSubheading>
          <div>
            <button onClick={hideModal}>Cancel</button>
            <button onClick={onConfirmDelete}>Delete</button>
          </div>
        </CommonStyled.VerificationWrapDiv>
      </Modal>

      <Heading
        heading="Element Bundles"
        subHeading="This is where you can view the listing of all the elements and can add new theme."
      >
        <PrimaryButton title="Add New Bundle" style={{ marginLeft: '20px' }} to={'/add-elements-bundle'} />
      </Heading>

      <CommonStyled.FilterDiv>
        <div style={{ display: 'flex' }}>
          <SingleSelect
            data={elementsDropDown}
            selectedId="elements-bundles"
            onSelect={(id) => navigate(`/${id}`)}
            style={{ marginRight: '20px' }}
          />
          <SingleSelect
            data={allBrands}
            selectedId={selectedBrand}
            onSelect={(id) => setSelectedBrand(id)}
          />
        </div>

        <SearchInput
          onInputChange={(e) => setSearchText(e.target.value)}
          placeholder="Search with bundle name"
        />
      </CommonStyled.FilterDiv>

      { !allBundles.length && selectedBrand === 'All' && !searchText ?
        <>
          { !loading ?
            <CommonStyled.NoDataFoundDiv>
              <img src="/no-elements-bundle-added-icon.svg" />
              <div>No element bundle added so far</div>
              <PrimaryButton title="Add New Bundle" to={'/add-elements-bundle'} />
            </CommonStyled.NoDataFoundDiv>
          : ''
          }
        </>
      :
        <Table
          rows={allBundles}
          headings={headings}
          page={page}
          limit={limit}
          count={count}
          onPageChange={onPageChange}
          onLimitChange={(value) => setLimit(value)}
          noDataImage="/no-element-bundle-found-icon.svg"
          noDataText="No bundle found"
          loading={loading}
          hideColumnToggle={true}
        />
      }

      

    </Layout>
  )
}

export default withRouter(ElementsBundles);
