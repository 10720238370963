import { useEffect, useState } from "react";
import moment from "moment";
import ProgressBar from "../../../Components/ProgressBar";
import Collapse from "../ProjectComponents/Collapse";
import { fetchData } from "../../../Utilities/handleRequest";
import { toastError } from "../../../Utilities/toast";
import { MilestoneLayout, TotalProgressFields, DesignFields, ExecuteFields, Notifications } from "../layout";
import * as Styled from "./styled";

const ProjectMilestones = ({
  params,
  milestoneMetadata,
  getProject,
  setLoading,
  projectName
}) => {
  const [milestoneData, setMilestoneData] = useState(milestoneMetadata);
  const [milestoneLayout, setMilestoneLayout] = useState(MilestoneLayout(milestoneMetadata));
  const [activeCollapse, setActiveCollapse] = useState('');

  useEffect(() => {
    setMilestoneLayout(MilestoneLayout(milestoneData));
  }, [milestoneData]);

  useEffect(() => {
    setMilestoneData({...milestoneMetadata});
  }, [milestoneMetadata]);

  const onCollapseChange = async (index) => {
    const data = { ...milestoneMetadata };
    if (activeCollapse === index) {
      setActiveCollapse(null);
    } else {
      setActiveCollapse(index);
      data.status = milestoneLayout[index].title;
    }
    setMilestoneData({ ...data });
  }

  const saveMilestone = async (dataMilestone) => {
    setLoading(true);
    let data = { ...dataMilestone };
    data = await setMilestoneProgress(data);

    setMilestoneData(data);

    const payload = { milestone_metadata: data };
    if (data.progress === 100) {
      payload.status = 'COMPLETED'
    }

    let res = await fetchData('post', `projects/update-project/${params.id}`, payload);

    if (res?.data) {
      toastError('Project updated successfully');
      await getProject();
      setLoading(false);
      return true;
    } else {
      toastError('Something went wrong!');
      setLoading(false);
      return false;
    }
  }

  const sendNotification = async (key) => {
    if (!key) return;

    await fetchData('post', `notification/send-notification/${params.id}`, {
      data: {
        "title": Notifications?.[key]?.title || '',
        "project_id": params.id,
        "project_name": projectName
      },
      title: Notifications?.[key]?.title || '',
      body: Notifications?.[key]?.body || ''
    });

    await fetchData('post', `projects/send-project-email/${params.id}`, {
      milestone: key
    });
  }

  const setMilestoneProgress = async (obj) => {
    const excludeFields = ['status', 'progress', 'completed', 'completed_date', 'design_progress', 'execute_progress', 'payment_requested'];
    const milestoneObj = { ...obj };
    let filledFields = 0;
    let lastProgress = '';

    const countProgress = async (obj) => {
      for (let key in obj) {
        if (obj[key] !== null && typeof obj[key] === 'object') {
          if (key === 'files') {
            if (obj?.[key]?.[0]) {
              filledFields += 1;
            }
          } else if (key.includes('measurement_checklist_')) {
            if (obj?.[key]?.progress === 100) {
              filledFields += 1;
            }
          } else {
            await countProgress(obj[key]);

            if (TotalProgressFields?.[key] && lastProgress !== key) {
              if (filledFields === TotalProgressFields?.[key]) {
                if (!milestoneObj?.[key]?.completed_date) {
                  if (key !== 'first_payment_request' && key !== 'second_payment_request' && key !== 'third_payment_request') {
                    await sendNotification(key);
                  }
                  milestoneObj[key].completed_date = moment().format('YYYY-MM-DD');
                }
              } else {
                milestoneObj[key].completed_date = null;
              }
              milestoneObj[key].progress = Math.round((filledFields / TotalProgressFields[key]) * 100);
              lastProgress = key;
              filledFields = 0;
            }
          }
        } else if (obj[key] && !excludeFields.includes(key)) {
          filledFields += 1;
        }
      }
    }

    await countProgress(obj);

    let design_progress = 0;
    let execute_progress = 0;

    for (let x = 0; x < DesignFields.length; x++) {
      design_progress += milestoneObj?.[DesignFields[x]]?.progress || 0;
    }

    for (let x = 0; x < ExecuteFields.length; x++) {
      execute_progress += milestoneObj?.[ExecuteFields[x]]?.progress || 0;
    }

    milestoneObj.progress = Math.round((design_progress + execute_progress) / 14)
    milestoneObj.design_progress = Math.round(design_progress / 5);
    milestoneObj.execute_progress = Math.round(execute_progress / 9);

    return milestoneObj;
  }

  const onPaymentRequest = (name) => {
    const data = { ...milestoneData };
    data[name] = { progress: 0, completed_date: null, payment_requested: true, payment_completed: false };
    const index = activeCollapse + 1;
    data.status = milestoneLayout[index]?.title;
    sendNotification(name);
    saveMilestone(data, name);
    setActiveCollapse(index);
  }

  const onSaveAndNext = (collapseName, collapseData) => {
    const data = { ...milestoneData, [collapseName]: collapseData };
    const index = activeCollapse + 1;
    data.status = milestoneLayout[index]?.title;
    saveMilestone(data);
    setActiveCollapse(index);
  }

  return (
    <div>
      {milestoneLayout && milestoneLayout?.map((collapse, collapseIndex) => (
        <div key={`milestone_collapse_${collapseIndex}`}>
          {collapseIndex === 0 ?
            <Styled.SectionHeadingDiv>
              <div>Design Timeline</div>
              <ProgressBar
                layout="vertical"
                progress={milestoneData?.design_progress || 0}
                style={{ width: '50%' }}
              />
            </Styled.SectionHeadingDiv>
            : ''
          }
          {collapseIndex === 5 ?
            <Styled.SectionHeadingDiv style={{ marginTop: '14px' }}>
              <div>Project Execution Timeline</div>
              <ProgressBar
                layout="vertical"
                progress={milestoneData?.execute_progress || 0}
                style={{ width: '50%' }}
              />
            </Styled.SectionHeadingDiv>
            : ''
          }
          <Collapse
            layout={collapse}
            collapseIndex={collapseIndex}
            milestoneCollapseData={milestoneData?.[collapse.name] || {}}
            active={activeCollapse === collapseIndex}
            setActive={() => onCollapseChange(collapseIndex)}
            saveCollapse={(name, data) => saveMilestone({ ...milestoneData, [name]: data })}
            setLoading={setLoading}
            setActiveCollapse={setActiveCollapse}
            onPaymentRequest={onPaymentRequest}
            onSaveAndNext={onSaveAndNext}
          />
        </div>
      ))
      }
    </div>
  )
}

export default ProjectMilestones;
