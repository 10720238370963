import styled from "styled-components";

export const UploadWrapDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const InputWrapLabel = styled.label`
  flex-grow: 1;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  position: relative;
  border: 1px solid #bfbfbf;
  border-radius: 8px;
  padding: 0px 20px;
  text-align: center;
  cursor: pointer;
  & > input {
    display: none;
  }
`;
export const UploadImgDiv = styled.div`
  width: 40px;
  height: 30px;
  background-color: #118D8B;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  & img {
    width: 16px;
    height: 15px;
  }
`;
export const Text1Div = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #118D8B;
`;
export const SelectedItemsDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 9px;
`;
export const SelectedDiv = styled.div`
  margin: 9px 0px;
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  & > div {
    font-weight: 500;
    font-size: 16px;
    color: #118D8B;
  }
  & > img {
    width: 90px;
    height: 90px;
    border-radius: 8px;
    overflow: hidden;
  }
  & > button {
    height: 16px;
    width: 16px;
    background: none;
    border: none;
    margin: 0px 0px 0px 12px;
    padding: 0px;
    box-shadow: none;
    flex-shrink: 0;
    & > img {
      height: 16px;
      width: 16px;
    }
  }
`;
export const ErrorDiv = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #DB1616;
  text-align: right;
`;
