import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import Heading from "../../Components/Heading";
import PrimaryButton from "../../Components/PrimaryButton";
import SecondaryButton from "../../Components/SecondaryButton";
import Multiselect from "../../Components/MultiSelect";
import UploadImage from "../../Components/UploadImage";
import Upload3DModal from "../../Components/Upload3DModal";
import Loader from "../../Components/Loader";
import Input from "../../Components/Input";
import Textarea from "../../Components/Textarea";
import CategorySelect from "../../Components/CategorySelect";
import { finishColors, elementUnits, miscellaneousProperties, serviceCostTypes } from "../../StaticData/databases";
import { fetchData, uploadMedia } from "../../Utilities/handleRequest";
import { withRouter } from "../../Utilities/withRouter";
import * as Styled from "./styled";
import { toastError } from "../../Utilities/toast";
import SingleSelect from "../../Components/SingleSelect";

const EditElements = ({ params, navigate, location }) => {
  const pathname = location.pathname.split('/')[1];

  const [elementData, setElementData] = useState({
    title: '',
    sku: '',
    description: '',
    category_id: '',
    category_name: '',
    subcategory_id: '',
    subcategory_name: '',
    execution_category: [],
    size: {
      length: '',
      width: '',
      height: '',
    },
    brands: [],
    misc: [],
    finishing: [],
    mrp: '',
    unit: [],
    procurement_cost: '',
    bx_price: '',
    service_cost_type: '',
    service_cost: '',
    element_image: [],
    model_url: '',
    ios_model_url: '',
    webgl_model_url: ''
  });

  const [loading, setLoading] = useState(false);
  const [elementError, setElementError] = useState({});
  const [allCategories, setAllCategories] = useState([]);
  const [allBrands, setAllBrands] = useState([]);
  const [allSubCategories, setAllSubCategories] = useState([]);
  const [allExecutionCategories, setAllExecutionCategories] = useState([]);

  const numberTypeId = ['mrp', 'procurement_cost', 'bx_price', 'service_cost', 'length', 'width', 'height'];

  useEffect(() => {
    if (params.id) {
      getElement(params.id);
    } else {
      getCategories();
      getBrands();
    }
  }, [params]);

  useEffect(() => {
    if (elementData?.category_id && allCategories) {
      const selectedCategory = allCategories.filter((cat) => cat.id === elementData.category_id)[0];
      if (selectedCategory?.id) {
        getSubCategories(selectedCategory.id);
      }
    }
  }, [elementData?.category_id]);

  const getBrands = async () => {
    let res = await fetchData('get', 'element/get-elements-utils');
    setAllExecutionCategories(res?.data?.executionCategory || []);

    const apiBrands = res?.data?.brands || [];
    const elementBrands = [];

    if (apiBrands && apiBrands.length) {
      for (let x = 0; x < apiBrands.length; x++) {
        elementBrands.push({ id: apiBrands[x].name, name: apiBrands[x].name });
      }
    }

    setAllBrands(elementBrands);
  }

  const getCategories = async () => {
    let res = await fetchData('get', 'element/get-all-category');
    setAllCategories(res?.data?.category || []);
  }

  const getSubCategories = async (categoryId) => {
    if (!categoryId) return;
    let res = await fetchData('get', `element/get-all-subcategory?category_id=${categoryId}`);
    if (res?.data?.subcategory && !res?.data?.subcategory?.length) {
      toastError('No subcategory found!');
    } else {
      setAllSubCategories(res?.data?.subcategory || []);
    }
  }

  const getElement = async (id) => {
    setLoading(true);
    await getBrands();

    let cat = await fetchData('get', 'element/get-all-category');
    const categoriesAll = cat?.data?.category || []
    
    let elm = await fetchData('get', `element/get-element/${id}`);
    const data = elm?.data;
    
    let sub = await fetchData('get', `element/get-all-subcategory?category_id=${data?.category_id}`);
    const subcategoriesAll = sub?.data?.subcategory || [];

    const categoryArray = categoriesAll.filter(e => e.id === data.category_id)[0];
    const subcategoryArray = subcategoriesAll.filter(e => e.id === data.subcategory_id)[0];

    data.category_name = categoryArray?.name || '';
    data.subcategory_name = subcategoryArray?.name || '';

    setAllCategories(categoriesAll);
    setAllSubCategories(subcategoriesAll);
    setElementData(data);

    setLoading(false);
  }

  const getInput = (id, placeholder) => {
    let inputType = null;

    if (id === 'title') {
      inputType = 'noSymbol';
    } else if (numberTypeId.includes(id)) {
      inputType = 'number';
    }

    return <Input
      id={id}
      value={elementData[id]}
      onChange={(e) => onInputChange(e)}
      placeholder={placeholder}
      error={elementError[id]}
      type={inputType}
      style={id === 'service_cost' ? { width: '48%' } : {}}
    />
  }

  const onInputChange = (e) => {
    if (!e || !e.target) return;
    const { id, value } = e.target;
    if (id === 'height' || id === 'length' || id === 'width') {
      if (value === '' || (/^\d+$/.test(value) && value !== '0')) {
        setElementData({ ...elementData, size: {...elementData.size, [id]: value} });
      }
    } else {
      setElementData({ ...elementData, [id]: value });
    }
    removeFromError(id);
  }

  const onSingleSelect = (value, id) => {
    setElementData({ ...elementData, [id]: value });
    removeFromError(id);
  }

  const onCategoryChange = (mode, id, name) => {
    if (mode === 'category') {
      setElementData({...elementData, category_id: id, category_name: name, subcategory_id: '', subcategory_name: ''});
    } else if (mode === 'subcategory') {
      setElementData({ ...elementData, subcategory_id: id, subcategory_name: name });
    }
    removeFromError(`${mode}_id`);
  }

  const onImageSelect = (value, id) => {
    setElementData({ ...elementData, [id]: value });
    removeFromError(id);
  }

  const onMultiSelect = (value, id) => {
    setElementData({ ...elementData, [id]: [...elementData[id], value] });
    removeFromError(id);
  }

  const onMultiSelectRemove = (value, id) => {
    const selectedArr = elementData[id].filter((val) => val !== value);
    setElementData({ ...elementData, [id]: selectedArr });
  }

  const removeFromError = (id) => {
    if (elementError[id]) {
      const errorData = {...elementError};
      delete errorData[id];
      setElementError(errorData);
    }
  }

  const getErrorMessage = (id, val) => {
    const value = typeof val === 'string' ? val.trim() : val;

    if (id === 'title') {
      if (!value) return 'Enter a title';
    } else if (id === 'sku') {
      if (!value) return 'Enter sku code';
    } else if (id === 'category_id') {
      if (!value) return 'Select category';
    } else if (id === 'subcategory_id') {
      if (!value) return 'Select subcategory';
    } else if (id === 'execution_category') {
      if (!value || !value.length) return 'Select minimum 1 execution category';
    } else if (id === 'brands') {
      if (!value || !value.length) return 'Select brand';
    } else if (id === 'misc') {
      if (!value || !value.length) return 'Select miscellaneous property';
    } else if (id === 'finishing') {
      if (!value || !value.length) return 'Select finish/colour';
    } else if (id === 'unit') {
      if (!value || !value.length) return 'Select unit';
    } else if (id === 'mrp') {
      if (!value) return 'Enter mrp';
    } else if (id === 'bx_price') {
      if (!value) return 'Enter BX price';
    } else if (id === 'procurement_cost') {
      if (!value) return 'Enter procurement cost';
    } else if (id === 'element_image') {
      if (!value || !value.length) return 'Select minimum 1 element image';
    } else if (id === 'length') {
      if (!value) return 'Enter a valid length';
    } else if (id === 'width') {
      if (!value) return 'Enter a valid width';
    } else if (id === 'height') {
      if (!value) return 'Enter a valid height';
    } else if (id === 'model_url') {
      if (!value) return 'Select android unity package';
    } else if (id === 'ios_model_url') {
      if (!value) return 'Select IOS unity package';
    } else if (id === 'webgl_model_url') {
      if (!value) return 'Select WebGL unity package';
    } else if (id === 'service_cost_type') {
      if (!value) return 'Select service cost type';
    } else if (id === 'service_cost') {
      if (!value) return 'Enter service cost';
    }

    return '';
  }

  const validateData = () => {
    const errorData = {};

    for (var key in elementData) {
      if (elementData.hasOwnProperty(key)) {
        if (key === 'size') {
          for (var sizeKey in elementData.size) {
            if (elementData.size.hasOwnProperty(sizeKey)) {
              if (getErrorMessage(sizeKey, elementData.size[sizeKey])) {
                errorData[sizeKey] = getErrorMessage(sizeKey, elementData.size[sizeKey]);
              } else {
                delete errorData[sizeKey];
              }
            }
          }
        } else {
          if (getErrorMessage(key, elementData[key])) {
            errorData[key] = getErrorMessage(key, elementData[key]);
          } else {
            delete errorData[key];
          }
        }
      }
    }

    if (Object.keys(errorData).length === 0) {
      setLoading(true);
      addNewExecutionCategory();
    } else {
      setElementError(errorData);
    }
  }

  const addNewExecutionCategory = async () => {
    let data = {...elementData};

    const key = 'execution_category';
    for (let x = 0; x < data[key].length; x++) {
      const arrFilter = allExecutionCategories.filter((e) => e.name && data[key][x] && e.name.trim().toLowerCase() == data[key][x].trim().toLowerCase())[0];

      if (arrFilter) {
        data[key][x] = arrFilter.name;
      } else {
        await fetchData('post', 'element/add-execution-category', { name: data[key][x] });
      }
    }

    delete data.category_name;
    delete data.subcategory_name;

    uploadElementImages(data);
  }

  const uploadElementImages = async (newData) => {
    const uploadKeys = ['element_image', 'model_url', 'ios_model_url', 'webgl_model_url'];
    let data = {...newData};

    for (let y = 0; y < uploadKeys.length; y++) {
      const key = uploadKeys[y];
      let mediaUrl = '';
      
      if (key === 'element_image') {
        const imageUrlArr = [];

        for (let x = 0; x < data[key].length; x++) {
          if (data[key] && data[key][x]) {
            mediaUrl = await uploadMedia(data[key][x]);
            imageUrlArr.push(mediaUrl);
          }
        }
        data = { ...data, [key]: imageUrlArr };
      } else {
        mediaUrl = await uploadMedia(data[key]);
        data = { ...data, [key]: mediaUrl };
      }
    }

    if (pathname === 'add-elements') {
      createElement(data);
    } else if (pathname === 'edit-elements') {
      updateElement(data);
    }
  }

  const createElement = async (data) => {
    let res = await fetchData('post', 'element/create-elements', data);
    if (res?.data) {
      toastError('The element has been added successfully!');
      navigate('/elements');
    } else {
      toastError(res?.message || 'Something went wrong');
    }
    setLoading(false);
  }

  const updateElement = async (data) => {
    let res = await fetchData('post', `element/update-elements/${data.id}`, data);
    if (res?.data) {
      toastError('The element has been updated successfully!');
      navigate('/elements');
    } else {
      toastError(res?.message || 'Something went wrong');
    }
    setLoading(false);
  }

  return (
    <Layout activePage="Elements" style={{ display: 'flex', flexDirection: 'column' }}>
      <Loader loading={loading} />

      <Heading
        heading={pathname === 'add-elements' ? 'Add New Element' : 'Update Element'}
        subHeading={pathname === 'add-elements' ? 'This is where you can add new bathroom element.' : 'This is where you can update bathroom element.'}
        backTo={{ text: 'Back to all elements', url: '/elements' }}
      />

      <Styled.CardDiv>
        <Styled.CardContentDiv>
          <Styled.RowDiv style={{ justifyContent: 'space-between' }}>
            <Styled.ColumnDiv style={{ width: '48%' }}>
              <Styled.TitleDiv notEmpty>Element Title</Styled.TitleDiv>
              {getInput('title', 'Enter element title')}
            </Styled.ColumnDiv>

            <Styled.ColumnDiv style={{ width: '48%' }}>
              <Styled.TitleDiv notEmpty>SKU Code</Styled.TitleDiv>
              {getInput('sku', 'Enter SKU code')}
            </Styled.ColumnDiv>
          </Styled.RowDiv>

          <Styled.ColumnDiv>
            <Styled.TitleDiv>Description</Styled.TitleDiv>
            <Textarea
              id="description"
              value={elementData.description}
              onChange={(e) => onInputChange(e)}
              style={{ height: '117px', padding: '12px 20px' }}
              placeholder="Enter description here"
              error={elementError.description}
            />
          </Styled.ColumnDiv>

          <Styled.RowDiv style={{ justifyContent: 'space-between' }}>
            <Styled.ColumnDiv style={{ width: '48%', flexShrink: '0' }}>
              <Styled.ColumnDiv>
                <Styled.TitleDiv notEmpty>Category</Styled.TitleDiv>
                <CategorySelect
                  placeholder="Select category"
                  options={allCategories}
                  selectedId={elementData.category_id}
                  selectedName={elementData.category_name}
                  onSelect={(id, name) => onCategoryChange('category', id, name)}
                  error={elementError.category_id}
                  updateList={getCategories}
                  mode="category"
                />
              </Styled.ColumnDiv>

              <Styled.ColumnDiv>
                <Styled.TitleDiv notEmpty>Execution Category</Styled.TitleDiv>
                <Multiselect
                  placeholder="Select execution category"
                  options={allExecutionCategories}
                  selected={elementData.execution_category}
                  onSelect={(value) => onMultiSelect(value, 'execution_category')}
                  onRemove={(value) => onMultiSelectRemove(value, 'execution_category')}
                  error={elementError.execution_category}
                />
              </Styled.ColumnDiv>

              <Styled.ColumnDiv>
                <Styled.TitleDiv notEmpty>Brand(s)</Styled.TitleDiv>
                <SingleSelect
                  placeholder="Select brand(s)"
                  onSelect={(value) => onSingleSelect(value, 'brands')}
                  data={allBrands}
                  style={{ width: '100%' }}
                  selectedId={elementData.brands}
                  error={elementError.brands}
                />
              </Styled.ColumnDiv>
              <Styled.ColumnDiv>
                <Styled.TitleDiv notEmpty>Finish/Colour</Styled.TitleDiv>
                <SingleSelect
                  placeholder="Select finishing"
                  onSelect={(value) => onSingleSelect(value, 'finishing')}
                  data={finishColors}
                  style={{ width: '100%' }}
                  selectedId={elementData.finishing}
                  error={elementError.finishing}
                />
              </Styled.ColumnDiv>
              <Styled.ColumnDiv>
                <Styled.TitleDiv notEmpty>Unit</Styled.TitleDiv>
                <SingleSelect
                  placeholder="Select unit"
                  onSelect={(value) => onSingleSelect(value, 'unit')}
                  data={elementUnits}
                  style={{ width: '100%' }}
                  selectedId={elementData.unit}
                  error={elementError.unit}
                />
              </Styled.ColumnDiv>
              <Styled.ColumnDiv>
                <Styled.TitleDiv notEmpty>BX Price</Styled.TitleDiv>
                {getInput('bx_price', 'Enter BX price (e.g. ₹2,00,000)')}
              </Styled.ColumnDiv>
              <Styled.ColumnDiv>
                <Styled.TitleDiv notEmpty>Element Image</Styled.TitleDiv>
                <UploadImage
                  selected={elementData.element_image}
                  onSelect={(value) => onImageSelect(value, 'element_image')}
                  error={elementError.element_image}
                />
              </Styled.ColumnDiv>
            </Styled.ColumnDiv>

            <Styled.ColumnDiv style={{ width: '48%', flexShrink: '0' }}>
              <Styled.ColumnDiv>
                <Styled.TitleDiv notEmpty>Sub-category</Styled.TitleDiv>
                <CategorySelect
                  placeholder="Select subcategory"
                  options={allSubCategories}
                  selectedId={elementData.subcategory_id}
                  selectedName={elementData.subcategory_name}
                  onSelect={(id, name) => onCategoryChange('subcategory', id, name)}
                  error={elementError.subcategory_id}
                  updateList={getSubCategories}
                  noIcon={true}
                  mode="subcategory"
                  selectedCategory={elementData.category_id}
                />
              </Styled.ColumnDiv>
              <Styled.ColumnDiv>
                <Styled.TitleDiv notEmpty>Size</Styled.TitleDiv>
                <Styled.RowDiv style={{ justifyContent: 'space-between' }}>
                  <Styled.SizeDiv style={elementError.length ? { border: '1px solid #DB1616' } : {}}>
                    <div>L</div>
                    <input id="length" value={elementData.size.length} onChange={(e) => onInputChange(e)} placeholder="Length(ft)" />
                  </Styled.SizeDiv>
                  <Styled.SizeDiv style={elementError.width ? { border: '1px solid #DB1616' } : {}}>
                    <div>B</div>
                    <input id="width" value={elementData.size.width} onChange={(e) => onInputChange(e)} placeholder="Width(ft)" />
                  </Styled.SizeDiv>
                  <Styled.SizeDiv style={elementError.height ? { border: '1px solid #DB1616' } : {}}>
                    <div>H</div>
                    <input id="height" value={elementData.size.height} onChange={(e) => onInputChange(e)} placeholder="Height(ft)" />
                  </Styled.SizeDiv>
                </Styled.RowDiv>
                { elementError.length || elementError.height || elementError.width ?
                  <Styled.ErrorDiv>Enter a valid size</Styled.ErrorDiv>
                : ''
                }
              </Styled.ColumnDiv>
              <Styled.ColumnDiv>
                <Styled.TitleDiv notEmpty>Miscellaneous Properties</Styled.TitleDiv>
                <SingleSelect
                  placeholder="Select misc. properties"
                  onSelect={(value) => onSingleSelect(value, 'misc')}
                  data={miscellaneousProperties}
                  style={{ width: '100%' }}
                  selectedId={elementData.misc}
                  error={elementError.misc}
                />
              </Styled.ColumnDiv>
              <Styled.ColumnDiv>
                <Styled.TitleDiv notEmpty>MRP</Styled.TitleDiv>
                {getInput('mrp', 'Enter MRP (e.g. ₹2,00,000)')}
              </Styled.ColumnDiv>
              <Styled.ColumnDiv>
                <Styled.TitleDiv notEmpty>Procurement Cost</Styled.TitleDiv>
                {getInput('procurement_cost', 'Enter procurement cost (e.g. ₹2,00,000)')}
              </Styled.ColumnDiv>
              <Styled.ColumnDiv>
                <Styled.TitleDiv notEmpty>Service Cost</Styled.TitleDiv>
                <Styled.RowDiv style={{ justifyContent: 'space-between' }}>
                  <SingleSelect
                    placeholder="Select option"
                    onSelect={(value) => onSingleSelect(value, 'service_cost_type')}
                    data={serviceCostTypes}
                    style={{ width: '48%', flexShrink: '0' }}
                    selectedId={elementData.service_cost_type}
                    error={elementError.service_cost_type}
                  />
                  {getInput('service_cost', 'Enter service cost')}
                </Styled.RowDiv>
              </Styled.ColumnDiv>
              <Styled.ColumnDiv>
                <Styled.TitleDiv notEmpty>Android Unity Package</Styled.TitleDiv>
                <Upload3DModal
                  selected={elementData.model_url}
                  onSelect={(value) => onImageSelect(value, 'model_url')}
                  error={elementError.model_url}
                />
              </Styled.ColumnDiv>
              <Styled.ColumnDiv>
                <Styled.TitleDiv notEmpty>IOS Unity Package</Styled.TitleDiv>
                <Upload3DModal
                  selected={elementData.ios_model_url}
                  onSelect={(value) => onImageSelect(value, 'ios_model_url')}
                  error={elementError.ios_model_url}
                />
              </Styled.ColumnDiv>
              <Styled.ColumnDiv>
                <Styled.TitleDiv notEmpty>WebGL Unity Package</Styled.TitleDiv>
                <Upload3DModal
                  selected={elementData.webgl_model_url}
                  onSelect={(value) => onImageSelect(value, 'webgl_model_url')}
                  error={elementError.webgl_model_url}
                />
              </Styled.ColumnDiv>

            </Styled.ColumnDiv>

          </Styled.RowDiv>
        </Styled.CardContentDiv>

        <Styled.CardFooterDiv>
          <SecondaryButton title="Cancel" onClick={() => navigate('/elements')} />
          <PrimaryButton title={pathname === 'add-elements' ? 'Add Element' : 'Update Element'} style={{ borderRadius: '8px', marginLeft: '20px' }} onClick={validateData} />
        </Styled.CardFooterDiv>
      </Styled.CardDiv>

    </Layout>
  )
}

export default withRouter(EditElements);
