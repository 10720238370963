import styled from "styled-components";

export const MainDiv = styled.div`
  background-image : url(/Home/home_page.svg);
  height: 100vh;
  width: 100vw;
`;
export const ProjectsWrapDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  border: 1px solid #ADB8AE;
  border-radius: 6px;
  background-color: #ffffff;
`;
export const TabWrapDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`;
export const TabDiv = styled.div`
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 15px 30px;
  box-shadow: 0px 4px 4px 0px #20944026;
`;
export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
`;
export const HeadingDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  & p {
    font-family: Nunito, sans-serif;
    font-size: 28px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 40px
  }
`;
export const DetailsDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
export const LoginForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const InputDiv = styled.div`
  width: 100%;
  min-height: 90px;
  display: flex;
  flex-direction: column;
`;
export const InputLabel = styled.p`
  text-align: left;
  font-weight: 400;
  margin: 0px 0px 12px 0px;
  font-size: 16px;
  font-family: Nunito, sans-serif;
`;
export const InputElement = styled.input`
  width: 100%;
  height: 44px;
  font-size: 16px;
  border-radius: 8px;
  box-sizing: border-box;
  border: ${({error}) => error ? '1px solid #DB1616' : '1px solid #ADB8AE'};
  flex-grow: 0;
  flex-shrink: 0;
  &:focus {
    outline: none;
  }
`;
export const ErrorDiv = styled.div`
  font-family: Nunito, sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #DB1616;
  text-align: ${({error}) => error ? error !== 'password does not match' ? 'left' : 'right' : 'right'};
  width: 100%;
`;
export const LinkDiv = styled.div`
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-top: 24px;
  cursor: pointer;
`;
