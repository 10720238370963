import styled from "styled-components";

export const PaginationDiv = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0px 0px 15px;
  border: 1px solid #bfbfbf;
  border-radius: 8px;
  background-color: #ffffff;
  overflow: hidden;
  & button {
    height: 38px;
    min-width: 38px;
    background-color: #ffffff;
    border-top: none;
    border-bottom: none;
    border-left: none;
    border-right: 1px solid #bfbfbf;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #344054;
    &:hover {
      background-color: #f8f8f8;
    }
  }
  & > div {
    height: 38px;
    min-width: 38px;
    border-right: 1px solid #bfbfbf;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #b7b7b7;
  }
  & button:last-child {
    border: none;
  }
  @media (max-width: 700px) {
    margin: 8px 0px;
  }
`;
export const NextPrevButton = styled.button`
  width: 115px;
  padding: ${props => props.val === 'next' ? '4px 32px 4px 4px' : '4px 4px 4px 32px'};
  border-top-left-radius: ${props => props.val === 'next' ? '0px' : '8px'};
  border-bottom-left-radius: ${props => props.val === 'next' ? '0px' : '8px'};
  border-bottom-right-radius: ${props => props.val === 'next' ? '8px' : '0px'};
  border-top-right-radius: ${props => props.val === 'next' ? '8px' : '0px'};
  position: relative;
  &:before {
    content: '';
    height: 8px;
    width: 8px;
    position: absolute;
    transform: rotate(45deg);
    border-bottom: ${props => props.val === 'next' ? 'none' : '2px solid #344054'};
    border-left: ${props => props.val === 'next' ? 'none' : '2px solid #344054'};

    border-top: ${props => props.val === 'next' ? '2px solid #344054' : 'none'};
    border-right: ${props => props.val === 'next' ? '2px solid #344054' : 'none'};

    left: ${props => props.val === 'next' ? 'auto' : '20px'};
    right: ${props => props.val === 'next' ? '20px' : 'auto'};
  }
  &:after {
    content: '';
    height: 2px;
    width: 12px;
    position: absolute;
    background-color: #344054;
    left: ${props => props.val === 'next' ? 'auto' : '20px'};
    right: ${props => props.val === 'next' ? '20px' : 'auto'};
  }
  & div {
    display: block;
    @media (max-width: 700px) {
      display: none;
    }
  }
`;
