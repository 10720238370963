import styled from "styled-components";

export const ContentWrapDiv = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid #bfbfbf;
  background-color: #ffffff;
  padding: 16px 0px;
`;
export const HeadingDiv = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #118D8B;
  margin: 28px 0px 0px 0px;
`;
export const CollapseBodyDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 28px 16px 28px;
  box-sizing: border-box;
`;
export const RowDiv = styled.div`
  display: flex;
  gap: 20px;
`;
export const ColumnDiv = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  & input {
    width: 100%;
    height: 42px;
    box-sizing: border-box;
    flex-grow: 0;
  }
`;
export const LabelDiv = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  margin: 20px 0px 12px 0px;
`;
export const DescriptionDiv = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  margin-top: 24px;
  &:before {
    content: '*'
  }
`;
export const FooterDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #bfbfbf;
  padding: 32px 32px 16px 32px;
  margin-top: 16px;
`;
export const AddInputWrapDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
export const AddInputDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  gap: 12px;
  margin-bottom: 12px;
`;
export const AddIconDiv = styled.div`
  position: absolute;
  height: 18px;
  width: 18px;
  border-radius: 18px;
  background-color: #118D8B;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  &:before {
    content: '';
    height: 10px;
    width: 2px;
    border-radius: 2px;
    background-color: #ffffff;
    position: absolute;
  }
  &:after {
    content: '';
    height: 2px;
    width: 10px;
    border-radius: 2px;
    background-color: #ffffff;
    position: absolute;
  }
`;
export const AdditionDiv = styled.div`
  width: calc(50% - 6px);
  align-self: flex-end;
  font-size: 14px;
  line-height: 19px;
  margin-top: 6px;
  color: #808080;
`;
