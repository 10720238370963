import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import Heading from "../../Components/Heading";
import SearchInput from "../../Components/SearchInput";
import SingleSelect from "../../Components/SingleSelect";
import Table from "../../Components/Table";
import InputDate from "../../Components/InputDate";
import { fetchData } from "../../Utilities/handleRequest";
import { withRouter } from "../../Utilities/withRouter";
import * as CommonStyled from "../../Common/commonStyled";

const Support = ({ navigate }) => {
  const [selectedType, setSelectedType] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [searchId, setSearchId] = useState('');

  const [allTickets, setAllTickets] = useState([]);

  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  const types = [
    { id: '', name: 'All tickets' },
    { id: 'open', name: 'Open tickets' },
    { id: 'closed', name: 'Closed tickets' }
  ];

  const categories = [
    { id: '', name: 'All categories' },
    { id: 'General', name: 'General' },
    { id: 'Technical', name: 'Technical' },
    { id: 'Sales', name: 'Sales' },
    { id: 'Billing', name: 'Billing' }
  ]

  useEffect(() => {
    setPage(1);
    getTickets(1);
  }, [searchId, limit, selectedType, selectedCategory,  selectedDate]);

  const getTickets = async (pageNo) => {
    setLoading(true);
    let url = `support-ticket/get-all-tickets?pageNo=${pageNo || page}&limit=${limit}`;
    if (selectedType) {
      url += `&type=${selectedType}`;
    }
    if (selectedCategory) {
      url += `&category=${selectedCategory}`;
    }
    if (selectedDate) {
      url += `&date=${selectedDate}`;
    }
    if (searchId) {
      url += `&ticket_id=${searchId}`;
    }

    let res = await fetchData('get', url);
    setAllTickets(res?.data?.tickets || []);
    setCount(res?.data?.count || 0);
    setLoading(false);
  }

  const onPageChange = (value) => {
    getTickets(value);
    setPage(value);
  }

  const navigateToTicket = (row) => {
    navigate(`/support-ticket/${row.id}`);
  }

  const headings = [
    { name: 'Ticket ID', key: 'id', type: 'text', sortBy: true },
    { name: 'Customer Name', key: 'client_name', type: 'text', sortBy: true },
    { name: 'Email', key: 'client_email', type: 'text', sortBy: true },
    { name: 'Contact No.', key: 'client_phone', type: 'text', sortBy: true },
    { name: 'Date & Time', key: 'created_at', type: 'date', sortBy: true },
    { name: 'Project', key: 'project_name', type: 'text', sortBy: true },
    { name: 'Action', type: 'action', actions: { view: {onClick: navigateToTicket, icon : '/view-icon.svg', toolText: 'View Ticket'} } }
  ];

  return (
    <Layout activePage="Support" style={{ display: 'flex', flexDirection: 'column' }}>
      <Heading
        heading="Support"
        subHeading="This is where you can view the listing of all the tickets."
      />

      <CommonStyled.FilterDiv>
        <div style={{ display: 'flex' }}>
          <InputDate
            onChange={(e) => setSelectedDate(e.target.value)}
            style={{ width: '240px' }}
            value={selectedDate}
          />
          <SingleSelect
            data={types}
            selectedId={selectedType}
            onSelect={(id) => setSelectedType(id)}
            style={{ marginLeft: '20px' }}
            placeholder="Select type"
          />
          <SingleSelect
            data={categories}
            selectedId={selectedCategory}
            onSelect={(id) => setSelectedCategory(id)}
            style={{ marginLeft: '20px' }}
            placeholder="Select categories"
          />
        </div>

        <SearchInput
          onInputChange={(e) => setSearchId(e.target.value)}
          placeholder="Search with ticket id"
          type="number"
          length={9}
        />
      </CommonStyled.FilterDiv>

      { !allTickets.length && !selectedCategory && !selectedType && !searchId ?
        <>
          { !loading ?
            <CommonStyled.NoDataFoundDiv>
              <img src="/no-support-ticket-icon.svg" />
              <div>No support ticket received so far</div>
            </CommonStyled.NoDataFoundDiv>
          : ''
          }
        </>
      :
        <Table
          rows={allTickets}
          headings={headings}
          page={page}
          limit={limit}
          count={count}
          onPageChange={onPageChange}
          onLimitChange={(value) => setLimit(value)}
          noDataImage="/no-ticket-found-icon.svg"
          noDataText="No ticket found"
          loading={loading}
          hideColumnToggle={true}
        />
      }

    </Layout>
  )
}

export default withRouter(Support);
