import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import Heading from "../../Components/Heading";
import SearchInput from "../../Components/SearchInput";
import Table from "../../Components/Table";
import Modal from "../../Components/Modal";
import { withRouter } from "../../Utilities/withRouter";
import * as CommonStyled from "../../Common/commonStyled";
import { fetchData } from "../../Utilities/handleRequest";

const Appointments = () => {
  const [searchText, setSearchText] = useState('');
  const [appointmentMessage, setAppointmentMessage] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [showMessageModal, setShowMessageModal] = useState(false);

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalAppointments, setTotalAppointments] = useState(0);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setPageNo(1);
    getAppointments(1);
  }, [searchText, pageSize]);

  const getAppointments = async (activePage) => {
    setLoading(true);
    let searchParams = '';
    searchText && (searchParams += `&search=${searchText}`);
    let res = await fetchData('get', `appointment/get-appointments?pageNo=${activePage}&limit=${pageSize}${searchParams}`);
    setAppointments(res?.data?.appointments)
    setTotalAppointments(res?.data?.count);
    setLoading(false);
  }

  const hideModal = () => {
    setShowMessageModal(false);
    setAppointmentMessage(null);
  }

  const onPageChange = (value) => {
    getAppointments(value);
    setPageNo(value);
  }

  const onMessageView = (row) => {
    setAppointmentMessage(row?.message);
    setShowMessageModal(true);
  }

  const headings = [
    { name: 'Customer Name', key: 'name', type: 'wrapText', sortBy: false },
    { name: 'Email', key: 'email', type: 'email', sortBy: false },
    { name: 'Mobile', key: 'phone_number', type: 'text', sortBy: false },
    { name: 'Address', key: 'address', type: 'wrapText', sortBy: false },
    { name: 'Date & Time', key: 'date', type: 'date', sortBy: false },
    { name: 'Message', type: 'action', actions: { view: { onClick: onMessageView, icon : '/message-view-icon.svg', activeIcon: '/message-view-active.svg', toolText:'View message'} } }
  ];

  return (
    <Layout activePage="Appointments" style={{ display: 'flex', flexDirection: 'column' }}>
      <Modal
        show={showMessageModal}
        hide={hideModal}
        heading={"Message"}
        hideFooter={true}
        width="446px"
      >
        {appointmentMessage}
      </Modal>

      <Heading
        heading="Appointments"
        subHeading="This is where you can view the list of the appointments requested by the customers."
      >
      </Heading>

      <CommonStyled.FilterDiv style={{flexDirection: 'row-reverse'}}>
        <SearchInput
          onInputChange={(e) => setSearchText(e.target.value)}
          placeholder={'Search from name'} 
        />
      </CommonStyled.FilterDiv>

      {!appointments?.length && !searchText ?
        <>
          {!loading ?
            <CommonStyled.NoDataFoundDiv>
              <img src="/appointments-color-icon.svg" alt="" />
              <div>No appointment booked so far</div>
            </CommonStyled.NoDataFoundDiv>
            : ''
          }
        </>
        :
        <Table
          rows={appointments}
          headings={headings}
          page={pageNo}
          limit={pageSize}
          count={totalAppointments}
          onPageChange={onPageChange}
          onLimitChange={(value) => setPageSize(value)}
          noDataImage="/no-appointment-found-icon.svg"
          noDataText="No appointment found"
          loading={loading}
        />
      }

    </Layout>
  )
}

export default withRouter(Appointments);
