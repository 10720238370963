import React, { useState, useEffect, useRef } from "react";
import { toastError } from "../../Utilities/toast";
import * as Styled from "./styled";

const Upload3DModal = ({ selected = [], onSelect = () => { }, error = '' }) => {
  const [selectedFile, setSelectedFile] = useState('');
  const [preview, setPreview] = useState('');

  const modalInput = useRef(null);

  useEffect(() => {
    if (!selected) {
      setPreview('');
      return;
    }

    let changedPreview = '';
    
    if (typeof selected === 'string') {
      changedPreview = getFileName(selected);
    } else {
      changedPreview = getFileName(selected.name);
    }
    
    setPreview(changedPreview);
    setSelectedFile(selected);

    if (modalInput && modalInput.current) {
      modalInput.current.value = '';
    }

    return () => {
      if (typeof selected !== 'string') {
        URL.revokeObjectURL(selected);
      }
    }
  }, [selected]);

  const getFileName = (name) => {
    if (!name) return '';
    const urlArr = name.split('/');
    const fileNameArr = urlArr[urlArr.length - 1];
    const nameArr = fileNameArr.split('.');
    return nameArr[0];
  }

  const onSelectFile = (e) => {
    if (!e || !e.target || !e.target.files || e.target.files.length === 0) return;
    const file = e.target.files[0];
    const fileArr = file.name.split('.');
    const fileExt = fileArr[fileArr.length - 1];

    if (fileExt !== 'unity3d') {
      toastError('File type not supported');
      return;
    }
    
    setSelectedFile(file);
    onSelect(file);
  }

  const onDropFile = (e) => {
    e.preventDefault();
    if (!e?.dataTransfer?.files?.length) return;
    const droppedFiles = Array.from(e.dataTransfer.files);

    const file = droppedFiles[0];
    const fileArr = file.name.split('.');
    const fileExt = fileArr[fileArr.length - 1];

    if (fileExt !== 'unity3d') {
      toastError('File type not supported');
      return;
    }
    
    setSelectedFile(file);
    onSelect(file);
  }

  const onPreviewRemove = () => {
    setSelectedFile('');
    onSelect('');
  }

  return (
    <Styled.UploadWrapDiv>
      <Styled.InputWrapLabel
        style={error ? { border: '1px solid red' } : {}}
        onDrop={onDropFile}
        onDragOver={(e) => e.preventDefault()}
      >
        <Styled.Text1Div>Upload unity package</Styled.Text1Div>
        <Styled.UploadImgDiv>
          <img src="/upload-image-icon.svg" />
        </Styled.UploadImgDiv>

        <input type="file" onChange={(e) => onSelectFile(e)} ref={modalInput} />
      </Styled.InputWrapLabel>

      { error ?
        <Styled.ErrorDiv>{error}</Styled.ErrorDiv>
      : ''
      }

      {preview && preview.length > 0 ?
        <Styled.SelectedItemsDiv>
          {!preview ? '' :
            <Styled.SelectedDiv>
              <div>{preview}</div>
              <button onClick={() => onPreviewRemove()}>
                <img src="/close-preview-icon.svg" />
              </button>
            </Styled.SelectedDiv>
          }
        </Styled.SelectedItemsDiv>
        : ''
      }
    </Styled.UploadWrapDiv>
  );
}

export default Upload3DModal;