import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import Heading from "../../Components/Heading";
import SearchInput from "../../Components/SearchInput";
import SingleSelect from "../../Components/SingleSelect";
import Table from "../../Components/ThemeTable";
import { fetchData } from "../../Utilities/handleRequest";
import { withRouter } from "../../Utilities/withRouter";
import { transactionStatus } from "../../StaticData/databases";
import * as CommonStyled from "../../Common/commonStyled";

const Transactions = () => {
  const [selectedStatus, setSelectedStatus] = useState('');
  const [searchText, setSearchText] = useState('');

  const [allTransactions, setAllTransactions] = useState(null);

  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  useEffect(() => {
    setPage(1);
    getTransactions(1);
  }, [searchText, limit, selectedStatus]);

  const getTransactions = async (pageNo) => {
    setLoading(true);
    let url = `payments/get-all-transactions?pageNo=${pageNo || page}&limit=${limit}`;
    if (selectedStatus) {
      url += `&status=${selectedStatus}`;
    }
    if (searchText) {
      url += `&searchText=${searchText}`;
    }

    let res = await fetchData('get', url);

    if (res) {
      setAllTransactions(res?.data || []);
      setCount(res?.count || 0);
    }
    setLoading(false);
  }

  const onPageChange = (value) => {
    getTransactions(value);
    setPage(value);
  }

  const headings = [
    { name: 'Customer Name', key: 'name', type: 'text', sortBy: true },
    { name: 'Email', key: 'email', type: 'email', sortBy: true },
    { name: 'Mobile', key: 'phone_number', type: 'text', sortBy: true },
    { name: 'Project Name', key: 'project_name', type: 'text', sortBy: true} ,
    { name: 'Transaction ID', key: 'id', type: 'text', sortBy: true },
    { name: 'Amount', key: 'amount', type: 'rupees', sortBy: true },
    { name: 'Date & Time', key: 'created_at', type: 'date', sortBy: true },
    { name: 'Status', key: 'status', type: 'transactionStatus', sortBy: true }
  ];

  return (
    <Layout activePage="Transactions" style={{ display: 'flex', flexDirection: 'column' }}>

      <Heading
        heading="Transactions History"
        subHeading="This is where you can view the listing of all the transactions."
      />

      <CommonStyled.FilterDiv>
        <SingleSelect
          data={transactionStatus}
          selectedId={selectedStatus}
          onSelect={(id) => setSelectedStatus(id)}
          placeholder="Select status"
        />

        <SearchInput
          onInputChange={(e) => setSearchText(e.target.value)}
          placeholder="Search keyword"
        />
      </CommonStyled.FilterDiv>

      { !allTransactions?.length && !selectedStatus && !searchText ?
        <>
          { !loading ?
            <CommonStyled.NoDataFoundDiv>
              <img src="/no-transactions-icon.svg" />
              <div>No transaction done so far</div>
            </CommonStyled.NoDataFoundDiv>
          : ''
          }
        </>
      :
        <Table
          rows={allTransactions}
          headings={headings}
          page={page}
          limit={limit}
          count={count}
          onPageChange={onPageChange}
          onLimitChange={(value) => setLimit(value)}
          noDataImage="/no-transactions-found-icon.svg"
          noDataText="No transaction found"
          loading={loading}
        />
      }

    </Layout>
  )
}

export default withRouter(Transactions);
