import React from "react";
import styled from "styled-components";
import Layout from "../../Components/Layout";

const MainWrap = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 100px;
  & > div:first-child {
    color: #118D8B;
    font-size: 120px;
    font-weight: 900;
  }
  & > div:last-child {
    font-size: 20px;
  }
`;

const NoFoundPage = () => {

  return (
    <Layout style={{ display: 'flex', flexDirection: 'column' }}>

      <MainWrap>
        <div>404</div>
        <div>Page not found</div>
      </MainWrap>

    </Layout>

  )
}

export default NoFoundPage;
