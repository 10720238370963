import React, { useState, useEffect } from "react";
import Layout from "../../Components/Layout";
import Heading from "../../Components/Heading";
import SearchInput from "../../Components/SearchInput";
import SingleSelect from "../../Components/SingleSelect";
import * as CommonStyled from "../../Common/commonStyled";
import Table from "../../Components/Table";
import Modal from "../../Components/Modal";
import Loader from "../../Components/Loader";
import { withRouter } from "../../Utilities/withRouter";
import { Star } from '../../Components/Rating'
import { fetchData } from "../../Utilities/handleRequest";

const Feedback = () => {
  const [selectedRatingType, setSelectedRatingType] = useState('');
  const [searchText, setSearchText] = useState('');

  const ratingTypes = [
    { id: '', name: 'All Ratings' },
    { id: '5', name: <p>5 <Star filled /></p> },
    { id: '4', name: <p>4 <Star filled /></p> },
    { id: '3', name: <p>3 <Star filled /></p> },
    { id: '2', name: <p>2 <Star filled /></p> },
    { id: '1', name: <p>1 <Star filled /></p> },
  ];

  const [feedbackMessage, setFeedbackMessage] = useState(null);
  const [feedbacks, setFeedbacks] = useState([]);
  const [showMessageModal, setShowMessageModal] = useState(false);

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalFeedbacks, setTotalFeedbacks] = useState(0);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setPageNo(1);
    getFeedbacks(1);
  }, [searchText, pageSize, selectedRatingType])

  const getFeedbacks = async (activePage) => {
    setLoading(true);
    let searchParams = '';
    searchText && (searchParams += `&search=${searchText}`);
    selectedRatingType && (searchParams += `&rating=${selectedRatingType}`);
    let res = await fetchData('get', `feedback/get-all-feedback?pageNo=${activePage}&limit=${pageSize}${searchParams}`);
    setFeedbacks(res?.data?.feedback)
    setTotalFeedbacks(res?.data?.count);
    setLoading(false);
  }

  const hideModal = () => {
    setShowMessageModal(false);
    setFeedbackMessage(null);
  }

  const onPageChange = (value) => {
    getFeedbacks(value);
    setPageNo(value);
  }

  const onMessageView = (row) => {
    setFeedbackMessage(row?.details);
    setShowMessageModal(true);
  }

  const headings = [
    { name: 'Customer Name', key: 'name', type: 'text', sortBy: true },
    { name: 'Phone No.', key: 'phone_number', type: 'text', sortBy: true },
    { name: 'Email', key: 'email', type: 'text', sortBy: true },
    { name: 'Project Name', key: 'project_name', type: 'text', sortBy: true },
    { name: 'Submitted On', key: 'created_at', type: 'date', sortBy: true },
    { name: 'Rating', key: 'rating', type: 'rating', sortBy: true },
    { name: 'Action', type: 'action', actions: { view: { onClick: onMessageView, icon: '/eye-icon-inactive.svg', activeIcon: '/eye-icon.svg', toolText: 'View detail' } } }
  ];

  return (
    <Layout activePage="Feedback" style={{ display: 'flex', flexDirection: 'column' }}>
      <Modal
        show={showMessageModal}
        hide={hideModal}
        heading={"Feedback Detail"}
        hideFooter={true}
        width="446px"
      >
        {feedbackMessage}
      </Modal>

      <Heading
        heading="Feedback"
        subHeading="This is where you can view the feedback provided by the customers."
      />
      {/* <StarRating rating={3}/> */}

      <CommonStyled.FilterDiv>
        <div style={{ display: 'flex' }}>
          <SingleSelect
            data={ratingTypes}
            selectedId={selectedRatingType}
            onSelect={(id) => setSelectedRatingType(id)}
          />
        </div>

        <SearchInput onInputChange={(e) => setSearchText(e.target.value)} placeholder='Search for name' />
      </CommonStyled.FilterDiv>

      {!feedbacks?.length && !searchText && !selectedRatingType ?
        <>
          {!loading ?
            <CommonStyled.NoDataFoundDiv>
              <img src="/no-feedback.svg" alt="" />
              <div>No feedback received so far</div>
            </CommonStyled.NoDataFoundDiv>
            : ''
          }
        </>
        :
        <Table
          rows={feedbacks}
          headings={headings}
          page={pageNo}
          limit={pageSize}
          count={totalFeedbacks}
          onPageChange={onPageChange}
          onLimitChange={(value) => setPageSize(value)}
          noDataImage="/no-feedback-found-icon.svg"
          noDataText="No feedback found"
          loading={loading}
        />
      }

    </Layout>

  )
}

export default withRouter(Feedback);
