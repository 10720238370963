import { useState } from "react";
import styled from "styled-components";

const SearchInputDiv = styled.div`
  width: 400px;
  border-radius: 6px;
  border: 1px solid #ADB8AE;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding-right: 15px;
  & > input {
    font-size: 14px;
    color: #000000;
    border: none;
    background: none;
    flex-grow: 1;
    &:focus {
      outline: none;
    }
  }
`;

const SearchInput = ({ onInputChange, placeholder, style, type, length }) => {
  const [searchText, setSearchText] = useState('');

  const inputChange = (e) => {
    const value = e.target.value;

    if (!type || value === '' || (type === 'number' && /^\d+$/.test(value))) {
      if (!length || value === '' || value?.length <= length) {
        setSearchText(value);
        onInputChange(e);
      }
    }
  }

  return (
    <SearchInputDiv style={style}>
      <input placeholder={placeholder} onChange={(e) => inputChange(e)} value={searchText} />
      <img src="/search-icon.svg" />
    </SearchInputDiv>
  )
}

export default SearchInput;
