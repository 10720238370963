import React from "react";
import * as Styled from "./styled";

const Heading = ({ heading, subHeading, backTo, children }) => {
  return (
    <Styled.MainDiv>
      <Styled.LeftDiv>
        { backTo ?
          <Styled.BackToDiv to={backTo.url}>
            <img src="/back-arrow.svg" />
            {backTo.text}
          </Styled.BackToDiv>
        : ''
        }
        <Styled.HeadingDiv>{heading}</Styled.HeadingDiv>
        <div>{subHeading}</div>
      </Styled.LeftDiv>

      { children ?
        <Styled.RightDiv>
          {children}
        </Styled.RightDiv>
      : ''
      }

    </Styled.MainDiv>
  )
}

export default Heading;
