import styled from "styled-components";

export const InputWrapDiv = styled.div`
  display: flex;
  flex-direction: column;
  & input {
    width: 100%;
    box-sizing: border-box;
  }
`;
export const ErrorDiv = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #DB1616;
  text-align: right;
`;