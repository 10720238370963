import * as Styled from "./styled";

const InputGroup = ({ error='', id="", value='', onChange=()=>{}, placeholder="", type="", style={}, groupValue="", groupAtLast=false, disabled=false }) => {

  const onInputChange = (e) => {
    const {value} = e.target;
    const format = /^[a-zA-Z0-9 ]+$/i;

    if (!type
      || value === ''
      || (type === 'number' && /^\d+$/.test(value))
      || (type === 'noSymbol' && format.test(value))) {
      onChange(e);
    }
  }

  return (
    <Styled.InputWrapDiv style={style}>
      <Styled.InputDiv style={error ? { border: '1px solid #DB1616' } : {}} atLast={groupAtLast}>
        { !groupAtLast ? <div>{groupValue}</div> : '' }
        <input
          id={id}
          value={value}
          onChange={onInputChange}
          placeholder={placeholder}
          disabled={disabled}
        />
        { groupAtLast ? <div>{groupValue}</div> : '' }
      </Styled.InputDiv>

      { error ?
        <Styled.ErrorDiv>{error}</Styled.ErrorDiv>
      : ''
      }
    </Styled.InputWrapDiv>
  )
}

export default InputGroup;
