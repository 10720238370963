import styled from "styled-components";

export const MainDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  & button {
    font-size: ${({ fontSize }) => fontSize ? fontSize : '14px'} !important;
  }
  & div {
    font-size: ${({ fontSize }) => fontSize ? fontSize : '14px'} !important;
  }
  & input {
    font-size: ${({ fontSize }) => fontSize ? fontSize : '14px'} !important;
  }
  & p {
    font-size: ${({ fontSize }) => fontSize ? fontSize : '14px'} !important;
  }
`;
export const SelectButtonDiv = styled.div`
  width: 100%;
  display: flex;
`;
export const SelectButton = styled.button`
  height: ${({ customHeight }) => customHeight ? customHeight : '44px'};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border: ${({ isopen }) => isopen ? '2px solid #118D8B' : '1px solid #bfbfbf'};
  border-radius: 8px;
  padding: 0px 36px 0px 15px;
  position: relative;
  & p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:after {
    content: '';
    height: 9px;
    width: 9px;
    position: absolute;
    right: 12px;
    top: ${({ isopen }) => isopen ? '16px' : '12px'};
    border-bottom: 2px solid #ADB8AE;
    border-right: 2px solid #ADB8AE;
    transform: ${({ isopen }) => isopen ? 'rotate(-135deg)' : 'rotate(45deg)'};
    transition: all 0.3s;
  }
`;
export const DropdownDiv = styled.div`
  width: 100%;
  overflow: hidden;
  transition: all 0.3s;
  position: absolute;
  ${({ direction, customHeight }) => direction === 'up' ? `bottom: ${customHeight ? customHeight : '44px'};` : `top: ${customHeight ? customHeight : '44px'};`}
  z-index: 2;
`;
export const ListDiv = styled.div`
  max-height: 240px;
  display: flex;
  flex-direction: column;
  border: 1px solid #ADB8AE;
  border-radius: 8px;
  overflow: auto;
  transition: all 0.3s;
`;
export const ListButton = styled.button`
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${({ active }) => active === 'true' ? '#118D8B' : '#ffffff'};
  color: ${({ active }) => active === 'true' ? '#ffffff' : '#000000'};
  padding: 0px 15px;
  z-index: 2;
  flex-shrink: 0;
  &:hover {
    background-color: ${({ active }) => active === 'true' ? '#118D8B' : '#F1F1F1'};
  }
  & p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const ErrorDiv = styled.div`
  font-weight: 400;
  line-height: 24px;
  color: #DB1616;
  text-align: right;
`;
export const SearchInputDiv = styled.div`
  height: 40px;
  border-radius: 6px;
  border: 1px solid #ADB8AE;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  & > input {
    color: #000000;
    border: none;
    background: none;
    flex-grow: 1;
    &:focus {
      outline: none;
    }
  }
  & > img {
    height: 18px;
    width: 18px;
    padding: 0px 20px;
  }
`;
export const OtherButton = styled.button`
  height: 44px;
  width: 44px;
  background-color: #ffffff;
  border: 1px solid #bfbfbf;
  border-radius: 8px;
  margin-left: 16px;
  flex-shrink: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &:before {
    content: '';
    width: 3px;
    height: 24px;
    background-color: #000000;
    border-radius: 3px;
    position: absolute;
  }
  &:after {
    content: '';
    height: 3px;
    width: 24px;
    background-color: #000000;
    border-radius: 3px;
    position: absolute;
  }
`;
export const DropdownWrapDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;
export const OtherInputDiv = styled.div`
  display: flex;
  align-items: center;
  & button {
    height: 44px;
    width: 44px;
    border-radius: 8px;
    margin-left: 16px;
    flex-shrink: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
  }
`;
export const AddOtherButton = styled.button`
  background-color: #118D8B;
  &:before {
    content: '';
    width: 10px;
    height: 18px;
    border-bottom: 4px solid #ffffff;
    border-right: 4px solid #ffffff;
    transform: rotate(45deg);
    position: absolute;
    top: 8px;
  }
`;
export const CancelOtherButton = styled.button`
  background-color: red;
  &:before {
    content: '';
    width: 3.5px;
    height: 24px;
    background-color: #ffffff;
    position: absolute;
    transform: rotate(45deg);
  }
  &:after {
    content: '';
    width: 3.5px;
    height: 24px;
    background-color: #ffffff;
    position: absolute;
    transform: rotate(-45deg);
  }
`;
