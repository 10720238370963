import * as Styled from "./styled";

const Checkbox = ({ style, onChange=()=>{}, label, checked, disabled=false }) => {
  return (
    <Styled.MainLabel style={style}>
      <input
        type="checkbox"
        onChange={(e) => onChange(e.target.checked)}
        checked={checked}
        disabled={disabled}
      />
      <span />
      <p>{label}</p>
    </Styled.MainLabel>
  )
}

export default Checkbox;
