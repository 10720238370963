import styled from "styled-components";

export const MainWrapDiv = styled.div`
  display: flex;
  flex-direction: ${({layout}) => layout === 'vertical' ? 'column' : 'row'};
  align-items: ${({layout}) => layout === 'horizontal' ? 'center' : 'flex-end'};
  width: 100%;
`;
export const ProgressDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 16px;
  border-radius: 16px;
  background-color: #c3e3e2;
  overflow: hidden;
  flex-grow: 1;
  & div {
    height: 16px;
    border-radius: 16px;
    background-color: #118D8B;
  }
`;
export const ProgressInfoDiv = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  margin-left: 22px;
  white-space: nowrap;
  flex-shrink: 0;
`;