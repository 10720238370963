import styled from "styled-components";

export const DetailsDiv = styled.div`
  display: flex;
  flex-direction: column;
  & p {
    margin: 10px 0px;
    font-size: 20px;
    word-break: break-all;
  }
`;
export const ExtendWrapDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ModalTextDiv = styled.div`
  font-size: 16px;
  margin-bottom: 12px;
  &:after {
    content: '*';
    color: #db1616;
    margin-left: 2px;
  }
`;