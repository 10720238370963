import styled from "styled-components";

export const MainDiv = styled.div`
  width: 40px;
  display: flex;
  flex-direction: column;
  position: relative;
`;
export const SelectButton = styled.button`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  position: relative;
  padding: 0px;
`;
export const DropdownDiv = styled.div`
  width: 100px;
  overflow: hidden;
  transition: all 0.3s;
  position: absolute;
  top: 54px;
  right: 0px;
  z-index: 2;
`;
export const ListDiv = styled.div`
  max-height: 240px;
  display: flex;
  flex-direction: column;
  border: 1px solid #ADB8AE;
  border-radius: 8px;
  overflow: auto;
  transition: all 0.3s;
`;
export const ListButton = styled.button`
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  color: #000000;
  padding: 0px 15px;
  z-index: 2;
  flex-shrink: 0;
  &:hover {
    background-color: #118D8B;
    color: #ffffff;
  }
  & p {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
