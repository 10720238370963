import styled from "styled-components";

export const InputWrapDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  & input {
    background: none;
    border: none;
    
  }
`;
export const InputLabel = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  border: 1px solid #bfbfbf;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 0px 15px;
  box-sizing: border-box;
  font-size: 14px;
  position: relative;
  cursor: pointer;
  & img {
    width: 18px;
    height: 20px;
  }
  & input {
    height: 100% !important;
    width: 100% !important;
    padding: 0px !important;
    opacity: 0;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
  }
  & input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    color: transparent;
    position: absolute;
    height: auto;
    width: auto;
    cursor: pointer;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
  & input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    background: transparent;
    color: transparent;
    position: absolute;
    height: auto;
    width: auto;
    cursor: pointer;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
`;
export const ErrorDiv = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #DB1616;
  text-align: right;
`;