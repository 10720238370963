import { useEffect, useState } from "react";
import PrimaryButton from "../../../../Components/PrimaryButton";
import InputDate from "../../../../Components/InputDate";
import UploadFile from "../UploadFile";
import { MilestoneLayout } from "../../layout";
import * as Styled from "./styled";
import { uploadMedia } from "../../../../Utilities/handleRequest";

const SubCollapse = ({
  heading = '',
  index,
  active = false,
  setActive = () => {},
  collapseSubCollapseData,
  collapseIndex,
  onSubCollapseSave,
  setLoading,
  isDisabled=false,
}) => {
  const [subCollapseData, setSubCollapseData] = useState({...collapseSubCollapseData});
  const [layout, setLayout] = useState(MilestoneLayout(subCollapseData)?.[collapseIndex]?.layout?.[index]?.[0]);

  useEffect(() => {
    setLayout(MilestoneLayout(subCollapseData)?.[collapseIndex]?.layout?.[index]?.[0]);
  }, [subCollapseData]);

  useEffect(() => {
    setSubCollapseData({ ...collapseSubCollapseData });
  }, [collapseSubCollapseData]);

  const onSubCollapseChange = () => {
    if (active) {
      setActive('');
    } else {
      setActive(index);
      setSubCollapseData({ ...collapseSubCollapseData });
    }
  }

  const onChangeFiles = (value) => {
    const data = { ...subCollapseData || {} };
    data.files = value;
    setSubCollapseData(data);
  }

  const getProgress = (data) => {
    if (!data) return 0;
    const fieldsArray = ['start_date', 'end_date', 'remarks', 'files'];
    let p = 0;

    for (let x = 0; x < fieldsArray.length; x++) {
      if (fieldsArray[x] === 'files') {
        if (data?.[fieldsArray[x]] && data?.[fieldsArray[x]]?.length) p++;
      } else {
        if (data?.[fieldsArray[x]] && data?.[fieldsArray[x]]?.trim()) p++;
      }
    }

    return p * 25;
  }

  const onSaveAndNext = async () => {
    setLoading(true);
    const data = {...subCollapseData};
    const files = [];
    if (data?.files) {
      for (let x = 0; x < data.files.length; x++) {
        if (typeof data.files[x] === 'string') {
          files.push(data.files[x]);
        } else {
          const imageUrl = await uploadMedia(data.files[x]);
          files.push(imageUrl);
        }
      }
    }
    data.files = files;
    data.progress = getProgress(data);

    onSubCollapseSave(layout.name, data);
  }
  
  return (
    <Styled.MainWrapDiv active={active ? 'true' : 'false'}>
      <Styled.HeadWrapDiv active={active ? 'true' : 'false'} onClick={onSubCollapseChange}>
        <Styled.HeadingDiv active={active ? 'true' : 'false'}>{heading}</Styled.HeadingDiv>
        {subCollapseData.progress === 100 ? <Styled.CompletedDiv /> : ''}
      </Styled.HeadWrapDiv>

      <Styled.ContentWrapDiv maxHeight="800px" active={active ? 'true' : 'false'}>
        <Styled.RowDiv>
          <Styled.ColumnDiv>
            <Styled.InputLabelDiv>Start Date</Styled.InputLabelDiv>
            <InputDate
              onChange={(e) => setSubCollapseData({ ...subCollapseData, start_date: e.target.value })}
              value={subCollapseData?.start_date || ''}
              placeholder="Select start date"
              style={{ width: '100%' }}
              max={subCollapseData?.end_date}
              disabled={isDisabled}
              min={new Date().toISOString().split('T')[0]}

            />
          </Styled.ColumnDiv>

          <Styled.ColumnDiv>
            <Styled.InputLabelDiv>End Date</Styled.InputLabelDiv>
            <InputDate
              onChange={(e) => setSubCollapseData({ ...subCollapseData, end_date: e.target.value })}
              value={subCollapseData?.end_date || ''}
              placeholder="Select end date"
              style={{ width: '100%' }}
              min={subCollapseData?.start_date}
              disabled={isDisabled}
            />
          </Styled.ColumnDiv>
        </Styled.RowDiv>

        <Styled.ColumnDiv style={{ width: '100%' }}>
          <Styled.InputLabelDiv>Remarks</Styled.InputLabelDiv>
          <textarea
            id="remarks"
            onChange={(e) => setSubCollapseData({ ...subCollapseData, remarks: e.target.value })}
            value={subCollapseData.remarks || ''}
            disabled={isDisabled}
            style={{ backgroundColor: isDisabled ? '#f8f8f8' : '#ffffff' }}
          />
        </Styled.ColumnDiv>

        <Styled.ColumnDiv>
          <Styled.InputLabelDiv>Upload File</Styled.InputLabelDiv>
          <UploadFile
            selected={subCollapseData.files}
            changeFiles={(value) => onChangeFiles(value)}
            error={''}
            disabled={isDisabled}
          />
        </Styled.ColumnDiv>

        <PrimaryButton
          style={{ alignSelf: 'flex-end', marginTop: '24px' }}
          title={layout?.submit?.text}
          onClick={onSaveAndNext}
          disabled={isDisabled}
        />

      </Styled.ContentWrapDiv>
    </Styled.MainWrapDiv>
  )
}

export default SubCollapse;
