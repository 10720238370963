import * as Styled from "./styled";

const Loader = ({ loading }) => {
  if (loading) {
    return (
      <Styled.LoaderOverlay>
        <div />
      </Styled.LoaderOverlay>
    );
  }

  return null;
};

export default Loader;
