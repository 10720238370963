import React, { useState, useRef } from "react";
import useOutsideClick from "../../../Hooks/useOutsideClick";
import { clearLocalStorage } from "../../../Utilities/localStorageHandler";
import * as Styled from "./styled";

const HeaderMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onLogout = () => {
    clearLocalStorage();
    setIsOpen(false);
    window.location.href = '/';
  }

  const dropdownRef = useRef(null);
  useOutsideClick({
    ref: dropdownRef,
    handler: () => setIsOpen(false),
  });

  return (
    <Styled.MainDiv ref={dropdownRef}>
      <Styled.SelectButton
        onClick={() => setIsOpen(!isOpen)}
        isOpen={isOpen}
      >
        <img src={isOpen ? '/user-icon-active.svg' : '/user-icon.svg'} />
      </Styled.SelectButton>

      <Styled.DropdownDiv style={{ maxHeight: isOpen ? '100px' : '0px' }}>
        <Styled.ListDiv>
          <Styled.ListButton onClick={onLogout}>Logout</Styled.ListButton>
        </Styled.ListDiv>
      </Styled.DropdownDiv>

    </Styled.MainDiv>
  )
}

export default HeaderMenu;
