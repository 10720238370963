import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Button = styled.button`
  height: 44px;
  padding: 0px 25px;
  display: flex;
  align-items: center;
  border-radius: 44px;
  background-color: #118D8B;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
`;
const LinkButton = styled(Link)`
  height: 44px;
  padding: 0px 25px;
  display: flex;
  align-items: center;
  border-radius: 44px;
  background-color: #118D8B;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  text-decoration: none;
`;
const AddIcon = styled.img`
  height: 18px;
  width: 18px;
  margin-right: 10px;
`;

const PrimaryButton = ({ title, style, addIcon, to, onClick=()=>{}, disabled=false }) => {
  return (
    to ?
      <LinkButton style={{...style, backgroundColor: disabled ? '#bfbfbf' : '#118D8B'}} to={to} onClick={onClick} disabled={disabled}>
        { addIcon ?
          <AddIcon src="/add-icon-white.svg" />
        : ''
        }
        {title}
      </LinkButton>
    :
      <Button style={{...style, backgroundColor: disabled ? '#bfbfbf' : '#118D8B'}} onClick={onClick} disabled={disabled}>
        { addIcon ?
          <AddIcon src="/add-icon-white.svg" />
        : ''
        }
        {title}
      </Button>
  )
}

export default PrimaryButton;
