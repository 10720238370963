import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import Heading from "../../Components/Heading";
import SearchInput from "../../Components/SearchInput";
import SingleSelect from "../../Components/SingleSelect";
import Modal from "../../Components/Modal";
import Table from "../../Components/ThemeTable";
import Loader from "../../Components/Loader";
import ToolTip from "../../Components/ToolTip";
import { userTypes, projectStatus } from "../../StaticData/databases";
import { fetchData } from "../../Utilities/handleRequest";
import { withRouter } from "../../Utilities/withRouter";
import { toastError } from "../../Utilities/toast";
import * as CommonStyled from "../../Common/commonStyled";
import * as Styled from "./styled";
import PrimaryButton from "../../Components/PrimaryButton";
import * as XLSX from 'xlsx';


const Projects = ({ navigate }) => {
  const [allProjects, setAllProjects] = useState([]);
  const [actionProject, setActionProject] = useState(null);
  const [showAssignModal, setShowAssignModal] = useState(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  const [searchText, setSearchText] = useState('');
  const [searchStatus, setSearchStatus] = useState('');

  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);

  const [assignRoles, setAssignRoles] = useState([{}]);
  const [membersList, setMembersList] = useState({});
  const [projectsPage,setProjectsPage] = useState(null);


  useEffect(() => {
    setPage(1);
    getProjectsList(1);
  }, [limit, searchStatus, searchText]);

  const getMembersList = async (role_id) => {
    let res = await fetchData('get', `projects/manager/${role_id}`);
    let list = [];
    res?.data?.map(member => {
      member.name = `${member.name}(${member.email})`;
      list.push(member);
    });
    const listMember = {...membersList};
    listMember[role_id] = list;
    setMembersList(listMember)
  }

  const getProjectsList = async (pageNo) => {
    setFetching(true);
    let url = `projects/get-all-projects?pageNo=${pageNo || page}&limit=${limit}`;
    if (searchStatus && searchStatus !== 'All') {
      url += `&status=${searchStatus}`;
    }
    if (searchText) {
      url += `&search=${searchText}`;
    }

    let res = await fetchData('get', url);
    setAllProjects(res?.data?.projects || []);
    const totalPages = Math.ceil(res?.data?.count / limit);
    setProjectsPage(totalPages);
    setCount(res?.data?.count);
    setFetching(false);
  }

  

const getTotalExistingProject = async () => {
  try {
    setLoading(true);
    let allData = [];
    const promises = [];

    for (let currentPage = 1; currentPage <= projectsPage; currentPage++) {
      let fullUrl = `projects/get-all-projects?pageNo=${currentPage}&limit=${limit}`;
      if (searchStatus && searchStatus !== 'All') {
        fullUrl += `&status=${searchStatus}`;
      }
      if (searchText) {
        fullUrl += `&search=${searchText}`;
      }
      promises.push(fetchData('get', fullUrl));
    }

    const results = await Promise.all(promises);
    results.forEach(result => {
      allData = allData.concat(result?.data?.projects || []);
    });
    
    // Transform data for Excel export
    const excelData = allData.map(project => ({
      'Project Name': project.project_name,
      'Client Name': project.name,
      'Billing Name': project.billing_name,
      'BX Code': project.bx_code,
      'Email': project.email,
      'Mobile No': project.phone_number,
      'Address': project.address,
      'Status': project.status,
      'Quote Value': project.quote_value,
      'Received Amount': project.received_amount
    }));

    // Create workbook and worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(excelData);

    // Add worksheet to workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Projects');

    // Generate Excel file
    XLSX.writeFile(wb, 'BathXpertz_Projects.xlsx');

  } catch (error) {
    console.error('Export failed:', error);
    toastError('Export failed. Please try again.');
  }
  finally{
    setLoading(false);
  }
}


  const validateAssign = async () => {
    let isValid = true;
    const roles = [...assignRoles];

    if (!roles?.[0]) {
      roles.push({});
    }

    for (let x = 0; x < roles.length; x++) {
      if (!roles?.[x]?.role) {
        roles[x].roleError = 'Select role';
        isValid = false;
      } else {
        delete roles[x].roleError;
      }
      if (!roles?.[x]?.id) {
        roles[x].memberError = 'Select team member';
        isValid = false;
      } else {
        delete roles[x].memberError;
      }
    }
    setAssignRoles(roles);

    if (!isValid) {
      return;
    }

    const data = { project_id: actionProject.id };
    const add_admin = [];

    let admin_ids = actionProject?.admins?.map(r => r.id) || [];

    for (let x = 0; x < roles.length; x++) {
      if (!admin_ids.includes(roles[x].id)) {
        add_admin.push(roles[x].id);
      } else {
        const index = admin_ids.indexOf(roles[x].id);
        admin_ids.splice(index, 1);
      }
    }

    if (admin_ids?.length) {
      data.delete_admin = [...admin_ids];
    }

    if (add_admin?.length) {
      data.add_admin = [...add_admin];
    }

    onTeamMemberAssign(data);
  }

  const onTeamMemberAssign = async (data) => {
    setLoading(true);
    let res = await fetchData('post', 'projects/add-project-admin', data);
    if (res?.message === 'Successfully added') {
      toastError('Member assigned successfully');
      getProjectsList();
      hideModal();
    } else {
      toastError('Something went wrong');
    }
    setLoading(false);
  }

  const onPageChange = (value) => {
    setPage(value);
    getProjectsList(value);
  }

  const hideModal = () => {
    setShowAssignModal(false);
    setAssignRoles([{}]);
    setActionProject(null);
  }

  const navigateToDetails = (row) => {
    navigate(`/projects/${row.id}`);
  }

  const isViewHide = (row) => {
    if (row.received_amount == 0) {
      return true;
    }
    return false;
  }

  const onAssign = async (row) => {
    setActionProject({...row});
    
    if (row?.admins?.length) {
      const rolesAssign = [];
      for (let x = 0; x < row.admins.length; x++) {
        rolesAssign.push({
          id: row.admins[x].id,
          role: row.admins[x].role,
          name: row.admins[x].name
        });
        await getMembersList(row.admins[x].role);
      }
      setAssignRoles(rolesAssign);
    } else {
      setAssignRoles([{}]);
    }
    setShowAssignModal(true);
  }

  const onAddRole = () => {
    const rolesAssign = [...assignRoles];
    rolesAssign.push({});
    setAssignRoles(rolesAssign);
    setMembersList({...membersList});
  }

  const onRemoveRole = (index) => {
    const rolesAssign = [...assignRoles];
    rolesAssign.splice(index, 1);
    setAssignRoles(rolesAssign);
  }

  const onAssignSelect = (index, key, value, list) => {
    const rolesAssign = [...assignRoles];
    if (!rolesAssign[index]) {
      rolesAssign[index] = {};
    }

    if (key === 'role') {
      rolesAssign[index].role = value;
      delete rolesAssign[index].id;
      delete rolesAssign[index].name;
      getMembersList(value);
    } else if (key === 'id') {
      const selectedMember = list?.filter((item) => item.id === value) || [];
      if (selectedMember[0]) {
        rolesAssign[index].id = selectedMember[0].id;
        rolesAssign[index].name = selectedMember[0].name;
      }
    }

    setAssignRoles(rolesAssign);
    removeFromError(index, key);
  }

  const removeFromError = (index, mode) => {
    const rolesAssign = [...assignRoles];
    if (mode === 'role') {
      delete rolesAssign[index].roleError;
    } else {
      delete rolesAssign[index].memberError;
    }
    setAssignRoles(rolesAssign);
  }

  const headings = [
    { name: 'Project Name', key: 'project_name', type: 'text', sortBy: true },
    { name: 'Client Name', key: 'name', type: 'text', sortBy: true },
    { name: 'Billing Name', key: 'billing_name', type: 'text', sortBy: true },
    { name: 'BX Code', key: 'bx_code', type: 'text', sortBy: true },
    { name: 'Email', key: 'email', type: 'email', sortBy: true },
    { name: 'Mobile No', key: 'phone_number', type: 'text', sortBy: true },
    { name: 'Address', key: 'address', type: 'address', sortBy: true },
    { name: 'Status', key: 'status', type: 'status', sortBy: true, style: {whiteSpace: 'nowrap'} },
    { name: 'Stage', key: ['milestone_metadata', 'status'], type: 'stage', sortBy: true, style: {whiteSpace: 'nowrap'} },
    { name: 'Quote Value', key: 'quote_value', type: 'rupees', sortBy: true },
    { name: 'Received Amount', key: 'received_amount', type: 'rupees', sortBy: true },
    { name: 'Action', type: 'action', actions: { view: { onClick: navigateToDetails, toolText: 'View detail', hide: isViewHide }, addUser: { onClick: onAssign, toolText: 'Assign team member' } } }
  ];

  return (
    <Layout activePage="Projects" style={{ display: 'flex', flexDirection: 'column' }}>
      <Loader loading={loading} />

      <Modal
        show={showAssignModal}
        hide={hideModal}
        hideFooter={true}
        width="600px"
        heading="Assign Team Member"
        contentStyle={{ overflow: 'visible', padding: '0px' }}
      >
        <Styled.ColumnDiv style={{ overflow: assignRoles?.length > 5 ? 'auto' : 'visible' }}>

          <Styled.RowDiv style={{ paddingRight: assignRoles?.length > 1 ? '64px' : '0px' }}>
            <Styled.AddEditTitleDiv notEmpty>Role</Styled.AddEditTitleDiv>
            <Styled.AddEditTitleDiv notEmpty>Team Member</Styled.AddEditTitleDiv>
          </Styled.RowDiv>

          { assignRoles.map((item, itemIndex) => (
            <Styled.RowDiv>
              <Styled.RowDiv>
                <Styled.InputWrapDiv iserror={item?.roleError} style={{ flexShrink: 0 }}>
                  <SingleSelect
                    data={userTypes?.filter(user => user?.id !== 'All')}
                    placeholder="Select role"
                    selectedId={item?.role}
                    onSelect={(value) => onAssignSelect(itemIndex, 'role', value)}
                    style={{ width: assignRoles.length > 1 ? '230px' : '260px' }}
                    error={item?.roleError}
                    direction={ assignRoles.length > 3 && itemIndex >= Math.round(assignRoles.length/2) ? 'up' : 'down' }
                  />
                </Styled.InputWrapDiv>

                <Styled.InputWrapDiv iserror={item?.memberError}>
                  <SingleSelect
                    data={membersList?.[item?.role] || []}
                    placeholder="Select team member"
                    selectedId={item?.id}
                    onSelect={(value) => onAssignSelect(itemIndex, 'id', value, membersList?.[item?.role])}
                    style={{ width: assignRoles.length > 1 ? '230px' : '260px' }}
                    error={item?.memberError}
                    direction={ assignRoles.length > 3 && itemIndex >= Math.round(assignRoles.length/2) ? 'up' : 'down' }
                  />
                </Styled.InputWrapDiv>
              </Styled.RowDiv>

              { assignRoles?.length > 1 ?
                <Styled.RemoveRoleDiv>
                  <ToolTip tooltip="Remove role">
                    <Styled.RemoveRoleButton onClick={() => onRemoveRole(itemIndex)}>
                      <img src="/delete-icon.svg" />
                    </Styled.RemoveRoleButton>
                  </ToolTip>
                </Styled.RemoveRoleDiv>
              : ''
              }

            </Styled.RowDiv>
          ))}

        </Styled.ColumnDiv>

        <Styled.AddButtonsDiv>
          <button onClick={onAddRole}>Add Role</button>
          <div>
            <button onClick={hideModal}>Cancel</button>
            <button onClick={validateAssign}>Assign</button>
          </div>
        </Styled.AddButtonsDiv>
      </Modal>

      <Heading
        heading="Projects"
        subHeading="This is where you can view the listing of all projects and assign projects"
       
      >
      <div style={{ display: 'flex' }}>
          <Loader loading={loading} />
          <PrimaryButton
            title="Export"
            style={{ marginLeft: '20px' }}
            onClick={() => getTotalExistingProject()}
          />
        </div>
      </Heading>

      <CommonStyled.FilterDiv>
        <SingleSelect
          data={projectStatus}
          placeholder="Project Status"
          selectedId={searchStatus}
          onSelect={(id) => {
            setFetching(true);
            setSearchStatus(id);
          }}
        />

        <SearchInput onInputChange={(e) => {
          setFetching(true);
          setSearchText(e.target.value)
        }}
          placeholder="Search with project name" />
      </CommonStyled.FilterDiv>

      {!allProjects?.length && !searchStatus && !searchText ?
        <>
          {!fetching ?
            <CommonStyled.NoDataFoundDiv>
              <img src="/no-project-added-icon.svg" alt="" />
              <div>No project added so far</div>
            </CommonStyled.NoDataFoundDiv>
            : ''
          }
        </>
        :
        <Table
          rows={allProjects}
          headings={headings}
          page={page}
          limit={limit}
          count={count}
          onPageChange={onPageChange}
          onLimitChange={(value) => setLimit(value)}
          noDataImage="/no-project-found-icon.svg"
          noDataText="No project found"
          loading={fetching}
        />
      }

    </Layout>
  )
}

export default withRouter(Projects);
