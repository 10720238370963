import React, { useState, useEffect, useRef } from "react";
import useOutsideClick from "../../Hooks/useOutsideClick";
import * as Styled from "./styled";

const TableDropDown = ({
  hiddenColumns = [],
  setHiddenColumns = () => { },
  data = []
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef(null);
  useOutsideClick({
    ref: dropdownRef,
    handler: () => setIsOpen(false),
  });

  const onCheck = (e, name) => {
    const isChecked = e.target.checked;

    if (isChecked && !hiddenColumns.includes(name) && hiddenColumns.length < (data.length - 4)) {
      setHiddenColumns([...hiddenColumns, name]);
    } else if (!isChecked && hiddenColumns.includes(name)) {
      setHiddenColumns(hiddenColumns.filter((e) => e !== name));
    }
  }

  return (
    <Styled.MainDiv ref={dropdownRef}>
      <Styled.SelectButton
        onClick={() => setIsOpen(!isOpen)}
        isopen={isOpen}
      />

      {data && data.length ?
        <Styled.DropdownDiv style={{ maxHeight: isOpen ? '242px' : '0px' }}>
          <Styled.ListDiv>
            {data.map((item) => (
              item.name !== 'Action' ?
                <Styled.CheckboxLabel ischecked={!hiddenColumns.includes(item.name)} key={`${item.name}_table_drop_down`}>
                  <input type="checkbox" onChange={(e) => onCheck(e, item.name)} />
                  <span />
                  <p>{item.name}</p>
                </Styled.CheckboxLabel>
                : ''
            ))}
          </Styled.ListDiv>
        </Styled.DropdownDiv>
        : ''
      }

    </Styled.MainDiv>
  )
}

export default TableDropDown;
