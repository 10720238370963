import { Link } from "react-router-dom";
import styled from "styled-components";

export const FilterDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 28px;
`;
export const NoDataFoundDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-grow: 1;
  padding: 15px;
  border: 1px solid #ADB8AE;
  border-radius: 6px;
  background-color: #ffffff;
  & > img {
    height: 80px;
    width: 80px;
  }
  & > div {
    font-size: 20px;
    font-weight: 700;
    margin: 16px 0px 28px 0px;
  }
`;
export const TableWrapDiv = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ADB8AE;
  border-radius: 6px;
  background-color: #ffffff;
  overflow: auto;
`;
export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  & th {
    height: 52px;
    background-color: #E1ECE2;
    font-weight: 600;
    padding: 4px 30px;
    text-align: left;
  }
  & td {
    height: 52px;
    border-bottom: 1px solid #ADB8AE;
    margin: 0px;
    padding: 4px 30px;
  }
`;
export const PaginationWrapDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
`;
export const EntriesDiv = styled.div`
  display: flex;
  align-items: center;
  & select {
    margin-left: 12px;
    height: 40px;
    border-radius: 6px;
    border: 1px solid #D0D5DD;
    padding: 0px 15px;
  }
`;
export const PaginationDiv = styled.div`
  display: flex;
  & button {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 16px;
    background-color: #ffffff;
    border: 1px solid #D0D5DD;
    font-size: 14px;
    border-right: none;
    & img {
      height: 20px;
      width: 20px;
    }
    &:hover {
      background-color: #F1F1F1;
    }
    &:last-child {
      border-right: 1px solid #D0D5DD;
    }
  }
`;
export const PrevButton = styled.button`
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  & img {
    margin-right: 8px;
  }
`;
export const NextButton = styled.button`
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  & img {
    margin-left: 8px;
  }
`;
export const PageButton = styled.button`

`;
export const VerificationWrapDiv = styled.div`
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  & img {
    height: 40px;
    width: 40px;
    margin-bottom: 22px;
  }
  & div:last-child {
    display: flex;
    align-items: center;
    & > button {
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      padding: 0px 28px;
      font-size: 16px;
      font-weight: 600;
    }
    & > button:first-child {
      color: #000000;
      border: 1px solid #000000;
      margin-right: 20px;
      background-color: #ffffff;
    }
    & > button:last-child {
      color: #ffffff;
      background-color: #DB1616;
      border: 1px solid #DB1616;
      padding: 0px 38px;
    }
  }
`;
export const VerifyModalHeading = styled.div`
  font-size: 28px;
  font-weight: 700;
  line-height: 38px;
  margin-bottom: 16px;
`;
export const VerifyModalSubheading = styled.div`
  font-size: 16px;
  margin-bottom: 32px;
  text-align: center;
`;
export const ImportWrapDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  padding: 64px 20px;
`;
export const ImportDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #000000;
`;
export const ImportLink = styled(Link)`
  height: 150px;
  width: 150px;
  border-radius: 8px;
  border: 1px solid #bfbfbf;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 18px;
  background-color: #ffffff;
`;