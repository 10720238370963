import axios from "axios";
import { toastError } from "./toast";

export const fetchClientData = async (token, method, url, body={}) => {
  if (!token || !method || !url) return;

  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  axiosRequestOptions["headers"]["Authorization"] = "Bearer " + token;
  if (method === 'get') {
    axiosRequestOptions["headers"]["body"] = JSON.stringify(body);
  } else if (method === 'post') {
    axiosRequestOptions["headers"]["data"] = body;
  }

  try {
    let response = null;
    if (method === 'get') {
      response = await axios.get(process.env.REACT_APP_API_URL + url, axiosRequestOptions);
    } else if (method === 'post') {
      response = await axios.post(process.env.REACT_APP_API_URL + url, body, axiosRequestOptions);
    }

    if (response && response.data) {
      return response.data;
    } else {
      throw Error(`Invalid Response`);
    }
  } catch (error) {
    toastError(error?.response?.data?.message || 'Error fetching data');
    if (error?.response?.status === 401) {
      return { error : 'unauthorize' };
    }
    
    return { error : error?.response };
  }
};