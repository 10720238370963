import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import JSZip from "jszip";
import axios from "axios";
import Heading from "../../Components/Heading";
import Layout from "../../Components/Layout";
import Tabs from "../../Components/Tabs";
import ProgressBar from "../../Components/ProgressBar";
import Loader from "../../Components/Loader";
import ToolTip from "../../Components/ToolTip";
import Modal from "../../Components/Modal";
import SingleSelect from "../../Components/SingleSelect";
import ProjectMilestones from "./ProjectMilestones";
import ProjectElements from "./ProjectElements";
import ProjectExecution from "./ProjectExecution";
import { TabLayout } from "./layout";
import { withRouter } from "../../Utilities/withRouter";
import { fetchData } from "../../Utilities/handleRequest";
import { toastError } from "../../Utilities/toast";
import { numberWithCommas } from "../../Utilities/utilsFunctions";
import * as Styled from "./styled";

const ProjectDetails = ({ params }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [showElementsModal, setShowElementsModal] = useState(false);
  const [downloadActive, setDownloadActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [projectData, setProjectData] = useState(null);
  const [calculation, setCalculation] = useState(null);
  const [showBoqModal, setShowBoqModal] = useState(false);
  const [allBoqs, setAllBoqs] = useState(null);
  const [selectedBoq, setSelectedBoq] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (params.id) {
      getProject(params.id);
    }
  }, [params]);

  const getProject = async () => {
    setLoading(true);
    let res = await fetchData('get', `projects/get-project/${params.id}`);
    if (res?.data) {
      setProjectData({ ...res?.data });
    }

    res = await fetchData('get', `payments/calculate-payment/${params.id}`);
    if (res?.data) {
      setCalculation({...res?.data});
    }
    setLoading(false);
  }

  const getAllPreviousBoqs = async () => {
    setLoading(true);
    let res = await fetchData('get', `project-boq/get-all-previous-boqs/${params.id}`)
    if (res?.data?.allBoqs?.length) {
      const createdArr = res.data.allBoqs.map((item) => item.created_at);
      createdArr.push(projectData.created_at);
      const allBoqsData = [{ id: 'LATEST' },  ...res.data.allBoqs].map((item, index) => ({ id: item.id, created_at: createdArr[index] }));
      const boqsData = [];
      for (let x = 0; x < allBoqsData.length; x++) {
        const boqCreatedAt = moment(createdArr[x]).format('DD MMM YYYY, hh:mm A');
        boqsData.push({ id: allBoqsData[x].id, name: x === 0 ?  `Latest (${boqCreatedAt})` : boqCreatedAt });
      }

      setAllBoqs(boqsData);
    } else {
      setAllBoqs([{ id: 'LATEST', name: `Latest (${moment(projectData.created_at).format('DD MMM YYYY, hh:mm A')})` }]);
    }
    setLoading(false);
  }

  const askForApproval = async () => {
    setLoading(true);
    let res = await fetchData('get', `projects/ask-for-approval/${params.id}`);
    if (res?.data) {
      getProject();
      toastError(res?.message);
    }
    setLoading(false);
  }

  const onTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
  }

  const getAdminRole = (role) => {
    if (!role) return '';
    let name = role.toLowerCase();
    name = name.split('_').join(' ');
    return name.charAt(0).toUpperCase() + name.slice(1);
  }

  const downloadBoq = async () => {
    if (!selectedBoq) {
      toastError('Please select boq to download!');
      return;
    }

    setLoading(true);
    try {
      let url = '';
      if (selectedBoq === 'LATEST') {
        url = `${process.env.REACT_APP_API_URL}project-boq/get-boq-pdf/${params.id}`;
      } else {
        url = `${process.env.REACT_APP_API_URL}project-boq/get-previous-boq/${selectedBoq}`;
      }
      let token = localStorage.getItem("jwtToken");
      const axiosRequestOptions = {
        "responseType": "blob",
        headers: {
          "Authorization": "Bearer " + token
        }
      }

      const res = await axios.get(url, axiosRequestOptions);
  
      if (res?.data) {
        const link = document.createElement('a');
        const boqBlob = new Blob([res.data], { type: "application/pdf" });
        link.href = window.URL.createObjectURL(boqBlob);
        link.download = `${projectData?.project_name?.replace(/[^\w\s]/gi, '')}_BOQ.pdf`;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        URL.revokeObjectURL(boqBlob);
        closeModalForBoq();
      }
    } catch (error) {
      toastError('Error while downloading BOQ!');
    }
    setLoading(false);
  }

  const downloadScreenshots = async () => {
    if (!projectData?.unity_metadata?.snapshot_urls?.length) {
      toastError('No rendered images found!');
      return;
    }

    setLoading(true);
    const images = [...projectData?.unity_metadata?.snapshot_urls];

    try {
      const zip = new JSZip();
      const folder = zip.folder(`${projectData?.project_name?.replace(/[^\w\s]/gi, '')}_Rendered Images`);

      for (let i = 0; i < images.length; i++) {
        const imageUrl = images[i];
        const fileNameArr = imageUrl.split('/');
        const fileName = fileNameArr[fileNameArr.length - 1];

        const response = await axios.get(imageUrl, { responseType: 'arraybuffer' });
        folder.file(fileName, response.data);
      }

      const zipContent = await zip.generateAsync({ type: 'blob' });

      const link = document.createElement('a');
      link.href = URL.createObjectURL(zipContent);
      link.download = `${projectData?.project_name?.replace(/[^\w\s]/gi, '')}_Rendered Images.zip`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(zipContent);
    } catch (error) {
      toastError('Error while generating ZIP file!');
    }

    setLoading(false);
  }

  const showModalForBoq = () => {
    getAllPreviousBoqs();
    setShowBoqModal(true);
  }

  const closeModalForBoq = () => {
    setShowBoqModal(false);
    setSelectedBoq('');
  }

  return (
    <Layout activePage="Projects" style={{ display: 'flex', flexDirection: 'column' }}>
      <Loader loading={loading} />

      <Modal
        show={showBoqModal}
        hide={closeModalForBoq}
        heading="Download BOQ"
        okText="Download"
        onOk={downloadBoq}
        contentStyle={{ overflow: 'visible' }}
        width="400px"
      >
        <Styled.LabelDiv>Select BOQ</Styled.LabelDiv>
        <SingleSelect
          placeholder={'Select BOQ'}
          onSelect={(value) => setSelectedBoq(value)}
          data={allBoqs}
          style={{ fontSize: '16px', width: '100%' }}
          selectedId={selectedBoq}
          height="42px"
          fontSize="16px"
        />
      </Modal>

      <Heading
        heading={projectData?.project_name}
        backTo={{ text: 'Back to all projects', url: '/projects' }}
      >
        { projectData?.approval_required ?
          <Styled.NotifyButton style={{ backgroundColor: '#bfbfbf' }}>
            <img src="/notify-icon.svg" />
            Waiting for approval
          </Styled.NotifyButton>
        :
          <Styled.NotifyButton onClick={askForApproval}>
            <img src="/notify-icon.svg" />
            Notify Customer
          </Styled.NotifyButton>
        }
      </Heading>

      <Styled.ContentWrapDiv>
        <Styled.RowDiv>
          <Styled.InfoDiv style={{ width: '40%' }}>
            <p>Customer details:</p>
            <p>Name: <strong>{projectData?.client_name}</strong></p>
            <p>Mobile no.: <strong>{projectData?.client_phone_number}</strong></p>
            <p>Email: <strong style={{ wordBreak: 'break-all' }}>{projectData?.client_email}</strong></p>
            <p>Address: <strong>{`${projectData?.client_address} ${projectData?.client_pincode || ''}`}</strong></p>
          </Styled.InfoDiv>

          <Styled.InfoDiv style={{ width: 'calc(60% - 28px)' }}>
            <Styled.RowDiv style={{ marginBottom: '4px' }}>
              <p style={{ margin: '0px' }}>Project  details:</p>
              <Styled.RowDiv style={{ alignItems: 'center' }}>
                <p style={{ margin: '0px' }}>Current status:</p>
                <Styled.StatusDiv>{projectData?.milestone_metadata?.status || 'Visit the site'}</Styled.StatusDiv>
              </Styled.RowDiv>
            </Styled.RowDiv>
            <p>Theme: <strong>{projectData?.theme_name || ''}</strong></p>
            <p>Bathroom size: <strong>{`L${projectData?.unity_metadata?.Dimension?.room_data?.length || ''} B${projectData?.unity_metadata?.Dimension?.room_data?.width || ''} H${projectData?.unity_metadata?.Dimension?.room_data?.height || ''}`}</strong></p>
            <Styled.ProjectValueDiv>
              <p>Project value: <strong>₹{numberWithCommas(calculation?.totalAmount || '')}</strong></p>
              <ToolTip
                tooltip={
                  `Product Cost : ₹${numberWithCommas(calculation?.productCost)}<br/>Execution Cost : ₹${numberWithCommas(calculation?.executionCost)}${calculation?.discountAmount ? `<br/>Discount : ₹${numberWithCommas(calculation?.discountAmount)}` : ''}`
                }
                wrap={true}
                tooltipStyle={{ width: 'max-content' }}
              >
                <Styled.ProjectValueInfoDiv>i</Styled.ProjectValueInfoDiv>
              </ToolTip>
            </Styled.ProjectValueDiv>
            
            <p>Project request initiated on: <strong>{moment(projectData?.created_at).format("DD MMM, YYYY - hh:mm A")}</strong></p>
            <ProgressBar
              layout="horizontal"
              progress={projectData?.milestone_metadata?.progress || 0}
              doneText="Done"
              style={{ marginTop: '12px' }}
            />
          </Styled.InfoDiv>
        </Styled.RowDiv>

        <Styled.DownloadWrapDiv>
          <Styled.DownloadHeadingDiv
            active={downloadActive ? 'true' : 'false'}
            onClick={() => setDownloadActive(!downloadActive)}
          >
            Download Assets
          </Styled.DownloadHeadingDiv>
          <Styled.DownloadCollapseDiv style={{ maxHeight: downloadActive ? '250px' : '0px' }}>
            <div>
              <div>Rendered images of the bathroom designed by the customer</div>
              <button onClick={downloadScreenshots}>Download Images</button>
            </div>
            <div>
              <div>BOQ of the bathroom designed by the customer</div>
              <button onClick={showModalForBoq}>Download BOQ</button>
            </div>
            {/* <div>
              <div>BOQ of the bathroom designed by the customer</div>
              <button onClick={() => navigate(`/projects/boq/${params.id}`)}>View BOQ</button>
            </div> */}
          </Styled.DownloadCollapseDiv>
        </Styled.DownloadWrapDiv>

        {projectData?.admins?.length ?
          <Styled.NameWrapDiv>
            {projectData?.admins.map((admin) => (
              <p>{`${getAdminRole(admin.role)}: `}<strong>{admin.name}</strong></p>
            ))}
          </Styled.NameWrapDiv>
          : ''
        }

        <Tabs
          tabs={TabLayout}
          activeTab={activeTab}
          setActiveTab={onTabChange}
          rightElement={activeTab === 1 ?
            <Styled.AddElementButton onClick={() => setShowElementsModal(true)}>
              <div />
              Add Element
            </Styled.AddElementButton>
            : null
          }
        >
          <Styled.TabContentDiv style={activeTab === 0 ? { display: 'flex', paddingBottom: '14px' } : { display: 'none' }}>
            <ProjectMilestones
              params={params}
              milestoneMetadata={projectData?.milestone_metadata || {}}
              createdAt={projectData?.created_at}
              getProject={getProject}
              setLoading={setLoading}
              projectName={projectData?.project_name || ''}
            />
          </Styled.TabContentDiv>

          <Styled.TabContentDiv style={{ display: activeTab === 1 ? 'flex' : 'none' }}>
            <ProjectElements
              params={params}
              projectElements={calculation?.productCostData || []}
              showElementsModal={showElementsModal}
              hideElementsModal={() => setShowElementsModal(false)}
              setLoading={setLoading}
              calculation={calculation}
              getProject={getProject}
            />
          </Styled.TabContentDiv>

          <Styled.TabContentDiv style={{ display: activeTab === 2 ? 'flex' : 'none' }}>
            <ProjectExecution
              params={params}
              dataExecution={projectData?.execution_metadata || {}}
              unityMetadata={projectData?.unity_metadata || {}}
              getProject={getProject}
              setLoading={setLoading}
              calculation={calculation}
              setCalculation={setCalculation}
            />
          </Styled.TabContentDiv>
        </Tabs>
      </Styled.ContentWrapDiv>

    </Layout>
  )
}

export default withRouter(ProjectDetails);