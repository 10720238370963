import { useEffect, useState } from "react";
import Heading from "../../Components/Heading";
import Layout from "../../Components/Layout";
import Input from "../../Components/Input";
import InputDate from "../../Components/InputDate";
import Textarea from "../../Components/Textarea";
import RadioButton from "../../Components/RadioButton";
import SingleSelect from "../../Components/SingleSelect";
import InputGroup from "../../Components/InputGroup";
import PrimaryButton from "../../Components/PrimaryButton";
import SecondaryButton from "../../Components/SecondaryButton";
import Loader from "../../Components/Loader";
import { couponTypes } from "../../StaticData/databases";
import { withRouter } from "../../Utilities/withRouter";
import { fetchData } from "../../Utilities/handleRequest";
import CouponCategorySelect from "./CouponCategorySelect";
import * as Styled from "./styled";
import { toastError } from "../../Utilities/toast";

const EditDiscountCoupon = ({ params, location, navigate }) => {
  const mode = location?.pathname?.split('/')[1] === 'add-discount-coupon' ? 'add' : 'edit';
  const [couponData, setCouponData] = useState({
    title: '',
    code: '',
    description: '',
    category_id: '',
    type: '',
    value: '',
    apply_condition: false,
    execution_cost: {
      amount: ''
    },
    coupon_limit: false,
    limit: '',
    remaining: 0,
    coupon_expiry: false,
    expiry_date: ''
  });
  const [couponError, setCouponError] = useState({});
  const [allCategories, setAllCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (params?.id) {
      getCoupon(params.id);
    }
    getAllCategories();
  }, []);

  const getAllCategories = async () => {
    let res = await fetchData('get', 'coupon/get-all-category');
    setAllCategories(res?.data?.coupon || []);
  }

  const getCoupon = async (id) => {
    let res = await fetchData('get', `coupon/get-coupon/${id}`);
    if (res?.data) {
      const data = {...couponData};
      const apiData = res.data;

      data.title = apiData?.title || '';
      data.code = apiData?.code || '';
      data.description = apiData?.description || '';
      data.category_id = apiData?.category_id || '';
      data.type = apiData?.type || '';
      data.value = apiData?.value || '';
      data.apply_condition = apiData?.apply_condition || false;
      data.execution_cost.amount = apiData?.execution_cost?.amount || '';
      data.coupon_limit = apiData?.coupon_limit || false;
      data.limit = apiData?.limit || '';
      data.remaining = apiData?.remaining || 0;
      data.coupon_expiry = apiData?.coupon_expiry || false;

      if (apiData.expiry_date) {
        data.expiry_date = apiData.expiry_date.split('.')[0];
      }
      setCouponData(data);
    }
  }

  const getInput = (id, placeholder) => {
    return <Input
      id={id}
      value={couponData?.[id]}
      onChange={(e) => onInputChange(e)}
      placeholder={placeholder}
      error={couponError[id]}
      type={id === 'title' ? 'noSymbol' : null}
    />
  }

  const onInputChange = (e) => {
    if (!e || !e.target) return;
    const { id, value } = e.target;
    const data = { ...couponData };

    if (id === 'execution_amount') {
      if (!data.execution_cost) {
        data.execution_cost = {};
      }
      data.execution_cost.amount = value;
    } else {
      data[id] = value;
    }
    setCouponData(data);
    removeFromError(id);
  }

  const removeFromError = (id) => {
    if (!couponError?.[id]) return;
    const errorData = { ...couponError };
    delete errorData[id];
    setCouponError(errorData);
  }

  const onSingleSelect = (id, value) => {
    const data = { ...couponData };
    data[id] = value;
    
    setCouponData(data);
    removeFromError(id);
  }

  const onCategorySelect = (value) => {
    setCouponData({...couponData, category_id: value});
    removeFromError('category_id');
  }

  const isDataValid = () => {
    const errorData = {};

    if (!couponData?.title || !couponData?.title?.trim()) {
      errorData.title = 'Enter a title';
    }
    if (!couponData?.code || !couponData?.code?.trim()) {
      errorData.code = 'Enter coupon code';
    }
    if (!couponData.category_id) {
      errorData.category_id = 'Select or add category';
    }
    if (!couponData.type) {
      errorData.type = 'Select coupon type';
    }
    if (!couponData?.value) {
      errorData.value = 'Enter coupon value';
    }
    if (couponData.apply_condition) {
      if (!couponData?.execution_cost?.amount) {
        errorData.execution_amount = 'Enter execution amount';
      }
    }
    if (couponData.coupon_limit && !couponData?.limit) {
      errorData.limit = 'Enter usage limit';
    }
    if (couponData.coupon_limit && couponData?.limit < couponData?.remaining) {
      errorData.remaining = 'Remaining usage must be less then limit';
    }
    if (couponData.coupon_expiry && !couponData.expiry_date) {
      errorData.expiry_date = 'Enter coupon expiry date time';
    }

    setCouponError(errorData);

    if (Object.keys(errorData).length === 0) {
      return true;
    }
    return false;
  }

  const addUpdateCoupon = async () => {
    if (!isDataValid()) return;
    if (mode === 'edit' && !params?.id) return;

    setLoading(true);

    const data = {...couponData};
    if (!data?.apply_condition) {
      delete data.execution_cost;
    }
    if (!data?.coupon_limit) {
      delete data.limit;
    }
    if (!data?.coupon_expiry) {
      delete data.expiry_date;
    }

    let url = 'coupon/add-coupon';
    let toast = 'The coupon has been added successfully!';

    if (mode === 'edit' && params?.id) {
      url = `coupon/update-coupon/${params.id}`;
      toast = 'The coupon has been updated successfully!';
    }

    let res = await fetchData('post', url, data);
    if (res?.data) {
      toastError(toast);
      navigate('/discount-coupon');
    } else {
      toastError('Something went wrong!');
    }
    setLoading(false);
  }

  return (
    <Layout activePage="Discount Coupon" style={{ display: 'flex', flexDirection: 'column' }}>
      <Loader loading={loading} />

      <Heading
        heading={mode === 'add' ? 'Add New Discount Coupon' : 'Update Discount Coupon'}
        subHeading={mode === 'add' ? 'This is where you can add new discount coupon.' : 'This is where you can update discount coupon.'}
        backTo={{ text: 'Back to all coupons', url: '/discount-coupon' }}
      />

      <Styled.CardDiv>
        <Styled.CardContentDiv>
          <Styled.RowDiv style={{ justifyContent: 'space-between' }}>
            <Styled.ColumnDiv style={{ width: '48%' }}>
              <Styled.TitleDiv notEmpty>Title</Styled.TitleDiv>
              {getInput('title', 'Enter coupon title')}
            </Styled.ColumnDiv>

            <Styled.ColumnDiv style={{ width: '48%' }}>
              <Styled.TitleDiv notEmpty>Coupon Code</Styled.TitleDiv>
              {getInput('code', 'Enter coupon code')}
            </Styled.ColumnDiv>
          </Styled.RowDiv>

          <Styled.TitleDiv>Description</Styled.TitleDiv>
          <Textarea
            id="description"
            value={couponData.description}
            onChange={(e) => onInputChange(e)}
            style={{ height: '117px', padding: '12px 20px' }}
            placeholder="Enter description here"
            error={couponError.description}
          />

          <Styled.RowDiv style={{ justifyContent: 'space-between' }}>
            <Styled.ColumnDiv style={{ width: '48%', flexShrink: '0' }}>
              <Styled.TitleDiv notEmpty>Category</Styled.TitleDiv>
              <CouponCategorySelect
                placeholder="Select category"
                options={allCategories}
                selected={couponData.category_id}
                onSelect={(value) => onCategorySelect(value)}
                error={couponError.category_id}
                updateList={getAllCategories}
              />

              <Styled.TitleDiv notEmpty>Coupon Apply Condition</Styled.TitleDiv>
              <RadioButton
                name="apply_condition"
                style={{ width: '48%', margin: '10px 0px' }}
                onChange={(e) => setCouponData({ ...couponData, apply_condition: e.target.value === 'true' ? true : false })}
                value={couponData.apply_condition === true ? 'true' : 'false'}
                options={[
                  { id: 'false', name: 'Applicable to all' },
                  { id: 'true', name: 'Follow the condition' }
                ]}
              />

              {couponData.apply_condition ?
                <Styled.ColumnDiv>
                  <Styled.TitleDiv notEmpty>If Execution Cost is Greater than Equals to</Styled.TitleDiv>
                  <Styled.RowDiv style={{ gap: '20px' }}>
                    <InputGroup
                      placeholder="Enter value"
                      groupValue="₹"
                      style={{ width: '100%' }}
                      onChange={onInputChange}
                      value={couponData?.execution_cost?.amount}
                      type="number"
                      id="execution_amount"
                      error={couponError?.execution_amount}
                    />
                  </Styled.RowDiv>
                </Styled.ColumnDiv>
                : ''
              }

              <Styled.TitleDiv notEmpty>Coupon Usage Limit</Styled.TitleDiv>
              <RadioButton
                name="coupon_limit"
                style={{ width: '48%', margin: '10px 0px' }}
                onChange={(e) => setCouponData({ ...couponData, coupon_limit: e.target.value === 'true' ? true : false })}
                value={couponData.coupon_limit === true ? 'true' : 'false'}
                options={[
                  { id: 'false', name: 'No limit' },
                  { id: 'true', name: 'Set the limit' }
                ]}
              />

              {couponData.coupon_limit ?
                <Styled.ColumnDiv>
                  <Styled.TitleDiv notEmpty>No. of Usage of This Coupon Code</Styled.TitleDiv>
                  <InputGroup
                    groupValue="Times"
                    groupAtLast={true}
                    type="number"
                    onChange={onInputChange}
                    id="limit"
                    value={couponData?.limit}
                    error={couponError?.limit}
                  />
                </Styled.ColumnDiv>
                : ''
              }

              {couponData.coupon_limit ?
                <Styled.ColumnDiv>
                  <Styled.TitleDiv notEmpty>No. of Remaining Usage of This Coupon Code</Styled.TitleDiv>
                  <InputGroup
                    groupValue="Times"
                    groupAtLast={true}
                    type="number"
                    onChange={onInputChange}
                    id="remaining"
                    value={couponData?.remaining}
                    error={couponError?.remaining}
                  />
                </Styled.ColumnDiv>
                : ''
              }

              <Styled.TitleDiv notEmpty>Coupon Expiry</Styled.TitleDiv>
              <RadioButton
                name="coupon_expiry"
                style={{ width: '48%', margin: '10px 0px' }}
                onChange={(e) => setCouponData({ ...couponData, coupon_expiry: e.target.value === 'true' ? true : false })}
                value={couponData.coupon_expiry === true ? 'true' : 'false'}
                options={[
                  { id: 'false', name: 'No expiry' },
                  { id: 'true', name: 'Set the expiry date' }
                ]}
              />

              {couponData.coupon_expiry ?
                <Styled.ColumnDiv>
                  <Styled.TitleDiv notEmpty>Set Expiry Date</Styled.TitleDiv>
                  <InputDate
                    type="datetime-local"
                    id="expiry_date"
                    onChange={onInputChange}
                    value={couponData?.expiry_date}
                    error={couponError?.expiry_date}
                    style={{ width: '100%' }}
                  />
                </Styled.ColumnDiv>
                : ''
              }
            </Styled.ColumnDiv>

            <Styled.ColumnDiv style={{ width: '48%', flexShrink: '0' }}>

              <Styled.TitleDiv notEmpty>Type</Styled.TitleDiv>
              <Styled.RowDiv style={{ gap: '20px' }}>
                <SingleSelect
                  placeholder="Select type"
                  onSelect={(value) => onSingleSelect('type', value)}
                  data={couponTypes}
                  style={{ width: '100%' }}
                  selectedId={couponData?.type}
                  error={couponError?.type}
                />

                <InputGroup
                  placeholder="Enter value"
                  groupValue={couponData?.type === 'percentage' ? '%' : '₹'}
                  style={{ width: '100%' }}
                  type="number"
                  id="value"
                  onChange={onInputChange}
                  value={couponData?.value}
                  error={couponError?.value}
                />
              </Styled.RowDiv>

              <Styled.TextDiv>Flat off on the execution cost</Styled.TextDiv>
            </Styled.ColumnDiv>
          </Styled.RowDiv>

        </Styled.CardContentDiv>

        <Styled.CardFooterDiv>
          <SecondaryButton title="Cancel" onClick={() => navigate('/discount-coupon')} />
          <PrimaryButton title={mode === 'add' ? 'Add Coupon' : 'Update Coupon'} style={{ borderRadius: '8px', marginLeft: '20px' }} onClick={addUpdateCoupon} />
        </Styled.CardFooterDiv>
      </Styled.CardDiv>

    </Layout>
  )
}

export default withRouter(EditDiscountCoupon);
