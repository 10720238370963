import * as Styled from "./styled";

const Input = ({ error='', id="", value='', onChange=()=>{}, placeholder="", type="", style={}, disabled=false }) => {

  const onInputChange = (e) => {
    const {value} = e.target;
    const format = /^[a-zA-Z0-9 ]+$/i;


    if (!type || value === '' || (type === 'number' && /^\d+$/.test(value) && value !== '0') || (type === 'noSymbol' && format.test(value))) {
      onChange(e);
    }
  }

  return (
    <Styled.InputWrapDiv style={style}>
      <input
        id={id}
        value={value}
        onChange={onInputChange}
        placeholder={placeholder}
        style={{ border: error ? '1px solid #DB1616' : '1px solid #bfbfbf' }}
        disabled={disabled}
      />

      { error ?
        <Styled.ErrorDiv>{error}</Styled.ErrorDiv>
      : ''
      }
    </Styled.InputWrapDiv>
  )
}

export default Input;
