import styled from "styled-components";

export const WrapDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
export const MainLabel = styled.label`
  position: relative;
  display: flex;
  cursor: pointer;
  & input {
    display: none;
    opacity: 0;
    width: 0;
    height: 0;
    &:checked ~ span {
      border: 1px solid #118D8B;
      &:before {
        background-color: #118D8B;
      }
    }
    &:focus ~ span {
      box-shadow: 0 0 1px #118D8B;
    }
  }
  & span {
    height: 24px;
    width: 24px;
    border-radius: 12px;
    border: 1px solid #000000;
    background-color: #ffffff;
    transition: .2s;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    &:before {
      content: "";
      height: 16px;
      width: 16px;
      border-radius: 8px;
      background-color: #ffffff;
      transition: .2s;
    }
  }
  & p {
    margin: 2px 0px 0px 20px;
    font-size: inherit !important;
  }
`;
