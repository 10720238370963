import { useEffect, useState } from "react";
import * as Styled from "./styled";

const ToggleSwitch = ({ height, width, style, onChange=()=>{}, onClick=()=>{}, value }) => {
  const [isChecked, setIsChecked] = useState(value || false);

  useEffect(() => {
    setIsChecked(value || false);
  }, [value]);

  const onToggle = (e) => {
    onChange(e.target.checked);
  }

  return (
    <Styled.MainLabel height={height} width={width} style={style} onClick={onClick}>
      <input type="checkbox" onChange={(e) => onToggle(e)} checked={isChecked} />
      <span />
    </Styled.MainLabel>
  )
}

export default ToggleSwitch;
