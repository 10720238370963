import styled from "styled-components";

export const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border: 1px solid #ADB8AE;
  border-radius: 8px;
  background-color: #ffffff;
`;
export const TableWrap = styled.div`
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-bottom: none;
  border-radius: 8px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
`;
export const MainTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  & th {
    height: 52px;
    background-color: #EDEDED;
    font-weight: 700;
    padding: 4px 30px;
    text-align: left;
    &:first-child {
      border-top-left-radius: 8px;
    }
    &:last-child {
      border-top-right-radius: 8px;
    }
  }
  & td {
    height: 52px;
    border-bottom: 1px solid #ADB8AE;
    margin: 0px;
    padding: 4px 30px;
  }
`;
export const SortByButton = styled.button`
  display: flex;
  align-items: center;
  position: relative;
  padding: 0px 14px 0px 0px;
  font-weight: 700;
  white-space: nowrap;

  &::before {
    content: '';
    height: 0px;
    width: 0px;
    position: absolute;
    right: 0px;
    margin-bottom: 8px;
    border-bottom: 5px solid ${({ sortBy, sortType }) => sortBy && sortType === 'asc' ? '#118D8B' : '#000000'};
    border-top: 0px solid transparent;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
  &::after {
    content: '';
    height: 0px;
    width: 0px;
    position: absolute;
    right: 0px;
    margin-top: 8px;
    border-top: 5px solid ${({ sortBy, sortType }) => sortBy && sortType === 'desc' ? '#118D8B' : '#000000'};
    border-bottom: 0px solid transparent;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 5px;
  }
`;
export const ActionsWrapDiv = styled.div`
  display: flex;
  align-items: center;
`;
export const ActionButton = styled.button`
  height: 36px;
  width: 36px;
  padding: 8px;
  border-radius: 3px;
  background: none;
  &:hover {
    background-color: #f1f1f1;
  }
  & img {
    width: 20px;
    height: 20px;
  }
`;
export const PaginationWrapDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
`;
export const EntriesDiv = styled.div`
  display: flex;
  align-items: center;
`;
export const NoDataDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 15px;
  & > img {
    height: 80px;
    width: 80px;
  }
  & > div {
    font-size: 20px;
    font-weight: 700;
    margin: 16px 0px 0px 0px;
  }
`;
export const LoadingDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;
export const LoaderDiv = styled.div`
  border: 6px solid #118D8B;
  border-radius: 50%;
  border-top: 6px solid #f3f3f3;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
  margin-bottom: 12px;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
export const TableDropdownDiv = styled.div`
  display: flex;
  align-items: center;
`;
export const SingleLineP = styled.p`
  max-width: 200px;
  margin: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;
export const StatusDiv = styled.div`
  background-color: ${({ status }) => status === 'captured' ? '#C4DEDE' : '#DB1616'};
  color: ${({ status }) => status === 'captured' ? '#000000' : '#ffffff'};
  font-size: 14px;
  white-space: nowrap;
  padding: 10px;
  border-radius: 20px;
  text-align: center;
`;
