import React, { useEffect, useState } from "react";
import moment from "moment";
import ToggleSwitch from "../ToggleSwitch";
import Pagination from "../Pagination";
import * as Styled from "./styled";
import SingleSelect from "../SingleSelect";
import ToolTip from "../ToolTip";
import StarRating from "../Rating";

const Table = ({
  rows = [],
  headings = [],
  count = 0,
  limit = 10,
  page = 1,
  onPageChange = () => { },
  onLimitChange = () => { },
  noDataText = "",
  noDataImage = "",
  loading = false
}) => {
  const [sortBy, setSortBy] = useState();
  const [sortType, setSortType] = useState('asc');
  const entries = [
    { id: 10, name: '10' },
    { id: 15, name: '15' },
    { id: 20, name: '20' },
    { id: 25, name: '25' },
    { id: 50, name: '50' }
  ];

  const getTd = (row, item, itemIndex) => {
    if (!item) return <td />;
    if (item.type === 'action') {
      if (!item.actions) return <td />;
      return (
        <td key={`${itemIndex}_${row[item.key]}`}>
          <Styled.ActionsWrapDiv>
            {item.actions.edit ?
              <ToolTip tooltip={item.actions.edit.toolText || ''}>
                <Styled.ActionButton onClick={() => item.actions.edit.onClick(row)}>
                  <img src="/edit-icon.svg" />
                </Styled.ActionButton>
              </ToolTip>
              : ''
            }
            {item.actions.delete ?
              <ToolTip tooltip={item.actions.delete.toolText || ''}>
                <Styled.ActionButton onClick={() => item.actions.delete.onClick(row)}>
                  <img src="/delete-icon.svg" />
                </Styled.ActionButton>
              </ToolTip>
              : ''
            }
            {item.actions.toggle ?
              <ToolTip tooltip={item.actions.toggle.toolText(row[item.actions.toggle.key])}>
                <ToggleSwitch
                  height={20}
                  width={33}
                  style={{ marginLeft: '4px' }}
                  onChange={(value) => item.actions.toggle.onChange(row, value)}
                  value={!row[item.actions.toggle.key]}
                />
              </ToolTip>
              : ''
            }
            {item.actions.view ?
              <ToolTip tooltip={item.actions.view.toolText || ''}>
                <Styled.ActionButton onClick={() => item.actions.view.onClick(row)} imageContent={item.actions.view.icon} imageContentHover={item.actions.view.activeIcon}>
                  <img alt="<>" />
                </Styled.ActionButton>
              </ToolTip>
              : ''
            }
          </Styled.ActionsWrapDiv>
        </td>
      )
    } else if (item.type === 'wrapText') {
      return <td style={{ whiteSpace: 'normal' }} key={`${itemIndex}_${row[item.key]}`}>{row[item.key]}</td>
    } else if (item.type === 'email') {
      return <td style={{ wordBreak: 'break-all', whiteSpace: 'normal' }} key={`${itemIndex}_${row[item.key]}`}>{row[item.key]}</td>
    } else if (item.type === 'size') {
      return <td style={{ whiteSpace: 'nowrap' }} key={`${itemIndex}_${row[item.key]}`}>
        {row[item.key]['length'] && row[item.key]['width'] ? `${row[item.key]['length']} X ${row[item.key]['width']}` : ''}
      </td>
    } else if (item.type === 'id' && item.from) {
      const itemArr = row[item.key];
      let itemName = '';
      for (let x = 0; x < itemArr.length; x++) {
        const idItem = item.from.filter((a) => a.id == itemArr[x])[0];
        if (idItem) {
          itemName += `${itemName !== '' ? ', ' : ''} ${idItem.name}`;
        }
      }
      return <td key={`${itemIndex}_${row[item.key]}`}>{itemName}</td>
    } else if (item.type === 'date') {
      return <td key={`${itemIndex}_${row[item.key]}`} style={{ whiteSpace: 'normal' }}>{row[item.key] ? moment(row[item.key]).format("DD MMM YYYY, hh:mm A") : '-'}</td>
    } else if (row[item.key] && item.key === 'rating') {
      return <td key={`${itemIndex}_${row[item.key]}`}>{<StarRating rating={row[item.key]} />}</td>
    } else if (row[item.key] && item.key === 'role') {
      return <td key={`${itemIndex}_${row[item.key]}`}>{row[item.key]?.charAt(0)?.toUpperCase() + row[item.key]?.slice(1)?.replaceAll("_", " ")?.toLowerCase()}</td>
    } else if (row[item.key] && item.key === 'address') {
      return <td key={`${itemIndex}_${row[item.key]}`}>
        <ToolTip tooltip={row[item.key]} wrap={true}>
          <Styled.SingleLineP>{row[item.key]?.slice(0, 25) + (row[item.key]?.length > 25 ? '...' : '')}</Styled.SingleLineP>
        </ToolTip>
      </td>
    } else {
      return <td key={`${itemIndex}_${row[item.key]}`}>{item.name === 'Ticket ID' ? `#${row[item.key]}` : row[item.key]}</td>
    }
  }

  const onSorting = (item) => {
    if (!rows || !rows.length) return;
    if (sortBy === item.key) {
      setSortType(() => (sortType === 'desc' ? 'asc' : 'desc'));
    } else {
      setSortBy(item.key);
    }
  }

  let sortedRows = rows || [];

  if (sortBy) {
    if (sortType === 'asc') {
      sortedRows = rows.sort((a, b) => (a[sortBy] > b[sortBy] || a[sortBy] === null) ? 1 : ((b[sortBy] > a[sortBy] || b[sortBy] === null) ? -1 : 0));
    } else if (sortType === 'desc') {
      sortedRows = rows.sort((a, b) => (a[sortBy] < b[sortBy] || b[sortBy] === null) ? 1 : ((b[sortBy] < a[sortBy] || a[sortBy] === null) ? -1 : 0));
    }
  }

  return (
    <Styled.MainDiv>

      <Styled.TableWrap>
        <Styled.MainTable>
          {headings && headings.length ?
            <thead>
              <tr>
                {headings.map((item, itemIndex) => (
                  item.sortBy ?
                    <Styled.Th key={`${itemIndex}_${item.name}`}>
                      <Styled.SortByButton
                        onClick={() => onSorting(item)}
                        sortBy={sortBy === item.key}
                        sortType={sortType}
                      >
                        {item.name}
                      </Styled.SortByButton>
                    </Styled.Th>
                    :
                    <th key={`${itemIndex}_${item.name}`}>{item.name}</th>
                ))}
              </tr>
            </thead>
            : ''
          }

          <tbody>
            {!loading && sortedRows.length ?
              sortedRows.map((row, rowIndex) => (
                <tr key={`tbody_tr_${rowIndex}`}>
                  {headings.map((item, itemIndex) => getTd(row, item, itemIndex))}
                </tr>
              ))
              : ''
            }
          </tbody>
        </Styled.MainTable>

        {loading ?
          <Styled.LoadingDiv>
            <Styled.LoaderDiv />
            <div>Loading...</div>
          </Styled.LoadingDiv>
          : ''
        }

        {!loading && !sortedRows.length ?
          <Styled.NoDataDiv>
            <img src={noDataImage} />
            <div>{noDataText}</div>
          </Styled.NoDataDiv>
          : ''}
      </Styled.TableWrap>

      {count > 10 ?
        <Styled.PaginationWrapDiv>
          <Styled.EntriesDiv>
            Show
            <SingleSelect
              placeholder="Show"
              onSelect={onLimitChange}
              data={entries}
              style={{ width: '71px', marginLeft: '12px' }}
              height="40px"
              selectedId={limit}
              direction="up"
            />
          </Styled.EntriesDiv>

          <Pagination
            activePage={page}
            totalPages={Math.ceil((count || 0) / (limit || 0))}
            onPageChange={onPageChange}
          />
        </Styled.PaginationWrapDiv>
        : ''
      }

    </Styled.MainDiv>
  )
}

export default Table;
