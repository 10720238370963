import styled from "styled-components";

export const ChatOuterDiv = styled.div`
  width: 100%;
  height: calc(100% - 118px);
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #bfbfbf;
  padding: 0px 20px 24px 20px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;
export const ChatWrapDiv = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  overflow: auto;
  padding-top: 24px;
  &::-webkit-scrollbar {
  width: 0px;
  height: 8px;
}
`;
export const SentDiv = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: #E2F1F1;
  margin-bottom: 12px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  align-self: flex-end;
`;
export const ReceiveDiv = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: #EDF6FE;
  margin-bottom: 12px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
`;
export const ReceiveImageDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 12px;
`;
export const ReceivedImg = styled.img`
  max-height: 220px;
  max-width: 220px;
  border-radius: 4px;
  margin-bottom: 8px;
  cursor: pointer;
`;
export const ReceivedIcon = styled.img`
  max-height: 120px;
  max-width: 120px;
  border-radius: 4px;
  margin-bottom: 8px;
  border: 1px solid #bfbfbf;
  cursor: pointer;
`;
export const NameDiv = styled.div`
  font-size: 14px;
  color: #000000;
  font-weight: 700;
`;
export const MessageDiv = styled.div`
  font-size: 14px;
  margin: 7px 0px 10px 0px;
  white-space: pre-line;
`;
export const TimeDiv = styled.div`
  font-size: 14px;
  opacity: 0.5;
`;
export const ChatInputDiv = styled.form`
  width: 100%;
  height: 130px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #DBEEEE;
  padding: 16px 20px;
  box-sizing: border-box;
  position: relative;
  & textarea {
    font-size: 12px;
    border: none;
    background: none;
    padding: 0px;
    &:focus {
      outline: none;
      border: none;
    }
  }
  & button {
    height: 24px;
    width: 24px;
    background: none;
    padding: 0px;
    margin: 0px;
    right: 16px;
    bottom: 12px;
    position: absolute;
  }
`;
export const ModalImg = styled.img`
  max-height: 70vh;
  max-width: 70vw;
`;
export const TicketClosedDiv = styled.div`
  background-color: #e6e6e6;
  padding: 12px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
`;