import { useEffect, useState } from "react";
import PrimaryButton from "../../../../Components/PrimaryButton";
import Checkbox from "../../../../Components/Checkbox";
import InputDate from "../../../../Components/InputDate";
import MeasurementModal from "../MeasurementModal";
import ChecklistModal from "../ChecklistModal";
import UploadFile from "../UploadFile";
import SubCollapse from "../SubCollapse";
import { uploadMedia } from "../../../../Utilities/handleRequest";
import { getUserRole } from "../../../../Utilities/localStorageHandler";
import { MilestoneLayout } from "../../layout";
import * as Styled from "./styled";
import moment from "moment";

const Collapse = ({
  active = false,
  setActive = () => { },
  collapseIndex,
  milestoneCollapseData = {},
  onPaymentRequest,
  saveCollapse,
  onSaveAndNext,
  setLoading
}) => {
  const [collapseData, setCollapseData] = useState({ ...milestoneCollapseData });
  const [layout, setLayout] = useState(MilestoneLayout(collapseData)[collapseIndex]);
  const [activeSubCollapse, setActiveSubCollapse] = useState('');
  const [activeModal, setActiveModal] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setLayout(MilestoneLayout(collapseData)[collapseIndex]);
  }, [collapseData]);

  useEffect(() => {
    setCollapseData({...milestoneCollapseData});
    const role = getUserRole();
    if (role === 'ADMIN' ||
      (collapseIndex < 5 && (role === 'DESIGNER_LEAD' || role === 'DESIGNER')) ||
      ((collapseIndex === 1 || collapseIndex > 4) && (role === 'PROJECT_MANAGER' || role === 'SUPERVISOR' || role === 'OPERATIONS_HEAD' || role === 'ZONAL_OPERATIONS_HEAD'))) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true)
    }
  }, [milestoneCollapseData]);

  const onChecklistClose = (listName) => {
    setActiveModal(null);
    const data = { ...collapseData || {} };
    data[listName] = milestoneCollapseData[listName] ? [...milestoneCollapseData[listName]] : [];
    setCollapseData(data);
  }

  const onChecklistChange = (listName, index, value) => {
    const data = { ...collapseData || {} };
    data[listName] = collapseData[listName] ? [...collapseData[listName]] : [];
    data[listName][index] = value;
    setCollapseData(data);
  }

  const onChecklistSave = async () => {
    const data = { ...collapseData };

    const files = [];
    if (data?.files) {
      for (let x = 0; x < data.files.length; x++) {
        if (typeof data.files[x] === 'string') {
          files.push(data.files[x]);
        } else {
          setLoading(true);
          const imageUrl = await uploadMedia(data.files[x]);
          files.push(imageUrl);
        }
      }
    }
    data.files = files;

    const dataSaved = await saveCollapse(layout.name, data);
    if (dataSaved) {
      setActiveModal(null);
    }
  }

  const onChangeFiles = (value) => {
    const data = { ...collapseData || {} };
    data.files = value;
    setCollapseData(data);
  }

  const isListCompleted = (listName, listLength) => {
    if (listName === 'measurementDetails') {
      const list = collapseData?.[listName] || [];
      let completeMeasurement = 0;

      for (let x = 1; x <= 10; x++) {
        if (collapseData?.[`measurement_checklist_${x}`]?.progress === 100) {
          completeMeasurement += 10;
        }
      }

      if (completeMeasurement === 100) {
        return true;
      }

    } else {
      const list = collapseData?.[listName] || [];
      if (list.length === listLength && !list.includes(false)) {
        return true;
      }
    }
    return false;
  }

  const onCollapseClose = () => {
    setActive();
    setActiveSubCollapse('');
    setCollapseData({ ...milestoneCollapseData });
  }

  const onSubCollapseSave = async (subCollapseName, subCollapseData) => {
    const data = { ...milestoneCollapseData };
    data[subCollapseName] = { ...subCollapseData };

    const dataSaved = await saveCollapse(layout.name, data);
    if (dataSaved) {
      setActiveSubCollapse(activeSubCollapse + 1);
    }
  }

  const onMeasurementSave = async (mData, callBack) => {
    const data = { ...mData };

    const files = [];
    if (data?.files) {
      for (let x = 0; x < data.files.length; x++) {
        if (typeof data.files[x] === 'string') {
          files.push(data.files[x]);
        } else {
          setLoading(true);
          const imageUrl = await uploadMedia(data.files[x]);
          files.push(imageUrl);
        }
      }
    }
    data.files = files;
    
    const dataSaved = await saveCollapse(layout.name, data);
    if (dataSaved) {
      callBack();
    }
  }

  const onDateChange = async (id, value) => {
    const data = { ...collapseData, [id]: value };
    setCollapseData(data);
    if (id === 'start_date' || id === 'end_date') {
      await saveCollapse(layout.name, data);
    }
  }

  const onCollapseSave = async () => {
    setLoading(true);
    const data = {...collapseData};
    const files = [];
    if (data?.files) {
      for (let x = 0; x < data.files.length; x++) {
        if (typeof data.files[x] === 'string') {
          files.push(data.files[x]);
        } else {
          const imageUrl = await uploadMedia(data.files[x]);
          files.push(imageUrl);
        }
      }
    }
    data.files = files;
    onSaveAndNext(layout.name, data);
  }

  return (
    <Styled.MainWrapDiv>
      <Styled.HeadWrapDiv active={active ? 'true' : 'false'} onClick={onCollapseClose}>
        <Styled.HeadingDiv>{layout.title}</Styled.HeadingDiv>
        <Styled.ProgressDiv>{`${collapseData?.progress || 0}% Completed`}</Styled.ProgressDiv>
        {/* {collapseIndex > 4 ?
          <Styled.ProgressDiv>{`${collapseData?.progress || 0}% Completed`}</Styled.ProgressDiv>
          : ''
        } */}
        {collapseData?.progress === 100 ? <Styled.CompletedDiv /> : ''}
        {collapseIndex < 5 && collapseData?.completed_date ? <Styled.CompletedDateDiv>{moment(collapseData.completed_date).format('DD MMM YYYY')}</Styled.CompletedDateDiv> : ''}
      </Styled.HeadWrapDiv>

      <Styled.ContentWrapDiv maxHeight="1500px" active={active ? 'true' : 'false'}>
        <Styled.ContentDiv>
          {layout.type === 'payment' ?
            <Styled.PaymentCollapseDiv>
              <p>{layout.text}</p>
              { collapseData?.payment_completed ?
                <PrimaryButton title="Paid" value={collapseData?.payment_requested} disabled={isDisabled} />
              :
                collapseData?.payment_requested ?
                  <PrimaryButton title="Requested" value={collapseData?.payment_requested} disabled={isDisabled} />
                :
                  <PrimaryButton title="Request For Payment" value={collapseData?.payment_requested} onClick={() => onPaymentRequest(layout.name)} disabled={isDisabled} />
              }
            </Styled.PaymentCollapseDiv>
            :
            <Styled.CollapseBodyDiv>
              {layout.layout?.map((elementArray, elementIndex) => (
                <Styled.RowDiv key={`collapse_${elementIndex}`}>
                  {elementArray?.map((element, eIndex) => (
                    <Styled.ColumnDiv
                      key={`collapse_${elementIndex}_index_${eIndex}`}
                      style={element.element === 'textarea' || element.element === 'subCollapse' || (element.detail && elementArray.length === 1) ? { width: '100%' } : {}}
                    >
                      {element.label ?
                        <Styled.InputLabelDiv>{element.label}</Styled.InputLabelDiv>
                        : ''
                      }
                      {element.detail ? <Styled.TextDiv>{element.detail}</Styled.TextDiv> : ''}
                      {element.element === 'input' ?
                        <InputDate
                          id={element.name}
                          placeholder={element.placeholder}
                          onChange={(e) => onDateChange(element.name, e.target.value)}
                          value={element.value || ''}
                          min={element.min}
                          max={element.max}
                          disabled={isDisabled}
                        />
                        : ''
                      }
                      {element.element === 'textarea' ?
                        <textarea
                          id={element.name}
                          onChange={(e) => setCollapseData({ ...collapseData, [element.name]: e.target.value })}
                          value={element.value || ''}
                          disabled={isDisabled}
                          style={{ backgroundColor: isDisabled ? '#f8f8f8' : '#ffffff' }}
                        />
                        : ''
                      }
                      {element.element === 'uploadFile' ?
                        <UploadFile
                          selected={element.value}
                          changeFiles={(value) => onChangeFiles(value)}
                          error={''}
                          labelStyle={element.detail && elementArray.length === 1 ? { width: '48%' } : {}}
                          disabled={isDisabled}
                        />
                        : ''
                      }
                      {element.element === 'button' ?
                        <>
                          {element.condition && !isListCompleted(element.condition, element.conditionListLength) ?
                            <Styled.DisabledListButton>
                              {element.text}
                              {isListCompleted(element.name, element.listLength) ?
                                <Styled.CompletedDiv />
                                : ''
                              }
                            </Styled.DisabledListButton>
                            :
                            <Styled.ListButton onClick={() => setActiveModal(element.name)}>
                              {element.text}
                              {isListCompleted(element.name, element.listLength) ?
                                <Styled.CompletedDiv />
                                : ''
                              }
                            </Styled.ListButton>
                          }
                        </>
                        : ''
                      }
                      {element.element === 'checklist' ?
                        <ChecklistModal
                          show={activeModal === element.name}
                          heading={element.heading}
                          hide={() => onChecklistClose(element.name)}
                          onSave={() => onChecklistSave()}
                          disabled={isDisabled}
                        >
                          {element.list?.map((check, checkIndex) => (
                            <div key={`checkbox_${element.name}_${checkIndex}`}>
                              <Checkbox
                                label={check.label}
                                onChange={(value) => onChecklistChange(element.name, checkIndex, value)}
                                checked={check.checked || false}
                                disabled={isDisabled}
                              />
                            </div>
                          ))}
                        </ChecklistModal>
                        : ''
                      }
                      {element.element === 'measurementDetailsChecklist' ?
                        <MeasurementModal
                          show={activeModal === element.name}
                          hide={() => setActiveModal(null)}
                          dataMeasurement={collapseData}
                          onSubmit={onMeasurementSave}
                          isDisabled={isDisabled}
                        />
                        : ''
                      }
                      {element.element === 'subCollapse' ?
                        <SubCollapse
                          heading={element.title}
                          index={elementIndex}
                          active={activeSubCollapse === elementIndex}
                          setActive={setActiveSubCollapse}
                          collapseSubCollapseData={milestoneCollapseData?.[element.name] || {}}
                          collapseIndex={collapseIndex}
                          onSubCollapseSave={onSubCollapseSave}
                          setLoading={setLoading}
                          isDisabled={isDisabled}
                        />
                        : ''
                      }
                    </Styled.ColumnDiv>
                  ))}
                </Styled.RowDiv>
              ))}

              {layout?.submit ?
                <PrimaryButton
                  style={{ alignSelf: 'flex-end', marginTop: '24px' }}
                  title={layout?.submit?.text}
                  onClick={onCollapseSave}
                  disabled={isDisabled}
                />
                : ''
              }

            </Styled.CollapseBodyDiv>
          }
        </Styled.ContentDiv>
      </Styled.ContentWrapDiv>
    </Styled.MainWrapDiv>
  )
}

export default Collapse;
