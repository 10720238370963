import React, { useEffect, useState } from "react";
import moment from "moment";
import Layout from "../../Components/Layout";
import Heading from "../../Components/Heading";
import PrimaryButton from "../../Components/PrimaryButton";
import Loader from "../../Components/Loader";
import Modal from "../../Components/Modal";
import { fetchData } from "../../Utilities/handleRequest";
import { withRouter } from "../../Utilities/withRouter";
import * as Styled from "./styled";
import { toastError } from "../../Utilities/toast";

const SupportTicket = ({ params, navigate }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [chats, setChats] = useState([]);
  const [tickedData, setTicketData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (params.id) {
      getTicket(1);
    }
  }, []);

  const getTicket = async (pageNo) => {
    setLoading(true);
    let url = `support-chat/get-all-chat/${params.id}?pageNo=${pageNo || page}&limit=${limit}`;
    let res = await fetchData('get', url);
    setChats(res?.data?.supportchat || []);
    setCount(res?.data?.count || 0);
    let ticket = await fetchData('get', `support-ticket/get-ticket/${params.id}`);
    setTicketData(ticket?.data || {});
    setLoading(false);
  }

  const sendMessage = async () => {
    if (!message) return;
    let res = await fetchData('post', 'support-chat/create-support-chat', {
      support_ticket_id: params.id,
      type: 'message',
      text: message,
    });
    if (res?.data) {
      getTicket(1);
      setMessage('');
    }
  }

  const onMessageKeyDown = (e) => {
    if (e.code === 'Enter' && e.keyCode === 13) {
      e.preventDefault();
      if (e.ctrlKey) {
        setMessage((prev) => prev += '\n');
      } else {
        sendMessage();
      }
    }
  }

  const onImageClick = (src) => {
    setModalImage(src);
    setShowModal(true);
  }

  const getFileIcon = (item) => {
    if (!item?.fileURL) return;
    let fileArray = item.fileURL.split('.');
    let fileExtension = fileArray[fileArray.length - 1];

    const iconImage = [];

    if (fileExtension === 'xls' || fileExtension === 'xlsx') {
      iconImage.push(<Styled.ReceivedIcon src="/xls-file-icon.svg" onClick={() => downloadFile(item.fileURL)} />);
    } else if (fileExtension === 'doc' || fileExtension === 'docx') {
      iconImage.push(<Styled.ReceivedIcon src="/doc-file-icon.svg" onClick={() => downloadFile(item.fileURL)} />);
    } else if (fileExtension === 'pdf') {
      iconImage.push(<Styled.ReceivedIcon src="/pdf-file-icon.svg" onClick={() => downloadFile(item.fileURL)} />);
    } else {
      iconImage.push(<Styled.ReceivedImg src={item.fileURL}
        onClick={() => onImageClick(item.fileURL)}
      />);
    }

    return <Styled.ReceiveImageDiv>
      {iconImage}
      <Styled.TimeDiv>{moment(item.created_at).format("DD MMM YYYY - HH:mm")}</Styled.TimeDiv>
    </Styled.ReceiveImageDiv>
  }

  const downloadFile = (url) => {
    window.open(url);
    return false;
  }

  const onCloseTicket = async () => {
    setLoading(true);

    let res = await fetchData('post', `support-ticket/update-ticket/${params.id}`);
    if (res?.data) {
      let ticket = await fetchData('get', `support-ticket/get-ticket/${params.id}`);
      setTicketData(ticket?.data || {});
    } else {
      toastError('Something went wrong!')
    }

    setLoading(false);
  }

  return (
    <Layout activePage="Support" style={{ display: 'flex', flexDirection: 'column' }}>
      <Loader loading={loading} />

      <Modal
        show={showModal}
        hide={() => setShowModal(false)}
        hideFooter={true}
        closeButton={false}
        backdropClick={false}
        outerCloseButton={true}
        contentStyle={{ padding: '0px', borderRadius: '6px' }}
        modalStyle={{ background: 'none', border: 'none' }}
      >
        <Styled.ModalImg src={modalImage} />
      </Modal>

      <Heading
        heading={`#${params.id}` || ''}
        subHeading="This is where you can view the messages of the tickets."
        backTo={{ text: 'Back to all tickets', url: '/support' }}
      >
        {tickedData?.status === 'open' ?
          <PrimaryButton title="Close Ticket" style={{ marginLeft: '20px' }} onClick={onCloseTicket} />
          : ''
        }
      </Heading>

      <Styled.ChatOuterDiv>
        <Styled.ChatWrapDiv>
          {chats?.map((item) => (
            item.client_name ?
              <>
                {item.fileURL ? getFileIcon(item) : ''}
                {item.text ?
                  <Styled.ReceiveDiv>
                    <Styled.NameDiv>{item.client_name}</Styled.NameDiv>
                    <Styled.MessageDiv>{item.text}</Styled.MessageDiv>
                    <Styled.TimeDiv>{moment(item.created_at).format("DD MMM YYYY - HH:mm")}</Styled.TimeDiv>
                  </Styled.ReceiveDiv>
                  : ''
                }
              </>
              :
              <Styled.SentDiv>
                <Styled.NameDiv>You</Styled.NameDiv>
                <Styled.MessageDiv>{item.text}</Styled.MessageDiv>
                <Styled.TimeDiv>{moment(item.created_at).format("DD MMM YYYY - HH:mm")}</Styled.TimeDiv>
              </Styled.SentDiv>
          ))}

        </Styled.ChatWrapDiv>

        {tickedData?.status === 'open' ?
          <Styled.ChatInputDiv>
            <textarea
              placeholder="Write message here"
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={onMessageKeyDown}
              value={message}
              disabled={tickedData?.status !== 'open'}
            />
            <button onClick={sendMessage}>
              <img src="/send-support-message-icon.svg" />
            </button>
          </Styled.ChatInputDiv>
          :
          <Styled.TicketClosedDiv>Ticket Closed</Styled.TicketClosedDiv>
        }

      </Styled.ChatOuterDiv>

    </Layout>
  )
}

export default withRouter(SupportTicket);
