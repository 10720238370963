import styled from "styled-components";

export const ContentWrapDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  & p {
    font-size: 20px;
  }
  & textarea {
    height: 130px;
    padding: 12px 20px;
    box-sizing: border-box;
  }
  & input {
    box-sizing: border-box;
  }
`;
export const InfoDiv = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #bfbfbf;
  padding: 32px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  box-sizing: border-box;
  & p {
    margin: 0px 0px 4px 0px;
    line-height: 28px;
  }
`;
export const StatusDiv = styled.div`
  background-color: #118D8B;
  border-radius: 20px;
  padding: 3px 12px;
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
  margin-left: 7px;
`;
export const NameWrapDiv = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #bfbfbf;
  padding: 32px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 28px;
  & p {
    margin: 0px;
    width: 30%;
  }
`;
export const TabContentDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
export const TestCollapseDiv = styled.div`
  padding: 28px;
`;
export const AddElementButton = styled.button`
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 20px;
  font-weight: 700;
  background: none;
  & div {
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 4px;
    background-color: #000000;
    margin-right: 12px;
    &:before {
      content: '';
      height: 3px;
      width: 16px;
      border-radius: 3px;
      background-color: #ffffff;
      position: absolute;
    }
    &:after {
      content: '';
      height: 3px;
      width: 16px;
      border-radius: 3px;
      background-color: #ffffff;
      position: absolute;
      transform: rotate(90deg);
    }
  }
`;
export const CompletedDiv = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 24px;
  background-color: #118D8B;
  margin-left: 12px;
  position: relative;
  flex-shrink: 0;
  &:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 8px;
    height: 10px;
    width: 5px;
    transform: rotate(45deg);
    border-right: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
  }
`;
export const RowDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const DownloadWrapDiv = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #bfbfbf;
  margin: 28px 0px;
`;
export const DownloadCollapseDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  overflow: hidden;
  transition: all 0.3s;
  & > div {
    display: flex;
    align-items: center;
    font-size: 20px;
    &:first-child {
      margin: 2px 32px 16px 32px;
    }
    &:last-child {
      margin: 16px 32px 32px 32px;
    }
    & > div:first-child {
      width: 600px;
    }
  }
  & button {
    height: 44px;
    width: 208px;
    border-radius: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #118D8B;
    color: #118D8B;
    background-color: #ffffff;
    font-size: 16px;
    transition: all 0.2s;
    flex-shrink: 0;
    &:hover {
      color: #ffffff;
      background-color: #118D8B;
    }
  }
`;
export const DownloadHeadingDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  color: #118D8B;
  padding: 32px 32px 30px 32px;
  cursor: pointer;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    right: 32px;
    top: ${({active}) => active === 'true' ? '36px' : '42px'};
    transform: ${({active}) => active === 'false' ? 'rotate(-135deg)' : 'rotate(45deg)'};
    height: 12px;
    width: 12px;
    border-bottom: 2px solid #000000;
    border-right: 2px solid #000000;
    transition: all 0.3s;
  }
`;
