import axios from "axios";
import { clearLocalStorage } from "./localStorageHandler";
import { toastError } from "./toast";

export const fetchData = async (method, url, body={}) => {
  if (!method || !url) return;

  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  let token = localStorage.getItem("jwtToken");

  if (token) {
    axiosRequestOptions["headers"]["Authorization"] = "Bearer " + token;
    // body["token"] = token;
    if (method === 'get') {
      axiosRequestOptions["headers"]["body"] = JSON.stringify(body);
    } else if (method === 'post') {
      axiosRequestOptions["headers"]["data"] = body;
    }
  }

  try {
    let response = null;
    if (method === 'get') {
      response = await axios.get(process.env.REACT_APP_API_URL + url, axiosRequestOptions);
    } else if (method === 'post') {
      response = await axios.post(process.env.REACT_APP_API_URL + url, body, axiosRequestOptions);
    }

    if (response && response.data) {
      return response.data;
    } else {
      throw Error(`Invalid Response`);
    }
  } catch (error) {
    toastError(error?.response?.data?.message || 'Error fetching data');
    if (error?.response?.status === 401) {
      clearLocalStorage();
      localStorage.setItem('unauthorize', error?.response?.data?.message);
      window.location.href = '/';
    }
    
    return { error : error?.response };
  }
};

// export const uploadMedia = async (data) => {
//   if (!data || !data.name) {
//     console.error("Invalid file data. Upload aborted.");
//     return;
//   }

//   const fileArr = data['name'].split('.');
//   const fileType = fileArr[fileArr.length - 1];

//   const validFileTypes = ['xls', 'xlsx', 'doc', 'docx', 'pdf', 'jpg', 'png', 'jpeg']; // Add more as needed
//   if (!validFileTypes.includes(fileType.toLowerCase())) {
//     console.error("Invalid file type:", fileType);
//     return;
//   }

//   fileArr.pop();
//   const fileName = fileArr.join('');

//   let file = await getFileBase64(data);
//   if (!file) {
//     console.error("Failed to get file content. Upload aborted.");
//     return;
//   }

//   if (fileType === 'xls' || fileType === 'xlsx') {
//     file = file.replace(/^data:application\/vnd.ms-excel;base64,/, "");
//     file = file.replace(/^data:application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,/, "");
//   } else if (fileType === 'doc' || fileType === 'docx') {
//     file = file.replace(/^data:application\/msword;base64,/, "");
//     file = file.replace(/^data:application\/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,/, "");
//   } else if (fileType === 'pdf') {
//     file = file.replace(/^data:application\/pdf;base64,/, "");
//   } else {
//     file = file.replace(/^data:image\/[a-z]+;base64,/, "");
//     file = file.replace(/^data:application\/octet-stream;base64,/, "");
//   }

//   try {
//     const imageRes = await fetchData('post', 'util/upload-media', {fileName, file, fileType});
//     return imageRes?.data?.url;
//   } catch (error) {
//     console.error("Failed to upload the file:", error);
//     return null;
//   }
// };

export const uploadMedia = async (data) => {
  if (!data) return;
  if (typeof data === 'string') return data;

  if (data['name']) {
    const fileArr = data['name'].split('.');
    const fileType = fileArr[fileArr.length - 1];
    fileArr.pop();
    const fileName = fileArr.join('');
    let file = await getFileBase64(data);
    if (file) {
      if (fileType === 'xls' || fileType === 'xlsx') {
        file = file.replace(/^data:application\/vnd.ms-excel;base64,/, "");
        file = file.replace(/^data:application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,/, "");
      } else if (fileType === 'doc' || fileType === 'docx') {
        file = file.replace(/^data:application\/msword;base64,/, "");
        file = file.replace(/^data:application\/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,/, "");
      } else if (fileType === 'pdf') {
        file = file.replace(/^data:application\/pdf;base64,/, "");
      } else {
        file = file.replace(/^data:image\/[a-z]+;base64,/, "");
        file = file.replace(/^data:application\/octet-stream;base64,/, "");
      }
    }

    const imageRes = await fetchData('post', 'util/upload-media', {fileName, file, fileType});
    return imageRes?.data?.url;
  }
};

export const getFileBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
