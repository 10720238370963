import { useEffect, useState } from "react";
import InputGroup from "../../../Components/InputGroup";
import SingleSelect from "../../../Components/SingleSelect";
import RadioButton from "../../../Components/RadioButton";
import PrimaryButton from "../../../Components/PrimaryButton";
import { toastError } from "../../../Utilities/toast";
import { fetchData } from "../../../Utilities/handleRequest";
import * as Styled from "./styled";

const ProjectExecution = ({params, dataExecution, unityMetadata, getProject, setLoading}) => {
  const [executionData, setExecutionData] = useState({...dataExecution});

  useEffect(() => {
    setExecutionData(dataExecution);
  }, [dataExecution]);

  const floorData = [
    { id: '0', name: 'Ground Floor' },
    { id: '1', name: '1st Floor' },
    { id: '2', name: '2nd Floor' },
    { id: '3', name: '3rd Floor' },
    { id: '4', name: '4th Floor' },
    { id: '5', name: '5th Floor' },
    { id: '6', name: '6th Floor' },
    { id: '7', name: '7th Floor' },
    { id: '8', name: '8th Floor' },
    { id: '9', name: '9th Floor' },
    { id: '10', name: '10th Floor' },
    { id: '11', name: '11th Floor' },
    { id: '12', name: '12th Floor' },
    { id: '13', name: '13th Floor' },
    { id: '14', name: '14th Floor' },
    { id: '15', name: '15th Floor' },
    { id: '16', name: '16th Floor' },
    { id: '17', name: '17th Floor' },
    { id: '18', name: '18th Floor' },
    { id: '19', name: '19th Floor' },
    { id: '20', name: '20th Floor' },
    { id: '21', name: '21th Floor' },
    { id: '22', name: '22th Floor' },
    { id: '23', name: '23th Floor' },
    { id: '24', name: '24th Floor' },
    { id: '25', name: '25th Floor' },
    { id: '26', name: '26th Floor' },
    { id: '27', name: '27th Floor' },
    { id: '28', name: '28th Floor' },
    { id: '29', name: '29th Floor' },
    { id: '30', name: '30th Floor' },
  ];

  const dismantlingData = [
    { id: 'ENTIRE', name: 'Entire dismantling' },
    { id: 'PARTIAL', name: 'Partial dismantling' },
  ];

  const radioButtonValues = [{ id: 'true', name: 'Yes' }, { id: 'false', name: 'No' }];
  const serviceLiftAccessValues = [{ id: 'true', name: 'Allowed' }, { id: 'false', name: 'Not Allowed' }];
  const groutingValues = [{ id: 'CEMENT', name: 'Cement' }, { id: 'EPOXY', name: 'Epoxy' }];

  const saveExecution = async () => {
    setLoading(true);

    let res = await fetchData('post', `projects/update-project/${params.id}`, {
      execution_metadata: {...executionData}
    });

    if (res?.data) {
      toastError('Project updated successfully');
      await getProject();
      setLoading(false);
      return true;
    } else {
      toastError('Something went wrong!');
      setLoading(false);
      return false;
    }
  }

  const onInputChange = (id, value) => {
    const data = {...executionData};
    data[id] = value === 'true' ? true : value === 'false' ? false : value;
    setExecutionData(data);
  }

  return (
    <Styled.MainWrapDiv>
      <Styled.HeadingDiv>Bathroom requirements</Styled.HeadingDiv>
      <Styled.TextDiv>This will affect the overall costing of bathroom installation</Styled.TextDiv>

      <Styled.RowDiv>
        <Styled.ColumnDiv>
          <Styled.LabelDiv>Bathroom Dimension</Styled.LabelDiv>
          <Styled.RowDiv>
            <InputGroup
              value={unityMetadata?.Dimension?.room_data?.length || '0'}
              onChange={()=>{}}
              placeholder={'Length(ft)'}
              type=""
              style={{width: '30%'}}
              groupValue={'L'}
              disabled={true}
            />
            <InputGroup
              value={unityMetadata?.Dimension?.room_data?.width || '0'}
              onChange={()=>{}}
              placeholder={'Breadth(ft)'}
              type=""
              style={{width: '30%'}}
              groupValue={'B'}
              disabled={true}
            />
            <InputGroup
              value={unityMetadata?.Dimension?.room_data?.height || '0'}
              onChange={()=>{}}
              placeholder={'Height(ft)'}
              type=""
              style={{width: '30%'}}
              groupValue={'H'}
              disabled={true}
            />
          </Styled.RowDiv>

          { unityMetadata?.project_Model?.roomLayout === 2 ?
            <Styled.RowDiv style={{ marginTop: '12px' }}>
              <InputGroup
                value={unityMetadata?.Dimension?.room_data?.cornerLength || '0'}
                onChange={()=>{}}
                placeholder={'Length(ft)'}
                type=""
                style={{width: '47.5%'}}
                groupValue={'CL'}
                disabled={true}
              />
              <InputGroup
                value={unityMetadata?.Dimension?.room_data?.cornerWidth || '0'}
                onChange={()=>{}}
                placeholder={'Breadth(ft)'}
                type=""
                style={{width: '47.5%'}}
                groupValue={'CB'}
                disabled={true}
              />
            </Styled.RowDiv>
          : ''
          }

          <Styled.LabelDiv>Properties on Floor</Styled.LabelDiv>
          <SingleSelect
            placeholder={'Select floor'}
            onSelect={(value) => onInputChange('properties_on_floor', value)}
            data={floorData}
            style={{ fontSize: '16px', width: '100%' }}
            height="42px"
            selectedId={executionData?.properties_on_floor}
            fontSize="16px"
          />

          <Styled.LabelDiv>Dismantling of Bathtub/Jacuzzi</Styled.LabelDiv>
          <RadioButton
            name={'dismantling_of_bathtub'}
            style={{ width: '48%', margin: '10px 0px' }}
            options={radioButtonValues}
            value={executionData?.dismantling_of_bathtub?.toString()}
            onChange={(e) => onInputChange('dismantling_of_bathtub', e.target.value)}
          />

          <Styled.LabelDiv>Electrical Works</Styled.LabelDiv>
          <RadioButton
            name={'electrical_works'}
            style={{ width: '48%', margin: '10px 0px' }}
            options={radioButtonValues}
            value={executionData?.electrical_works?.toString()}
            onChange={(e) => onInputChange('electrical_works', e.target.value)}
          />
        </Styled.ColumnDiv>

        <Styled.ColumnDiv>
          <Styled.LabelDiv>Dismantling</Styled.LabelDiv>
          <SingleSelect
            placeholder={'Select dismantling'}
            onSelect={(value) => onInputChange('dismantling_type', value)}
            data={dismantlingData}
            style={{ fontSize: '16px', width: '100%' }}
            height="42px"
            selectedId={executionData?.dismantling_type}
            fontSize="16px"
          />

          <Styled.LabelDiv>Service Lift Access</Styled.LabelDiv>
          <RadioButton
            name={'service_lift_access'}
            style={{ width: '48%', margin: '10px 0px' }}
            options={serviceLiftAccessValues}
            value={executionData?.service_lift_access?.toString()}
            onChange={(e) => onInputChange('service_lift_access', e.target.value)}
          />

          <Styled.LabelDiv>Plumbing Work</Styled.LabelDiv>
          <RadioButton
            name={'plumbing_work'}
            style={{ width: '48%', margin: '10px 0px' }}
            options={radioButtonValues}
            value={executionData?.plumbing_work?.toString()}
            onChange={(e) => onInputChange('plumbing_work', e.target.value)}
          />

          <Styled.LabelDiv>Grouting</Styled.LabelDiv>
          <RadioButton
            name={'grouting'}
            style={{ width: '48%', margin: '10px 0px' }}
            options={groutingValues}
            value={executionData?.grouting?.toString()}
            onChange={(e) => onInputChange('grouting', e.target.value)}
          />
        </Styled.ColumnDiv>
      </Styled.RowDiv>

      <PrimaryButton style={{ alignSelf: 'flex-end', marginTop: '40px' }} title="Save Requirements" onClick={saveExecution} />

    </Styled.MainWrapDiv>
  )
}

export default ProjectExecution;
