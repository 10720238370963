import styled from "styled-components";

export const MainWrapDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 48px 60px 48px;
  border-top: 1px solid #bfbfbf;
`;
export const HeadingDiv = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: #118D8B;
`;
export const TextDiv = styled.div`
  font-size: 16px;
  margin: 12px 0px 20px 0px;
`;
export const RowDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const ColumnDiv = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
`;
export const LabelDiv = styled.div`
  font-size: 16px;
  margin: 28px 0px 8px 0px;
`;
export const RadioButtonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
