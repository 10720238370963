import React from "react";
import HeaderMenu from "./HeaderMenu";
import * as Styled from "./styled";

export const Header = () => {
  return (
    <Styled.MainDiv>
      <Styled.LogoImg src="/logo.svg" />

      <HeaderMenu />
    </Styled.MainDiv>
  )
}

export default Header;
